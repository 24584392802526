<div role="main">
  <div class="">
    <div class="page-title">
      <div class="title_left">
        <h3>
          {{ "CONTACTS_TITLE" | translate }}

          <div class="display-inline">
            -
            <span
              class="cursor-pointer"
              routerLink="/editCustomer/{{ customer.uuid }}"
              title="{{ 'CONTACTS_TITLE_TOOLTIP_CUSTOMER' | translate }}"
              >{{ textNameCustomer }}</span
            >
            <span
              class="span-icon-header"
              routerLink="/editCustomer/{{ customer.uuid }}"
              data-toggle="tooltip"
              data-placement="top"
              title="{{ 'CONTACTS_TITLE_TOOLTIP_CUSTOMER' | translate }}"
            >
              <i class="mdi mdi-account icon-header"></i
            ></span>
          </div>
        </h3>
      </div>
    </div>
  </div>

  <div class="clearfix"></div>

  <div class="x_panel">
    <div class="x_title display-flex">
      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-3">
        <button
          class="btn btn-default"
          routerLink="/addCustomerContact/{{ customer.uuid }}"
          data-toggle="tooltip"
          data-placement="top"
          title="{{ 'CONTACTS_TOOLTIP_ADD' | translate }}"
        >
          <span>{{ "CONTACTS_BUTTON_NEW" | translate }}</span
          ><i class="mdi mdi-plus-circle indicator-color-green"></i>
        </button>
      </div>
    </div>

    <div class="x_content">
      <table class="table table-striped">
        <thead>
          <tr>
            <th class="table-header-col-no-sort">
              {{ "CONTACTS_TABLE_DATE" | translate }}
            </th>
            <th class="table-header-col-no-sort">
              {{ "CONTACTS_TABLE_TYPE" | translate }}
            </th>
            <th class="table-header-col-no-sort">
              {{ "CONTACTS_TABLE_SUBJECT" | translate }}
            </th>
            <th class="table-header-col-no-sort">
              {{ "CONTACTS_TABLE_ASSURANCES" | translate }}
            </th>
            <th class="table-header-col-no-sort">
              {{ "CONTACTS_TABLE_ACTIONS" | translate }}
            </th>
            <th class="tableColWidthIcon">
              <div
                class="table-header-col"
                routerLink="/addCustomerContact/{{ uuidCustomer }}"
                data-toggle="tooltip"
                data-placement="top"
                title="{{ 'CONTACTS_TABLE_TOOLTIP_ADD' | translate }}"
              >
                <i class="mdi mdi-plus-circle indicator-color-green"></i>
              </div>
            </th>
            <!-- th class="tableColWidthIcon"></th -->
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="
              let customerContact of customerContacts
                | limitFromTo
                  : currentPage * pageSize
                  : (currentPage + 1) * pageSize;
              let i = index
            "
          >
            <td routerLink="/editCustomerContact/{{ customerContact.uuid }}">
              {{ customerContact.textDate }}
            </td>
            <!-- td>{{ customerContact.date | date:'dd.MM.yyyy HH:mm'}}</td -->
            <td routerLink="/editCustomerContact/{{ customerContact.uuid }}">
              {{ customerContact.nameContactType }}
            </td>
            <td
              routerLink="/editCustomerContact/{{ customerContact.uuid }}"
              class="word-break-150px"
            >
              {{ customerContact.subject }}
            </td>
            <td
              routerLink="/editCustomerContact/{{ customerContact.uuid }}"
              class="word-break-150px"
            >
              {{ customerContact.assurances }}
            </td>
            <td
              routerLink="/editCustomerContact/{{ customerContact.uuid }}"
              class="word-break-150px"
            >
              {{ customerContact.actions }}
            </td>

            <td ng-show="1">
              <div class="dropdown">
                <button
                  class="btn btn-default dropdown-toggle btn-action"
                  type="button"
                  id="dropdownMenuButton"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {{
                    "GENERAL_TABLE_DROPDOWN_ACTIONS" | translate | capitalize
                  }}
                </button>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <li>
                    <a
                      class="dropdown-item"
                      routerLink="/editCustomerContact/{{
                        customerContact.uuid
                      }}"
                      ng-click="$event.stopPropagation();"
                      data-toggle="tooltip"
                      data-placement="top"
                    >
                      <i class="mdi mdi-pencil"></i>&nbsp;{{
                        "CONTACTS_TABLE_TOOLTIP_EDIT" | translate | capitalize
                      }}</a
                    >
                  </li>

                  <li>
                    <a
                      class="dropdown-item"
                      routerLink="/deleteCustomerContact/{{
                        customerContact.uuid
                      }}"
                      ng-click="$event.stopPropagation();"
                      data-toggle="tooltip"
                      data-placement="top"
                    >
                      <i class="mdi mdi-delete-outline"></i>&nbsp;{{
                        "CONTACTS_TABLE_TOOLTIP_DELETE" | translate | capitalize
                      }}</a
                    >
                  </li>
                </ul>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <button
          class="btn btn-sm btn-default btn-navigation"
          [disabled]="currentPage == 0 || numberOfPages() == 0"
          (click)="currentPage = 0; storeCurrentPage()"
        >
          {{ "NAVIGATION_PAGE_FIRST" | translate }}
        </button>
        <button
          class="btn btn-sm btn-default btn-navigation"
          [disabled]="currentPage == 0 || numberOfPages() == 0"
          (click)="currentPage = currentPage - 1; storeCurrentPage()"
        >
          {{ "NAVIGATION_PAGE_PREVIOUS" | translate }}
        </button>
        <span class="text-navigation">
          {{ currentPage + 1 }}/{{ numberOfPages() }}
        </span>
        <button
          class="btn btn-sm btn-default btn-navigation"
          [disabled]="currentPage >= numberOfPages() - 1"
          (click)="currentPage = currentPage + 1; storeCurrentPage()"
        >
          {{ "NAVIGATION_PAGE_NEXT" | translate }}
        </button>
        <button
          class="btn btn-sm btn-default btn-navigation"
          [disabled]="currentPage >= numberOfPages() - 1"
          (click)="currentPage = numberOfPages() - 1; storeCurrentPage()"
        >
          {{ "NAVIGATION_PAGE_LAST" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
