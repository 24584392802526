<div role="main">
  <div class="">
    <div class="page-title">
      <div class="title_left">
        <h3>{{ "ENQUIRIES_TITLE" | translate }}</h3>
      </div>
    </div>
  </div>

  <div class="clearfix"></div>

  <div class="x_panel">
    <div class="x_content">
      <table class="table table-striped">
        <thead>
          <tr>
            <th
              class="tableColWidthL table-header-col"
              (click)="changeSorting('date')"
            >
              <div>
                {{ "ENQUIRIES_TABLE_DATE" | translate
                }}<span [hidden]="sort.column != 'date'">
                  <span [hidden]="!sort.ascending"> ^</span>
                  <span [hidden]="sort.ascending"> v</span></span
                >
              </div>
            </th>
            <th
              class="tableColWidthL table-header-col"
              (click)="changeSorting('enquiryDate')"
            >
              <div>
                {{ "ENQUIRIES_TABLE_DATE_ENQUIRY" | translate
                }}<span [hidden]="sort.column != 'enquiryDate'">
                  <span [hidden]="!sort.ascending"> ^</span>
                  <span [hidden]="sort.ascending"> v</span></span
                >
              </div>
            </th>
            <th
              class="tableColWidthL table-header-col"
              (click)="changeSorting('number')"
            >
              <div>
                {{ "ENQUIRIES_TABLE_NUMBER" | translate
                }}<span [hidden]="sort.column != 'number'">
                  <span [hidden]="!sort.ascending"> ^</span>
                  <span [hidden]="sort.ascending"> v</span></span
                >
              </div>
            </th>
            <th
              class="tableColWidthL table-header-col"
              (click)="changeSorting('name')"
            >
              <div>
                {{ "ENQUIRIES_TABLE_NAME" | translate
                }}<span [hidden]="sort.column != 'name'">
                  <span [hidden]="!sort.ascending"> ^</span>
                  <span [hidden]="sort.ascending"> v</span></span
                >
              </div>
            </th>
            <th
              class="tableColWidthL table-header-col"
              (click)="changeSorting('street')"
            >
              <div>
                {{ "ENQUIRIES_TABLE_CITY" | translate
                }}<span [hidden]="sort.column != 'street'">
                  <span [hidden]="!sort.ascending"> ^</span>
                  <span [hidden]="sort.ascending"> v</span></span
                >
              </div>
            </th>
            <th
              class="tableColWidthL table-header-col"
              (click)="changeSorting('sumGrossV')"
            >
              <div>
                {{ "ENQUIRIES_TABLE_SUM_GROSS" | translate
                }}<span [hidden]="sort.column != 'sumGrossV'">
                  <span [hidden]="!sort.ascending"> ^</span>
                  <span [hidden]="sort.ascending"> v</span></span
                >
              </div>
            </th>

            <th class="tableColWidthIcon"></th>
            <!-- th class="tableColWidthIcon"></th -->
            <!--th>{{ 'OPEN_OFFERS_TABLE_CUSTOMER' | translate }}</th-->
            <!--th>Discount</th-->
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="
              let enquiry of enquiries
                | limitFromTo
                  : currentPage * pageSize
                  : (currentPage + 1) * pageSize;
              let i = index
            "
          >
            <td routerLink="/editSale/Offer/{{ enquiry.uuid }}/false">
              {{ enquiry.textDate }}
            </td>
            <td routerLink="/editSale/Offer/{{ enquiry.uuid }}/false">
              {{ enquiry.textEnquiryDate }}
            </td>
            <td routerLink="/editSale/Offer/{{ enquiry.uuid }}/false">
              {{ enquiry.number }}
            </td>
            <td routerLink="/editSale/Offer/{{ enquiry.uuid }}/false">
              {{ enquiry.name }}
            </td>
            <td routerLink="/editSale/Offer/{{ enquiry.uuid }}/false">
              {{ enquiry.street }}
            </td>
            <td
              routerLink="/editSale/Offer/{{ enquiry.uuid }}/false"
              class="noWrap"
            >
              {{ enquiry.sumGross }}
            </td>

            <td ng-show="1">
              <div class="dropdown">
                <button
                  class="btn btn-sm btn-default dropdown-toggle btn-action"
                  type="button"
                  id="dropdownMenuButton"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {{
                    "GENERAL_TABLE_DROPDOWN_ACTIONS" | translate | capitalize
                  }}
                </button>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <li>
                    <a
                      class="dropdown-item"
                      routerLink="/editSale/Offer/{{
                        enquiry.uuid
                      }}/false"
                      ng-click="$event.stopPropagation();"
                      data-toggle="tooltip"
                      data-placement="top"
                    >
                      <i class="mdi mdi-pencil"></i>&nbsp;{{
                        "ENQUIRIES_TABLE_TOOLTIP_EDIT" | translate | capitalize
                      }}</a
                    >
                  </li>

                  <li>
                    <a
                      class="dropdown-item"
                      [hidden]="enquiry.uuidCustomer == null"
                      routerLink="/editCustomer/{{
                        enquiry.uuidCustomer
                      }}"
                      data-toggle="tooltip"
                      data-placement="top"
                    >
                      <i class="mdi mdi-account"></i>&nbsp;{{
                        "ENQUIRIES_TABLE_TOOLTIP_CUSTOMER"
                          | translate
                          | capitalize
                      }}</a
                    >
                  </li>
                </ul>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <button
          class="btn btn-sm btn-default btn-navigation"
          [disabled]="currentPage == 0 || numberOfPages() == 0"
          (click)="currentPage = 0; storeCurrentPage()"
        >
          {{ "NAVIGATION_PAGE_FIRST" | translate }}
        </button>
        <button
          class="btn btn-sm btn-default btn-navigation"
          [disabled]="currentPage == 0 || numberOfPages() == 0"
          (click)="currentPage = currentPage - 1; storeCurrentPage()"
        >
          {{ "NAVIGATION_PAGE_PREVIOUS" | translate }}
        </button>
        <span class="text-navigation">
          {{ currentPage + 1 }}/{{ numberOfPages() }}
        </span>
        <button
          class="btn btn-sm btn-default btn-navigation"
          [disabled]="currentPage >= numberOfPages() - 1"
          (click)="currentPage = currentPage + 1; storeCurrentPage()"
        >
          {{ "NAVIGATION_PAGE_NEXT" | translate }}
        </button>
        <button
          class="btn btn-sm btn-default btn-navigation"
          [disabled]="currentPage >= numberOfPages() - 1"
          (click)="currentPage = numberOfPages() - 1; storeCurrentPage()"
        >
          {{ "NAVIGATION_PAGE_LAST" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
