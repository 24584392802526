import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common'
import { TranslateService } from '@ngx-translate/core';
import { ParametersStartService } from '../parameters-start.service';
import { CommonsService } from '../commons-service';
import { RepositoryService } from '../repository.service';
import { SaleModel } from '../models/sale-model';
declare const log: any;

@Component({
  selector: 'app-delete-sale',
  templateUrl: './delete-sale.component.html',
  styleUrls: ['./delete-sale.component.css']
})
export class DeleteSaleComponent implements OnInit {

  typeSale = "Offer"
  uuidSale = ""
  textTitle = ""

  sale: SaleModel = new SaleModel()
  textTimeSale = ""

  constructor(
    private route: ActivatedRoute,
    protected router: Router,
    private location: Location,
    protected translate: TranslateService,
    private parametersStart: ParametersStartService,
    private repository: RepositoryService,
  ) { }

  ngOnInit(): void {
    this.init()
  }

  async init() {
    const routeParams = this.route.snapshot.paramMap;
    this.typeSale = String(routeParams.get('type'));
    this.uuidSale = String(routeParams.get('uuidSale'));


    if (this.typeSale === "Offer") {
      this.translate.get('SALE_DELETE_TITLE_OFFER').subscribe((translated: string) => {
        this.textTitle = translated;
      })
      this.sale = await this.repository.readOffer(this.uuidSale)
    } else if (this.typeSale === "Order") {
      this.translate.get('SALE_DELETE_TITLE_ORDER').subscribe((translated: string) => {
        this.textTitle = translated;
      });
      this.sale = await this.repository.readOrder(this.uuidSale)
    } else if (this.typeSale === "Invoice") {
      this.translate.get('SALE_DELETE_TITLE_INVOICE').subscribe((translated: string) => {
        this.textTitle = translated;
      });
      this.sale = await this.repository.readInvoice(this.uuidSale)
    } else
      throw "Error, unknown sale type " + this.typeSale

    this.textTimeSale = CommonsService.formatDate0(this.sale.date)
  }

  async delete() {
    if (this.typeSale == "Offer")
      await this.repository.deleteOffer(this.sale.uuid)
    else if (this.typeSale == "Order")
      await this.repository.deleteOrder(this.sale.uuid)
    else if (this.typeSale == "Invoice")
      await this.repository.deleteInvoice(this.sale.uuid)

    let pathOrigin = await this.repository.readUtilityData("locationOriginSales")

    this.router.navigateByUrl(pathOrigin)
  }

}
