import { BaseModel } from "./base-model"

export class CustomerContactModel extends BaseModel {
  uuidCustomer: string = ""
  date: Date = new Date()
  textDate: string = ""
  uuidContactType: string = ""
  nameContactType: string = ""
  subject: string = ""
  assurances: string = ""
  actions: string = ""

  constructor(params: any = null) {
    super(params)
    this.copyFromObject(params)
  }

  static override newModel(object: any = null) {
    return new CustomerContactModel(object)
  }
}
