import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Injectable } from '@angular/core';

function padNumber(value: number | null) {
  if (value !== null) {
    return `0${value}`.slice(-2);
  } else {
    return '';
  }
}

@Injectable()
export class NgbDateCustomParserFormatter extends NgbDateParserFormatter {
  parse(value: string): NgbDateStruct | null {
    if (value) {
      const dateParts = value.trim().split('.');

      let dateObj: NgbDateStruct = { day: <any>null, month: <any>null, year: <any>null }

      dateObj.day = +dateParts[0];
      dateObj.month = +dateParts[1];
      dateObj.year = +dateParts[2];

      return dateObj;
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    return date ?
      date.day + "." + date.month + "." + date.year :
      '';
  }
}
