import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Location } from '@angular/common'
import { RepositoryService } from '../repository.service';
import { PartnerModel } from '../models/partner-model';
import { SexModel } from '../models/sex-model';
declare const log: any


@Component({
  selector: 'app-edit-partner',
  templateUrl: './edit-partner.component.html',
  styleUrls: ['./edit-partner.component.css']
})
export class EditPartnerComponent implements OnInit {

  textTitle = ""
  uuidPartner = ""
  partner: PartnerModel = new PartnerModel()
  partnerBeforeEdit: PartnerModel = new PartnerModel()
  sexes: SexModel[] = []

  constructor(
    protected location: Location,
    protected route: ActivatedRoute,
    protected router: Router,
    protected translate: TranslateService,
    protected repository: RepositoryService
  ) { }

  ngOnInit(): void {
    this.init()
  }

  async init() {
    this.sexes = <SexModel[]>await this.repository.sexes.readAll()

    this.initSpecific()
  }

  async initSpecific() {
    const routeParams = this.route.snapshot.paramMap;
    this.uuidPartner = String(routeParams.get('uuidPartner'));

    this.translate.get("PARTNER_EDIT_TITLE").subscribe((translated) => this.textTitle = translated)

    this.partner = <PartnerModel>await this.repository.partners.read(this.uuidPartner)

    log("the partner")
    log(this.partner)

    this.partnerBeforeEdit = new PartnerModel(this.partner)
  }


  async save() {
    this.saveSpecific()
  }

  async saveSpecific() {
    await this.repository.partners.update(this.partnerBeforeEdit, this.partner)
    this.location.back()
  }
}
