import { TaskModel } from "./task-model";

export class TaskForViewModel extends TaskModel {
  textDue: string = ""
  textCategory: string = ""
  classTextDecoration: string = ""

  constructor(params: any = null) {
    super(params)
    this.copyFromObject(params)
  }

  static override newModel(object: any = null) {
    return new TaskForViewModel(object)
  }
}
