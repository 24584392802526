import { BaseModel } from "./base-model";

export class ContactTypeModel extends BaseModel {
  name: string = ""

  constructor(params: any = null) {
    super(params)
    this.copyFromObject(params)
  }

  static override newModel(object: any = null) {
    return new ContactTypeModel(object)
  }
}

