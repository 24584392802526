import { HavingImagesModel } from "./having-images-model";

export class DeviceModel extends HavingImagesModel {

  serialNumber: string = ""
  name: string = ""
  uuidArticle: string = ""
  ean: string = ""
  uuidCustomer: string = ""
  street: string = ""
  zipCode: string = ""
  city: string = ""
  locationDescription: string = ""
  lastMeterReading: number = 0.0

  constructor(params: any = null) {
    super(params)
    this.copyFromObject(params)
  }

  static override newModel(object: any = null) {
    return new DeviceModel(object)
  }
}
