import { BaseModel } from "./base-model"
import { HavingImagesModel } from "./having-images-model"
import { ImageModel } from "./image-model"

export class MaintenanceModel extends HavingImagesModel {

  number: string = ""
  begin: Date = new Date()
  uuidCustomer: string = ""
  uuidMaintenanceCategory = ""
  categoryName: string = ""
  uuidMaintenanceStatus = ""
  statusName: string = ""
  uuidDevice: string = ""
  uuidArticle: string = ""
  isOwn: boolean = true
  serialNumber: string = ""
  faultDescription: string = ""
  remarks: string = ""
  done: boolean = false
  diagnosis: string = ""
  end: Date = new Date()

  constructor(params: any = null) {
    super(params)
    this.copyFromObject(params)
  }

  static override newModel(object: any = null) {
    return new MaintenanceModel(object)
  }
}
