import { Injectable } from '@angular/core';
import { DbService } from './db.service';
import { TaskCategoryModel } from './models/task-category-model';
import { TaskModel } from './models/task-model';
import { ModelFactory } from './models/model-factory';
import { ModelInterface } from './models/model-interface';
import { CancellationReasonModel } from './models/cancellation-reason-model';
import { PaymentTypeModel } from './models/payment-type-model';
import { StateOrderModel } from './models/state-order-model';
import { ParametersStartService } from './parameters-start.service';
import { OfferModel } from './models/offer-model';
import { SalePositionModel } from './models/sale-position-model';
import { OrderModel } from './models/order-model';
import { InvoiceModel } from './models/invoice-model';
import { OfferPositionModel } from './models/offer-position-model';
import { OrderPositionModel } from './models/order-position-model';
import { InvoicePositionModel } from './models/invoice-position-model';
import { SaleModel } from './models/sale-model';
import { CompanyModel } from './models/company-model';
import { CustomerModel } from './models/customer-model';
import { CustomerCategoryModel } from './models/customer-category-model';
import { CountryModel } from './models/country-model';
import { SexModel } from './models/sex-model';
import { PaymentTargetModel } from './models/payment-target-model';
import { PricelistModel } from './models/pricelist-model';
import { AppointmentModel } from './models/appointment-model';
import { TimeRecordingModel } from './models/time-recording-model';
import { CommonsService } from './commons-service';
import { ArticleModel } from './models/article-model';
import { DeviceModel } from './models/device-model';
import { ContactTypeModel } from './models/contact-type-model';
import { CustomerContactModel } from './models/customer-contact-model';
import { PartnerModel } from './models/partner-model';
import { NetworkService } from './network.service';
import { DataNotAvailableError } from './data-not-available-error';
import { BaseModelRepository } from './base-model-repository';
import { MaintenanceModel } from './models/maintenance-model';
import { StockMovementModel } from './models/stock-movement-model';
import { MaintenanceMaterialModel } from './models/maintenance-material-model';
import { MaintenanceCategoryModel } from './models/maintenance-category-model';
import { MaintenanceStateModel } from './models/maintenance-state-model';
import { TaxRateModel } from './models/tax-rate-model';
import { WarehouseModel } from './models/warehouse-model';
import { StockDataArticleModel } from './models/stock-data-article-model';
import { StocksOfArticleModel } from './models/stocks-of-article-model';
import { ArticleGroupModel } from './models/article-group-model';
declare const log: any;

@Injectable({
  providedIn: 'root'
})
export class RepositoryService {

  public articles: BaseModelRepository<ArticleModel>;
  public customers: BaseModelRepository<CustomerModel>;
  public timeRecordings: BaseModelRepository<TimeRecordingModel>
  public appointments: BaseModelRepository<AppointmentModel>;
  public tasks: BaseModelRepository<TaskModel>
  public partners: BaseModelRepository<PartnerModel>
  public customerCategories: BaseModelRepository<CustomerCategoryModel>
  public sexes: BaseModelRepository<SexModel>
  public contactTypes: BaseModelRepository<ContactTypeModel>
  public countries: BaseModelRepository<CountryModel>
  public paymentTypes: BaseModelRepository<PaymentTypeModel>
  public paymentTargets: BaseModelRepository<PaymentTargetModel>
  public priceLists: BaseModelRepository<PricelistModel>
  public statesOrder: BaseModelRepository<StateOrderModel>
  public cancellationReasons: BaseModelRepository<CancellationReasonModel>
  public taskCategories: BaseModelRepository<TaskCategoryModel>
  public devices: BaseModelRepository<DeviceModel>
  public maintenances: BaseModelRepository<MaintenanceModel>
  public maintenancesMaterials: BaseModelRepository<MaintenanceMaterialModel>
  public stockMovements: BaseModelRepository<StockMovementModel>
  public maintenanceCategories: BaseModelRepository<MaintenanceCategoryModel>
  public maintenanceStates: BaseModelRepository<MaintenanceStateModel>
  public taxRates: BaseModelRepository<TaxRateModel>
  public warehouses: BaseModelRepository<WarehouseModel>
  public stockDataArticles: BaseModelRepository<StockDataArticleModel>
  public articleGroups: BaseModelRepository<ArticleGroupModel>

  constructor(
    private parametersStart: ParametersStartService,
    private modelFactory: ModelFactory<ModelInterface>,
    private networkService: NetworkService,
    private dbService: DbService
  ) {
    this.articles = new BaseModelRepository(
      ArticleModel, "Artikel", this.networkService, this.dbService, this.modelFactory, this.parametersStart)
    this.customers = new BaseModelRepository(
      CustomerModel, "Kunde", this.networkService, this.dbService, this.modelFactory, this.parametersStart)
    this.timeRecordings = new BaseModelRepository(
      TimeRecordingModel, "Projektzeiterfassung", this.networkService, this.dbService, this.modelFactory, this.parametersStart)
    this.appointments = new BaseModelRepository(
      AppointmentModel, "Termin", this.networkService, this.dbService, this.modelFactory, this.parametersStart)
    this.tasks = new BaseModelRepository(
      TaskModel, "Aufgabe", this.networkService, this.dbService, this.modelFactory, this.parametersStart)
    this.partners = new BaseModelRepository(
      PartnerModel, "Ansprechpartner", this.networkService, this.dbService, this.modelFactory, this.parametersStart)
    this.customerCategories = new BaseModelRepository(
      CustomerCategoryModel, "Kundenkategorie", this.networkService, this.dbService, this.modelFactory, this.parametersStart)
    this.sexes = new BaseModelRepository(
      SexModel, "Geschlecht", this.networkService, this.dbService, this.modelFactory, this.parametersStart)
    this.contactTypes = new BaseModelRepository(
      ContactTypeModel, "Kontaktart", this.networkService, this.dbService, this.modelFactory, this.parametersStart)
    this.countries = new BaseModelRepository(
      CountryModel, "Land", this.networkService, this.dbService, this.modelFactory, this.parametersStart)
    this.paymentTypes = new BaseModelRepository(
      PaymentTypeModel, "Zahlungsart", this.networkService, this.dbService, this.modelFactory, this.parametersStart)
    this.paymentTargets = new BaseModelRepository(
      PaymentTargetModel, "Zahlungsziel", this.networkService, this.dbService, this.modelFactory, this.parametersStart)
    this.priceLists = new BaseModelRepository(
      PricelistModel, "Preisliste", this.networkService, this.dbService, this.modelFactory, this.parametersStart)
    this.statesOrder = new BaseModelRepository(
      StateOrderModel, "Auftragsstatus", this.networkService, this.dbService, this.modelFactory, this.parametersStart)
    this.cancellationReasons = new BaseModelRepository(
      CancellationReasonModel, "Stornierungsgrund", this.networkService, this.dbService, this.modelFactory, this.parametersStart)
    this.taskCategories = new BaseModelRepository(
      TaskCategoryModel, "Aufgabenkategorie", this.networkService, this.dbService, this.modelFactory, this.parametersStart)
    this.devices = new BaseModelRepository(
      DeviceModel, "Gerät", this.networkService, this.dbService, this.modelFactory, this.parametersStart)
    this.maintenances = new BaseModelRepository(
      MaintenanceModel, "Wartungsauftrag", this.networkService, this.dbService, this.modelFactory, this.parametersStart)
    this.maintenancesMaterials = new BaseModelRepository(
      MaintenanceMaterialModel, "WartungsauftragMaterial", this.networkService, this.dbService, this.modelFactory, this.parametersStart)
    this.stockMovements = new BaseModelRepository(
      StockMovementModel, "Lagerbewegung", this.networkService, this.dbService, this.modelFactory, this.parametersStart)
    this.maintenanceCategories = new BaseModelRepository(
      MaintenanceCategoryModel, "Wartungskategorie", this.networkService, this.dbService, this.modelFactory, this.parametersStart)
    this.maintenanceStates = new BaseModelRepository(
      MaintenanceStateModel, "Wartungsstatus", this.networkService, this.dbService, this.modelFactory, this.parametersStart)
    this.taxRates = new BaseModelRepository(
      TaxRateModel, "Steuersatz", this.networkService, this.dbService, this.modelFactory, this.parametersStart)
    this.warehouses = new BaseModelRepository(
      WarehouseModel, "Lager", this.networkService, this.dbService, this.modelFactory, this.parametersStart)
    this.stockDataArticles = new BaseModelRepository(
      StockDataArticleModel, "LagerdatenArtikel", this.networkService, this.dbService, this.modelFactory, this.parametersStart)
    this.articleGroups = new BaseModelRepository(ArticleGroupModel, "Artikelgruppe", this.networkService, this.dbService, this.modelFactory, this.parametersStart)

    let this_ = this
    setInterval(function () {
      this_.readEverythingEventually()
    }, NetworkService.TIMEOUT_ALLOW_RETRY_MS)
  }

  async deleteLocalDatabase() {
    return this.dbService.deleteDatabase()
  }

  iEntityToLoadAhead = 0
  async readEverythingEventually() {
    this.init()
    if (!this.networkService.hasServer ||
      await this.dbService.readNameUser() == "" ||
      await this.dbService.readSecurityToken() == ""
    ) {
      //log("readEverythingEventually: no connection or not logged in")
    } else {
      //log("readEverythingEventually: " + this.iEntityToLoadAhead)
      try {
        if (this.iEntityToLoadAhead == 1) {
          if ((await this.dbService.loadTable("Kundenkategorie")).length == 0) {
            log("reading customer categories")
            await this.customerCategories.readAll()
          }
        } else if (this.iEntityToLoadAhead == 2) {
          if ((await this.dbService.loadTable("Geschlecht")).length == 0) {
            log("reading sexes")
            await this.sexes.readAll()
          }
        } else if (this.iEntityToLoadAhead == 3) {
          if ((await this.dbService.loadTable("Kontaktart")).length == 0) {
            log("reading contact types")
            await this.contactTypes.readAll()
          }
        } else if (this.iEntityToLoadAhead == 4) {
          if ((await this.dbService.loadCountries()).length == 0) {
            log("reading countries")
            await this.countries.readAll()
          }
        } else if (this.iEntityToLoadAhead == 5) {
          if ((await this.dbService.loadTableWithActiveFlag("Zahlungsart")).length == 0) {
            log("reading payment types")
            await this.paymentTypes.readAll()
          }
        } else if (this.iEntityToLoadAhead == 6) {
          if ((await this.dbService.loadTableWithActiveFlag("Zahlungsziel")).length == 0) {
            log("reading payment targets")
            await this.paymentTargets.readAll()
          }
        } else if (this.iEntityToLoadAhead == 7) {
          if ((await this.dbService.loadTableWithActiveFlag("Preisliste")).length == 0) {
            log("reading price lists")
            await this.priceLists.readAll()
          }
        } else if (this.iEntityToLoadAhead == 8) {
          if ((await this.dbService.loadTable("Auftragsstatus")).length == 0) {
            log("reading states orders")
            await this.statesOrder.readAll()
          }
        } else if (this.iEntityToLoadAhead == 9) {
          if ((await this.dbService.loadTable("Stornierungsgrund")).length == 0) {
            log("reading cancellation reasons")
            await this.cancellationReasons.readAll()
          }
        } else if (this.iEntityToLoadAhead == 10) {
          if ((await this.dbService.loadTable("Aufgabenkategorie")).length == 0) {
            log("reading task categories")
            await this.taskCategories.readAll()
          }
        } else if (this.iEntityToLoadAhead == 11) {
          if ((await this.dbService.loadTable("Wartungskategorie")).length == 0) {
            log("reading maintenance categories")
            await this.maintenanceCategories.readAll()
          }
        } else if (this.iEntityToLoadAhead == 12) {
          if ((await this.dbService.loadTable("Wartungsstatus")).length == 0) {
            log("reading maintenance states")
            await this.maintenanceStates.readAll()
          }
        } else if (this.iEntityToLoadAhead == 13) {
          if ((await this.dbService.loadTable("Steuersatz")).length == 0) {
            log("reading tax reates")
            await this.taxRates.readAll()
          }
        } else if (this.iEntityToLoadAhead == 14) {
          if ((await this.dbService.loadTable("Lager")).length == 0) {
            log("reading warehouses")
            await this.warehouses.readAll()
          }
        } else if (this.iEntityToLoadAhead == 15) {
          /*
          if ((await this.dbService.loadTable("LagerdatenArtikel")).length == 0) {
            log("reading stockDataArticles")
            await this.stockDataArticles.readAll()
          }
          */
        } else if (this.iEntityToLoadAhead == 16) {
          if ((await this.dbService.loadTableWithActiveFlag("Artikelgruppe")).length == 0) {
            log("reading price lists")
            await this.priceLists.readAll()
          }
        } else if (this.iEntityToLoadAhead == 17) {
          //await this.readCustomers("number", true)
        } else if (this.iEntityToLoadAhead == 18) {
          //await this.readAppointments()
        } else if (this.iEntityToLoadAhead == 19) {
          //await this.readTasks("due", true)
        } else if (this.iEntityToLoadAhead == 20) {
          //await this.readArticles("number", true)
        } else if (this.iEntityToLoadAhead == 21) {
          //await this.readPartners()
        } else if (this.iEntityToLoadAhead == 22) {
          //await this.readOffers("", "number", true)
        } else if (this.iEntityToLoadAhead == 23) {
          //await this.readOrders("", "number", true)
        } else if (this.iEntityToLoadAhead == 24) {
          //await this.readInvoices("", "number", true)
        } else if (this.iEntityToLoadAhead == 25) {
          //await this.readCustomerContacts("")
        } else if (this.iEntityToLoadAhead == 26) {
          this.iEntityToLoadAhead = 0
        }
      } catch (err) {
        log("caught error")
        log(err)
      }

      this.iEntityToLoadAhead++
    }
  }

  async readUtilityData(key: string) {
    return this.dbService.readUtilityData(key)
  }

  async updateUtilityData(key: string, value: string) {
    return this.dbService.writeUtilityData(key, value)
  }

  async readNameUser(): Promise<string> {
    return this.dbService.readNameUser()
  }

  async updateNameUser(nameUser: string) {
    return this.dbService.writeNameUser(nameUser)
  }

  async readSecurityToken() {
    return this.dbService.readSecurityToken()
  }

  async updateSecurityToken(securityToken: string) {
    return this.dbService.writeSecurityToken(securityToken)
  }

  async readFlagDoSync(): Promise<boolean> {
    return this.dbService.readFlagDoSync()
  }

  async updateFlagDoSync(flagDoSync: boolean) {
    return this.dbService.writeFlagDoSync(flagDoSync)
  }

  async init() {
    let currencyFromServer = this.networkService.getCurrency()
    if (currencyFromServer) {
      this.parametersStart.currency = currencyFromServer
      await this.dbService.writeUtilityData("currency", currencyFromServer)
    } else
      this.parametersStart.currency = await this.dbService.readUtilityData("currency")

    await this.readCompany()
  }

  async readCompany(): Promise<CompanyModel> {
    let company = new CompanyModel()
    let companyFromServer = this.networkService.getCompany()

    if (companyFromServer) {
      company = new CompanyModel(companyFromServer)
      company.uuid = CommonsService.UUID_DUMMY
      await this.dbService.saveOrUpdateItemFromServer(company, "Firma")
    } else {
      let rows = await this.dbService.loadTable("Firma")
      company = new CompanyModel(rows[0])
    }

    return Promise.resolve(company)
  }

  async readCustomerContacts(uuidCustomer: string): Promise<CustomerContactModel[]> {
    let customerContacts: CustomerContactModel[] = []
    let customerContactsFromServer = await this.networkService.readTable("Kundenkontakt")

    if (customerContactsFromServer) {
      customerContacts = <CustomerContactModel[]>this.modelFactory.newModels(CustomerContactModel, customerContactsFromServer)
      this.dbService.updateTable("Kundenkontakt", customerContacts)
    } else {
      let objs = await this.dbService.loadCustomerContacts(uuidCustomer)
      customerContacts = <CustomerContactModel[]>this.modelFactory.newModels(CustomerContactModel, objs)
    }

    customerContacts = customerContacts.filter((customerContact) => {
      return customerContact.uuidCustomer == uuidCustomer
    })
    CommonsService.sort(customerContacts, "date", false)

    return Promise.resolve(customerContacts)
  }

  async readCustomerContact(uuid: string): Promise<CustomerContactModel> {
    let contact = new CustomerContactModel()
    let contactFromServer = await this.networkService.readTableEntry("Kundenkontakt", uuid)

    if (contactFromServer) {
      contact = new CustomerContactModel(contactFromServer)
      this.dbService.updateEditedModelByUuid(contact, "Kundenkontakt") // no await! just initiate write
    } else {
      let obj: any = await this.dbService.loadTableEntryByUuid("Kundenkontakt", uuid)
      contact = new CustomerContactModel(obj)
    }

    return Promise.resolve(contact)
  }

  async createCustomerContact(customerContact: CustomerContactModel) {
    await this.updateCustomerOnCustomerContact(customerContact)
    await this.dbService.saveNewModelManipulationIncrement("Kunde",
      "Werbemaßnahmen Anzahl_139", customerContact.uuidCustomer);

    let reachedServer = await this.networkService.createTableEntry("Kundenkontakt", customerContact)
    await this.dbService.saveNewObjectLocally(customerContact, "Kundenkontakt")

    if (!reachedServer)
      await this.dbService.saveNewModelManipulation(customerContact, "Kundenkontakt")
  }

  async updateCustomerContact(customerContactBeforeEdit: CustomerContactModel, customerContact: CustomerContactModel) {
    await this.updateCustomerOnCustomerContact(customerContact)

    let reachedServer = await this.networkService.updateTableEntry("Kundenkontakt", customerContact)
    await this.dbService.updateEditedModelByUuid(customerContact, "Kundenkontakt");

    if (!reachedServer)
      await this.dbService.saveEditedModelManipulation(customerContactBeforeEdit, customerContact, "Kundenkontakt");
  }

  private async updateCustomerOnCustomerContact(customerContact: CustomerContactModel) {
    var strTime = CommonsService.dateToMySQLString(customerContact.date);
    await this.dbService.saveNewModelManipulationOfTime("Kunde",
      "letzter Kontakt/Werbung_142", strTime, customerContact.uuidCustomer);

    let customer: CustomerModel = <CustomerModel>await this.customers.read(customerContact.uuidCustomer)

    if (!customer.lastContact || customer.lastContact.getTime() < customerContact.date.getTime()) {
      let customerPrev: CustomerModel = new CustomerModel(customer)

      customer.lastContact = customerContact.date;
      customer.nextContact = new Date(customer.lastContact)
      customer.nextContact.setDate(customer.nextContact.getDate() + customer.contactFrequencyDays)
      await this.customers.update(customerPrev, customer)
    }
  }

  async deleteCustomerContact(uuid: string) {
    let reachedServer = await this.networkService.deleteTableEntry("Kundenkontakt", uuid)
    await this.dbService.deleteTableEntryByUuid("Kundenkontakt", uuid);

    if (!reachedServer)
      await this.dbService.saveDeleteModelManipulations("Kundenkontakt", uuid);
  }

  async updateStockAfterStockMovement(uuidStockMovement: string) {
    let reachedServer = await this.networkService.updateStockAfterStockMovement(uuidStockMovement)

    if (!reachedServer)
      await this.dbService.saveUpdateStockAfterStockMovementManipulation(uuidStockMovement)

    // TODO: this.dbService.updateStockAfterStockMovement(uuidStockMovement)
  }

  async createOffer(offer: OfferModel) {
    log("create offer")
    let reachedServer = await this.networkService.createSale("Angebot", offer)
    await this.dbService.saveNewObjectLocally(offer, "Angebot")

    if (reachedServer) {
      this.updateSalePositions(new OfferModel(), offer, "Angebotspositionen", false)
    } else {
      await this.dbService.saveNewModelManipulation(offer, "Angebot");
      this.updateSalePositions(new OfferModel(), offer, "Angebotspositionen", true)
    }
  }

  async createOrder(order: OrderModel) {
    let reachedServer = await this.networkService.createSale("Auftrag", order)
    await this.dbService.saveNewObjectLocally(order, "Auftrag")

    if (reachedServer) {
      this.updateSalePositions(new OrderModel(), order, "Auftragspositionen", false)
    } else {
      await this.dbService.saveNewModelManipulation(order, "Auftrag");
      this.updateSalePositions(new OrderModel(), order, "Auftragspositionen", true)
    }
  }

  async createInvoice(invoice: InvoiceModel) {
    let reachedServer = await this.networkService.createSale("Rechnung", invoice)
    await this.dbService.saveNewObjectLocally(invoice, "Rechnung")

    if (reachedServer) {
      this.updateSalePositions(new InvoiceModel(), invoice, "Rechnungspositionen", false)
    } else {
      await this.dbService.saveNewModelManipulation(invoice, "Rechnung");
      this.updateSalePositions(new InvoiceModel(), invoice, "Rechnungspositionen", true)
    }
  }

  async readOpenOffers(sortColumn: string, sortAscending: boolean): Promise<OfferModel[]> {
    await this.init()

    let openOffers: OfferModel[] = []
    let offersFromServer = await this.networkService.readOffers()

    if (offersFromServer) {
      let cancellationReasons: CancellationReasonModel[] =
        <CancellationReasonModel[]>await this.cancellationReasons.readAll()
      let taxRates = await this.taxRates.readAll()
      let offers: OfferModel[] = []
      for (let offerFS of offersFromServer) {
        let offer: OfferModel = new OfferModel(offerFS)
        let positions = <OfferPositionModel[]>this.modelFactory.newModels(OfferPositionModel, offer.positions)
        for (let position of positions) {
          position.taxRate = <TaxRateModel | null>CommonsService.getObjectByUuid(taxRates, position.uuidTaxRate)
        }
        offer.setPositions(positions, this.parametersStart.currency)
        offers.push(offer)

        if ((offer.uuidOrder == null ||
          offer.uuidOrder == "") &&
          (offer.uuidCancellationReason == null ||
            offer.uuidCancellationReason == "" ||
            offer.uuidCancellationReason == cancellationReasons[0].uuid))
          openOffers.push(offer)
      }
      this.dbService.updateSales("Angebot", "Angebotspositionen", offers) // no await! only initiate write, store all offers
    } else {
      let objs: any[] = await this.dbService.loadOpenSales("Angebot")
      objs.forEach(obj => {
        let openOffer: OfferModel = new OfferModel(obj)
        CommonsService.setPrices(openOffer, this.parametersStart.currency)
        openOffers.push(openOffer)
      });
    }

    CommonsService.sort(openOffers, sortColumn, sortAscending)

    return Promise.resolve(openOffers)
  }

  async readOpenOrders(sortColumn: string, sortAscending: boolean): Promise<OrderModel[]> {
    await this.init()

    let openOrders: OrderModel[] = []
    CommonsService.startTime("readOpenOrders")
    let ordersFromServer = await this.networkService.readOrders()
    CommonsService.stopTime("readOpenOrders")

    if (ordersFromServer) {
      let statesOrder: StateOrderModel[] = <StateOrderModel[]>await this.statesOrder.readAll()
      let orders: OrderModel[] = []
      let taxRates = await this.taxRates.readAll()

      for (let orderFS of ordersFromServer) {
        let order: OrderModel = new OrderModel(orderFS)
        let positions = <OrderPositionModel[]>this.modelFactory.newModels(OrderPositionModel, order.positions)
        for (let position of positions) {
          position.taxRate = <TaxRateModel | null>CommonsService.getObjectByUuid(taxRates, position.uuidTaxRate)
        }
        order.setPositions(positions, this.parametersStart.currency)
        orders.push(order)

        if (
          order.booked && (
            order.uuidStateOrder == statesOrder[1].uuid ||
            order.uuidStateOrder == statesOrder[2].uuid ||
            order.uuidStateOrder == statesOrder[3].uuid)) {
          openOrders.push(order)
        }
      }

      this.dbService.updateSales("Auftrag", "Auftragspositionen", orders) // no await! only initiate write
    } else {
      let objs: any[] = await this.dbService.loadOpenSales("Auftrag")
      objs.forEach(obj => {
        let order: OrderModel = new OrderModel(obj)
        CommonsService.setPrices(order, this.parametersStart.currency)
        openOrders.push(order)
      });
    }

    CommonsService.sort(openOrders, sortColumn, sortAscending)

    return Promise.resolve(openOrders)
  }

  async readOffers(uuidCustomer: string, sortColumn: string, sortAscending: boolean): Promise<OfferModel[]> {
    await this.init()

    let offers: OfferModel[] = []
    CommonsService.startTime("readOffers")
    let offersFromServer = await this.networkService.readOffers(uuidCustomer)
    CommonsService.stopTime("readOffers")

    let taxRates = await this.taxRates.readAll()

    if (offersFromServer) {
      for (let offerFS of offersFromServer) {
        let offer: OfferModel = new OfferModel(offerFS)
        let positions = <OfferPositionModel[]>this.modelFactory.newModels(OfferPositionModel, offer.positions)
        for (let position of positions) {
          position.taxRate = <TaxRateModel | null>CommonsService.getObjectByUuid(taxRates, position.uuidTaxRate)
        }
        offer.setPositions(positions, this.parametersStart.currency)
        offers.push(offer)
      }
      for (let offer of offers) {
        this.updateOfferLocally(offer) // no await! only initiate write
      }
    } else {
      let objs: any[] = await this.dbService.loadSales(uuidCustomer, "Angebot", this.parametersStart.currency)
      objs.forEach(obj => {
        let offer: OfferModel = new OfferModel(obj)
        CommonsService.setPrices(offer, this.parametersStart.currency)
        offers.push(offer)
      });
    }

    CommonsService.sort(offers, sortColumn, sortAscending)

    return Promise.resolve(offers)
  }

  async readOrders(uuidCustomer: string, sortColumn: string, sortAscending: boolean): Promise<OrderModel[]> {
    await this.init()

    let orders: OrderModel[] = []
    CommonsService.startTime("readOrders")
    let ordersFromServer = await this.networkService.readOrders(uuidCustomer)
    CommonsService.stopTime("readOrders")

    let taxRates = await this.taxRates.readAll()

    if (ordersFromServer) {
      for (let offerFS of ordersFromServer) {
        let order: OrderModel = new OrderModel(offerFS)
        let positions = <OrderPositionModel[]>this.modelFactory.newModels(OrderPositionModel, order.positions)
        for (let position of positions) {
          position.taxRate = <TaxRateModel | null>CommonsService.getObjectByUuid(taxRates, position.uuidTaxRate)
        }
        order.setPositions(positions, this.parametersStart.currency)
        orders.push(order)
      }
      for (let order of orders) {
        this.updateOrderLocally(order) // no await! only initiate write
      }
    } else {
      let objs: any[] = await this.dbService.loadSales(uuidCustomer, "Auftrag", this.parametersStart.currency)
      objs.forEach(obj => {
        let order: OrderModel = new OrderModel(obj)
        CommonsService.setPrices(order, this.parametersStart.currency)
        orders.push(order)
      });
    }

    CommonsService.sort(orders, sortColumn, sortAscending)

    return Promise.resolve(orders)
  }

  async readInvoices(uuidCustomer: string, sortColumn: string, sortAscending: boolean): Promise<InvoiceModel[]> {
    await this.init()

    let invoices: InvoiceModel[] = []
    CommonsService.startTime("readInvoices")
    let invoicesFromServer = await this.networkService.readInvoices(uuidCustomer)
    CommonsService.stopTime("readInvoices")

    let taxRates = await this.taxRates.readAll()

    if (invoicesFromServer) {
      for (let invoiceFS of invoicesFromServer) {
        let invoice: InvoiceModel = new InvoiceModel(invoiceFS)
        let positions = <InvoicePositionModel[]>this.modelFactory.newModels(InvoicePositionModel, invoice.positions)
        for (let position of positions) {
          position.taxRate = <TaxRateModel | null>CommonsService.getObjectByUuid(taxRates, position.uuidTaxRate)
        }
        invoice.setPositions(positions, this.parametersStart.currency)
        invoices.push(invoice)
      }
      for (let invoice of invoices) {
        this.updateInvoiceLocally(invoice) // no await! only initiate write
      }
    } else {
      let objs: any[] = await this.dbService.loadSales(uuidCustomer, "Rechnung", this.parametersStart.currency)
      objs.forEach(obj => {
        let invoice: InvoiceModel = new InvoiceModel(obj)
        CommonsService.setPrices(invoice, this.parametersStart.currency)
        invoices.push(invoice)
      });
    }

    CommonsService.sort(invoices, sortColumn, sortAscending)

    return Promise.resolve(invoices)
  }

  async readOffer(uuid: string): Promise<OfferModel> {
    await this.init()

    CommonsService.startTime("readOffer")
    let offer: OfferModel = new OfferModel()
    let offerFromServer = await this.networkService.readSale("Angebot", uuid)

    let taxRates = await this.taxRates.readAll()

    if (offerFromServer) {
      offer = new OfferModel(offerFromServer)
      let positions: OfferPositionModel[] = <OfferPositionModel[]>this.modelFactory.newModels(OfferPositionModel, offerFromServer.positions)
      for (let position of positions) {
        position.taxRate = <TaxRateModel | null>CommonsService.getObjectByUuid(taxRates, position.uuidTaxRate)
      }
      offer.setPositions(positions, this.parametersStart.currency)
      this.updateOfferLocally(offer) // no await! just initiate update
    } else {
      let obj = await this.dbService.loadSale(uuid, "Angebot", "Angebotspositionen", this.parametersStart.currency)
      if (!obj)
        throw new DataNotAvailableError("Data not available - Angebot " + uuid)
      offer = new OfferModel(obj)
      let positions: OfferPositionModel[] = <OfferPositionModel[]>this.modelFactory.newModels(OfferPositionModel, obj.positions)
      for (let position of positions) {
        position.taxRate = <TaxRateModel | null>CommonsService.getObjectByUuid(taxRates, position.uuidTaxRate)
      }
      offer.setPositions(positions, this.parametersStart.currency)
    }
    CommonsService.stopTime("readOffer")
    return Promise.resolve(offer)
  }

  async readOrder(uuid: string): Promise<OrderModel> {
    await this.init()

    CommonsService.startTime("readOrder")
    let order: OrderModel = new OrderModel()
    let orderFromServer = await this.networkService.readSale("Auftrag", uuid)

    let taxRates = await this.taxRates.readAll()

    if (orderFromServer) {
      order = new OrderModel(orderFromServer)
      let positions: OrderPositionModel[] = <OrderPositionModel[]>this.modelFactory.newModels(OrderPositionModel, orderFromServer.positions)
      for (let position of positions) {
        position.taxRate = <TaxRateModel | null>CommonsService.getObjectByUuid(taxRates, position.uuidTaxRate)
      }
      order.setPositions(positions, this.parametersStart.currency)
      this.updateOrderLocally(order) // no await! just initiate update
    } else {
      let obj = await this.dbService.loadSale(uuid, "Auftrag", "Auftragspositionen", this.parametersStart.currency)
      if (!obj)
        throw new DataNotAvailableError("Data not available - Auftrag " + uuid)
      order = new OrderModel(obj)
      let positions: OrderPositionModel[] = <OrderPositionModel[]>this.modelFactory.newModels(OrderPositionModel, obj.positions)
      for (let position of positions) {
        position.taxRate = <TaxRateModel | null>CommonsService.getObjectByUuid(taxRates, position.uuidTaxRate)
      }
      order.setPositions(positions, this.parametersStart.currency)
    }

    CommonsService.stopTime("readOrder")
    return Promise.resolve(order)
  }

  async readInvoice(uuid: string): Promise<InvoiceModel> {
    await this.init()

    CommonsService.startTime("readInvoice")
    let invoice: InvoiceModel = new InvoiceModel()
    let invoiceFromServer = await this.networkService.readSale("Rechnung", uuid)

    let taxRates = await this.taxRates.readAll()

    if (invoiceFromServer) {
      invoice = new InvoiceModel(invoiceFromServer)
      let positions: InvoicePositionModel[] = <InvoicePositionModel[]>this.modelFactory.newModels(InvoicePositionModel, invoiceFromServer.positions)
      for (let position of positions) {
        position.taxRate = <TaxRateModel | null>CommonsService.getObjectByUuid(taxRates, position.uuidTaxRate)
      }
      invoice.setPositions(positions, this.parametersStart.currency)
      this.updateInvoiceLocally(invoice) // no await! just initiate update
    } else {
      let obj = await this.dbService.loadSale(uuid, "Rechnung", "Rechnungspositionen", this.parametersStart.currency)
      if (!obj)
        throw new DataNotAvailableError("Data not available - Rechnung " + uuid)
      invoice = new InvoiceModel(obj)
      let positions: InvoicePositionModel[] = <InvoicePositionModel[]>this.modelFactory.newModels(InvoicePositionModel, obj.positions)
      for (let position of positions) {
        position.taxRate = <TaxRateModel | null>CommonsService.getObjectByUuid(taxRates, position.uuidTaxRate)
      }
      invoice.setPositions(positions, this.parametersStart.currency)
    }

    CommonsService.stopTime("readInvoice")
    return Promise.resolve(invoice)
  }

  async updateOfferLocally(sale: OfferModel) {
    let saleOld = await this.dbService.loadSale(sale.uuid, "Angebot", "Angebotspositionen", this.parametersStart.currency)

    if (!saleOld) {
      await this.dbService.saveNewObjectLocally(sale, "Angebot")
      await this.updateSalePositions(new SaleModel(), sale, "Angebotspositionen", false)
    } else {
      let saleOldTs = new OfferModel(saleOld)
      let positions: OfferPositionModel[] = <OfferPositionModel[]>this.modelFactory.newModels(OfferPositionModel, saleOld.positions)
      saleOldTs.setPositions(positions, this.parametersStart.currency)
      await this.dbService.updateEditedModelByUuid(sale, "Angebot")
      await this.updateSalePositions(saleOldTs, sale, "Angebotspositionen", false)
    }
  }

  async updateOrderLocally(sale: OrderModel) {
    let saleOld = await this.dbService.loadSale(sale.uuid, "Auftrag", "Auftragspositionen", this.parametersStart.currency)
    if (!saleOld) {
      await this.dbService.saveNewObjectLocally(sale, "Auftrag")
      await this.updateSalePositions(new SaleModel(), sale, "Auftragspositionen", false)
    } else {
      let saleOldTs = new OrderModel(saleOld)
      let positions: OrderPositionModel[] = <OrderPositionModel[]>this.modelFactory.newModels(OrderPositionModel, saleOld.positions)
      saleOldTs.setPositions(positions, this.parametersStart.currency)
      await this.dbService.updateEditedModelByUuid(sale, "Auftrag")
      await this.updateSalePositions(saleOldTs, sale, "Auftragspositionen", false)
    }
  }

  async updateInvoiceLocally(sale: InvoiceModel) {
    let saleOld = await this.dbService.loadSale(sale.uuid, "Rechnung", "Rechnungspositionen", this.parametersStart.currency)
    if (!saleOld) {
      await this.dbService.saveNewObjectLocally(sale, "Rechnung")
      await this.updateSalePositions(new SaleModel(), sale, "Rechnungspositionen", false)
    } else {
      let saleOldTs = new InvoiceModel(saleOld)
      let positions: InvoicePositionModel[] = <InvoicePositionModel[]>this.modelFactory.newModels(InvoicePositionModel, saleOld.positions)
      saleOldTs.setPositions(positions, this.parametersStart.currency)
      await this.dbService.updateEditedModelByUuid(sale, "Rechnung")
      await this.updateSalePositions(saleOldTs, sale, "Rechnungspositionen", false)
    }
  }

  async updateOffer(offerBeforeEdit: OfferModel, offer: OfferModel) {
    let reachedServer = await this.networkService.updateSale("Angebot", offer)
    await this.dbService.updateEditedModelByUuid(offer, "Angebot")

    if (reachedServer) {
      await this.updateSalePositions(offerBeforeEdit, offer, "Angebotspositionen", false)
    } else {
      await this.updateSalePositions(offerBeforeEdit, offer, "Angebotspositionen", true)
      await this.dbService.saveEditedModelManipulation(offerBeforeEdit, offer, "Angebot")
    }
  }

  async updateOrder(orderBeforeEdit: OrderModel, order: OrderModel) {
    let reachedServer = await this.networkService.updateSale("Auftrag", order)
    await this.dbService.updateEditedModelByUuid(order, "Auftrag")

    if (reachedServer) {
      await this.updateSalePositions(orderBeforeEdit, order, "Auftragspositionen", false)
    } else {
      await this.updateSalePositions(orderBeforeEdit, order, "Auftragspositionen", true)
      await this.dbService.saveEditedModelManipulation(orderBeforeEdit, order, "Auftrag")
    }
  }

  async updateInvoice(invoiceBeforeEdit: InvoiceModel, invoice: InvoiceModel) {
    let reachedServer = await this.networkService.updateSale("Rechnung", invoice)
    await this.dbService.updateEditedModelByUuid(invoice, "Rechnung")

    if (reachedServer) {
      await this.updateSalePositions(invoiceBeforeEdit, invoice, "Rechnungspositionen", false)
    } else {
      await this.updateSalePositions(invoiceBeforeEdit, invoice, "Rechnungspositionen", true)
      await this.dbService.saveEditedModelManipulation(invoiceBeforeEdit, invoice, "Rechnung")
    }
  }

  private async updateSalePositions(saleBeforeEdit: SaleModel, sale: SaleModel, nameModelPosition: string, writeManipulations: boolean) {
    let arrUuidsBeforeEdit = saleBeforeEdit.getArrUuidsOfPositions()

    for (let iPos = 0; iPos < sale.getNumPositions(); iPos++) {
      let position: SalePositionModel = sale.getPosition(iPos)
      let iPosBeforeEdit = arrUuidsBeforeEdit.indexOf(position.uuid)
      if (iPosBeforeEdit >= 0) {
        // update existing
        log("updateSalePositions: updating existing position")
        arrUuidsBeforeEdit.splice(iPosBeforeEdit, 1)
        let positionBeforeEdit = saleBeforeEdit.getPositionByUuid(position.uuid)
        await this.dbService.updateEditedModelByUuid(position, nameModelPosition);
        if (writeManipulations)
          await this.dbService.saveEditedModelManipulation(positionBeforeEdit, position, nameModelPosition)
      } else {
        // store new position
        log("updateSalePositions: store new position")
        await this.dbService.saveNewObjectLocally(position, nameModelPosition)
        if (writeManipulations)
          await this.dbService.saveNewModelManipulation(position, nameModelPosition);
      }
    }

    for (let iPosOld = 0; iPosOld < arrUuidsBeforeEdit.length; iPosOld++) {
      // delete old position
      log("updateSalePositions: deleting old position")
      let oldPosition: SalePositionModel | null = saleBeforeEdit.getPositionByUuid(arrUuidsBeforeEdit[iPosOld])
      if (oldPosition) {
        await this.dbService.deleteTableEntryByUuid(nameModelPosition, oldPosition.uuid)
        if (writeManipulations)
          await this.dbService.saveDeleteModelManipulations(nameModelPosition, oldPosition.uuid)
      }
    }
  }

  async readOfferPosition(uuid: string): Promise<OfferPositionModel> {
    await this.init()

    let position: OfferPositionModel = new OfferPositionModel()
    let positionFromServer = await this.networkService.readTableEntry("Angebotspositionen", uuid)

    if (positionFromServer) {
      position = new OfferPositionModel(positionFromServer)
      this.dbService.updateEditedModelByUuid(position, "Angebotspositionen")
    } else {
      let obj = await this.dbService.loadTableEntryByUuid("Angebotspositionen", uuid)
      if (!obj)
        throw new DataNotAvailableError("Data not available - Angebotsposition " + uuid)
      position = new OfferPositionModel(obj)
    }

    CommonsService.setPrices(position, this.parametersStart.currency)
    return Promise.resolve(position)
  }

  async readOrderPosition(uuid: string): Promise<OrderPositionModel> {
    await this.init()

    let position: OrderPositionModel = new OrderPositionModel()
    let positionFromServer = await this.networkService.readTableEntry("Auftragspositionen", uuid)

    if (positionFromServer) {
      position = new OrderPositionModel(positionFromServer)
      this.dbService.updateEditedModelByUuid(position, "Auftragspositionen")
    } else {
      let obj = await this.dbService.loadTableEntryByUuid("Auftragspositionen", uuid)
      if (!obj)
        throw new DataNotAvailableError("Data not available - Auftragsposition " + uuid)
      position = new OrderPositionModel(obj)
    }

    CommonsService.setPrices(position, this.parametersStart.currency)
    return Promise.resolve(position)
  }

  async readInvoicePosition(uuid: string): Promise<InvoicePositionModel> {
    await this.init()

    let position: InvoicePositionModel = new InvoicePositionModel()
    let positionFromServer = await this.networkService.readTableEntry("Rechnungspositionen", uuid)

    if (positionFromServer) {
      position = new InvoicePositionModel(positionFromServer)
      this.dbService.updateEditedModelByUuid(position, "Rechnungspositionen")
    } else {
      let obj = await this.dbService.loadTableEntryByUuid("Rechnungspositionen", uuid)
      if (!obj)
        throw new DataNotAvailableError("Data not available - Rechnungsposition " + uuid)
      position = new InvoicePositionModel(obj)
    }

    CommonsService.setPrices(position, this.parametersStart.currency)
    return Promise.resolve(position)
  }

  async deleteOffer(uuid: string) {
    let reachedServer = await this.networkService.deleteTableEntry("Angebot", uuid)
    await this.dbService.deleteSale(uuid, "Angebot")

    if (!reachedServer)
      await this.dbService.saveDeleteModelManipulations("Angebot", uuid)
  }

  async deleteOrder(uuid: string) {
    let reachedServer = await this.networkService.deleteTableEntry("Auftrag", uuid)
    await this.dbService.deleteSale(uuid, "Auftrag")

    if (!reachedServer)
      await this.dbService.saveDeleteModelManipulations("Auftrag", uuid)
  }

  async deleteInvoice(uuid: string) {
    let reachedServer = await this.networkService.deleteTableEntry("Rechnung", uuid)
    await this.dbService.deleteSale(uuid, "Rechnung")

    if (!reachedServer)
      await this.dbService.saveDeleteModelManipulations("Rechnung", uuid)
  }

  async readEnquiries(sortColumn: string, sortAscending: boolean): Promise<OfferModel[]> {
    await this.init()

    let offers = await this.readOffers("", "number", true)

    var dateToday = new Date();
    var dateStart = new Date("2000-01-01T01:00:00.000Z");
    //let dateStart = new Date("1969-01-01T01:00:00.000Z");
    //let dateStart = new Date("1869-01-01T01:00:00.000Z");

    let enquiriesR: any[] = offers
    CommonsService.sort(enquiriesR, sortColumn, sortAscending)
    enquiriesR = enquiriesR.filter((enquiry) => {
      CommonsService.setPrices(enquiry, this.parametersStart.currency);

      enquiry.date = new Date(enquiry.date);
      enquiry.textDate = CommonsService.formatDate0(enquiry.date);

      enquiry.enquiryDate = new Date(enquiry.enquiryDate);
      enquiry.textEnquiryDate = CommonsService.formatDate0(enquiry.enquiryDate);

      if (enquiry.enquiryDate > dateStart && enquiry.enquiryDate <= dateToday)
        return true

      return false
    })

    let enquiries: OfferModel[] = <OfferModel[]>this.modelFactory.newModels(OfferModel, enquiriesR)

    return Promise.resolve(enquiries)
  }

  async readStocksOfArticleInWarehouses(uuidArticle: string): Promise<StocksOfArticleModel[]> {
    let stocksOfArticleFromServer = await this.networkService.readStocksOfArticleInWarehouses(uuidArticle)
    let stocksOfArticle: StocksOfArticleModel[] = []
    if (stocksOfArticleFromServer)
      stocksOfArticle = <StocksOfArticleModel[]>this.modelFactory.newModels(StocksOfArticleModel, stocksOfArticleFromServer)
    return Promise.resolve(stocksOfArticle)
  }
}
