import { BaseModel } from "./base-model"

declare const log: any

export class CustomerModel extends BaseModel {

  lastName: string = ""
  familiar: boolean = false
  academicTitle: string = ""
  firstName: string = ""
  number: string = ""
  street: string = ""
  zipCode: string = ""
  city: string = ""
  telephone1: string = ""
  telephone2: string = ""
  fax: string = ""
  email: string = ""
  birthday: Date = new Date(0)
  homepage: string = ""
  remarks: string = ""
  numberUID: string = ""
  uuidSex: string = ""
  uuidCountry: string = ""
  uuidsCustomerCategories: string = ""
  //uuidsCustomerCategoriesArr: string[] = []
  uuidPaymentType: string = ""
  uuidPaymentTarget: string = ""
  uuidPricelist: string = ""
  discount: number = 0
  keywords: string = ""
  taxExempt: boolean = false
  lastContact: Date = new Date()
  nextContact: Date = new Date(new Date().getTime() + (30 * 1000 * 60 * 60 * 24))
  contactFrequencyDays: number = 30
  nextContactTopic: string = ""
  dateNextFreq: Date = new Date(0)
  textNextContact: string = ""

  constructor(params: any = null) {
    super(params)
    this.copyFromObject(params)
    //this.buildUuidsCustomerCategoriesArr()
  }

  /*
  buildUuidsCustomerCategoriesArr() {
    this.uuidsCustomerCategoriesArr = this.uuidsCustomerCategories.split(",");
  }
  */

  static override newModel(object: any = null) {
    return new CustomerModel(object)
  }

}
