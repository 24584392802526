import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { CommonsService } from '../commons-service';
import { ParametersStartService } from '../parameters-start.service'
import { RepositoryService } from '../repository.service';
import { SaleModel } from '../models/sale-model';
declare const $: any;
declare const log: any;

@Component({
  selector: 'app-view-open-sales',
  templateUrl: './view-open-sales.component.html',
  styleUrls: ['./view-open-sales.component.css']
})
export class ViewOpenSalesComponent implements OnInit {

  showLinks = true
  typeSales = "Offer"
  textTitle = ""

  sort = {
    column: "Datum_6",
    ascending: true
  }
  textSearch = ""
  currentPage = 0
  pageSize = environment.entriesPerPage;

  sales: SaleModel[] = [];

  constructor(
    protected router: Router,
    private route: ActivatedRoute,
    protected translate: TranslateService,
    private commons: CommonsService,
    protected repository: RepositoryService,
    private parametersStart: ParametersStartService) {
  }

  getTypeSales() {
    const routeParams = this.route.snapshot.paramMap;
    return String(routeParams.get('type'));
  }

  ngOnInit(): void {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.typeSales = this.getTypeSales()

    if (this.typeSales == "Offer") {
      this.translate.get("OPEN_SALES_TITLE_OFFERS").subscribe((translated: string) => {
        this.textTitle = translated
      })
    } else if (this.typeSales == "Order") {
      this.translate.get("OPEN_SALES_TITLE_ORDERS").subscribe((translated: string) => {
        this.textTitle = translated
      })
    } else
      throw Error("unknown type of sales " + this.typeSales)

    this.repository.updateUtilityData("locationOriginSales", "viewOpenSales/" + this.typeSales)
      .then(() => {
        return this.repository.readUtilityData("searchOpenSalesText")
      }).then((textSearch) => {
        this.textSearch = textSearch
        return this.repository.readUtilityData("sortOpenSalesColumn")
      }).then((column) => {
        this.sort.column = column
        return this.repository.readUtilityData("showOpenSalesPage" + this.typeSales)
      }).then((page) => {
        this.currentPage = +page
        return this.repository.readUtilityData("sortOpenSalesAscending")
      }).then((isAscending) => {
        this.sort.ascending = isAscending == "true";
        return this.search()
      }).then(() => {

      })
  }

  async search() {
    this.loadOpenSalesSearch().then(() => {
      if (this.currentPage > this.numberOfPages() - 1)
        this.currentPage = this.numberOfPages() - 1;
      if (this.currentPage < 0)
        this.currentPage = 0;

      this.repository.updateUtilityData("searchOpenSalesText", this.textSearch);
    });
  }

  private keyTimeout: any = null;

  keyUpEvent(event: any) {
    if (event.key.length != 1 && event.key != "Backspace") {
      event.preventDefault()
      return false;
    }

    if (this.keyTimeout) {
      clearTimeout(this.keyTimeout);
      this.keyTimeout = null;
    }

    this.keyTimeout = setTimeout(() => {
      this.search();
    }, 300)

    return true
  }

  numberOfPages() {
    if (this.sales && this.sales.length > 0)
      return Math.ceil(this.sales.length / this.pageSize);
    else
      return 1;
  }

  private async loadOpenSalesSearch() {
    var textSearchLC = "";
    if (this.textSearch && this.textSearch != "")
      textSearchLC = this.textSearch.toLowerCase();

    CommonsService.startTime("loadOpenSalesSearch")
    let openSales: SaleModel[] = []
    if (this.typeSales == "Offer")
      openSales = await this.repository.readOpenOffers(this.sort.column, this.sort.ascending)
    else if (this.typeSales == "Order")
      openSales = await this.repository.readOpenOrders(this.sort.column, this.sort.ascending)

    openSales = openSales.filter((sale: any) => {
      if (sale.number && sale.number.toLowerCase().indexOf(textSearchLC) >= 0 ||
        sale.name.toLowerCase().indexOf(textSearchLC) >= 0 ||
        sale.street.toLowerCase().indexOf(textSearchLC) >= 0 ||
        sale.city.toLowerCase().indexOf(textSearchLC) >= 0 ||
        sale.zipCode.toLowerCase().indexOf(textSearchLC) >= 0)
        return true
      else
        return false
    })
    CommonsService.stopTime("loadOpenSalesSearch")

    this.sales = openSales

    return Promise.resolve()

  }

  timeLastSorting = new Date();
  changeSorting(column: string) {
    var timeNow = new Date();
    if (timeNow.getTime() - this.timeLastSorting.getTime() < CommonsService.TIME_TRIGGER_SORTING_MS)
      return;
    this.timeLastSorting = new Date();

    if (this.sort.column == column) {
      this.sort.ascending = !this.sort.ascending;
    } else {
      this.sort.column = column;
      this.sort.ascending = false;
    }

    const this_ = this;
    this.repository.updateUtilityData("sortOpenSalesColumn", this.sort.column)
      .then(() => {
        return this.repository.updateUtilityData("sortOpenSalesAscending", "" + this.sort.ascending);
      }).then(() => {
        setTimeout(function () {
          this_.loadOpenSalesSearch();
        }, 10);
      })
  }

  gotoCustomer(idCustomerAtClient: number) {
    log("goto customer " + idCustomerAtClient);
    if (idCustomerAtClient >= 0)
      this.router.navigateByUrl("/editCustomer/" + idCustomerAtClient)
  }

  storeCurrentPage() {
    this.repository.updateUtilityData("showOpenSalesPage" + this.typeSales, "" + this.currentPage);
  }

  clickedOnSale(sale: any) {
    this.router.navigateByUrl("/editSale/" + this.typeSales + "/" + sale.uuid + "/false");
  }
}
