import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common'
import { ActivatedRoute, Router } from '@angular/router';
import { CommonsService } from '../commons-service';
import { RepositoryService } from '../repository.service';
import { AppointmentModel } from '../models/appointment-model';
import { CustomerModel } from '../models/customer-model';
declare const log: any;

@Component({
  selector: 'app-delete-appointment',
  templateUrl: './delete-appointment.component.html',
  styleUrls: ['./delete-appointment.component.css']
})
export class DeleteAppointmentComponent implements OnInit {

  uuidAppointment = ""
  appointment: AppointmentModel = new AppointmentModel()
  nameCustomer = ""
  textDateBegin = ""
  textDateEnd = ""

  constructor(
    private location: Location,
    private route: ActivatedRoute,
    private router: Router,
    private repository: RepositoryService,
  ) { }

  ngOnInit(): void {
    this.asyncInit()
  }

  async asyncInit() {
    const routeParams = this.route.snapshot.paramMap;
    this.uuidAppointment = String(routeParams.get('uuidAppointment'));

    this.appointment = <AppointmentModel>await this.repository.appointments.read(this.uuidAppointment)

    this.textDateBegin = CommonsService.formatDateTime0(this.appointment.begin)
    this.textDateEnd = CommonsService.formatDateTime0(this.appointment.end)

    try {
      let customer = <CustomerModel>await this.repository.customers.read(this.appointment.uuidCustomer)
      if (customer)
        this.nameCustomer = customer.firstName + " " + customer.lastName;
    } catch (err) {
      this.nameCustomer = "";
    }
  }

  async delete() {
    await this.repository.appointments.delete(this.uuidAppointment)
    this.location.back()
  }

}
