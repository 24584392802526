export const environment = {
  production: true,

  //SERVER_HOST: "http://www.localhost",
	SERVER_HOST: "wss://futureapp-test.futurefactory-software.com/ws/",
  // SERVER_HOST: "http://www.futurefactory.at",
  // SERVER_HOST: "http://www.pyrotrade.futurefactory.at";

  "port": "80",

  "enableLogging": true,

  "entriesPerPage": 30,

  "maxEntriesPerCall": 20,

  "enableProjectTime": true,
  "enableAppointments": true,
  "enableTasks": true,
  "enableOpenOffers": true,
  "enableOpenOrders": true,
  "enableEnquiries": true,
  "enableCustomersSuggNext": true,
  "enableCustomersSuggFreq": true,

  "enableSignaturePad": true
};
