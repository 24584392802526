import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'limitFromTo'
})
export class LimitFromToPipe implements PipeTransform {

  transform(input: any[], from: number, to: number): any[] {
    return (input != undefined) ? input.slice(from, to) : [];
  }

}
