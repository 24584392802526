import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common'
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CommonsService } from '../commons-service';
import { TaskModel } from '../models/task-model';
import { RepositoryService } from '../repository.service';
import { TaskCategoryModel } from '../models/task-category-model';
import { ModelFactory } from '../models/model-factory';
import { ModelInterface } from '../models/model-interface';
declare const log: any
declare var $: any

@Component({
  selector: 'app-edit-task',
  templateUrl: './edit-task.component.html',
  styleUrls: ['./edit-task.component.css'],
  providers: []
})
export class EditTaskComponent implements OnInit {

  textTitle = ""
  uuidTask = ""
  task: TaskModel = new TaskModel()
  taskBeforeEdit: TaskModel = new TaskModel()
  hasDateDue = false
  textDue = ""
  doneBool = false
  taskCategories: TaskCategoryModel[] = []

  constructor(
    protected location: Location,
    protected route: ActivatedRoute,
    protected router: Router,
    protected modelFactory: ModelFactory<ModelInterface>,
    protected translate: TranslateService,
    protected repository: RepositoryService
  ) { }

  ngOnInit(): void {
    this.asyncInit()
  }

  async asyncInit() {
    this.taskCategories = <TaskCategoryModel[]>await this.repository.taskCategories.readAll()
    log("categories")
    log(this.taskCategories)

    $('#div-date-due').datetimepicker({
      format: 'DD.MM.YYYY HH:mm'
    });

    await this.asyncInitSpecific()
  }

  async asyncInitSpecific() {
    this.translate.get("TASK_EDIT_TITLE").subscribe((translated: string) => this.textTitle = translated)

    const routeParams = this.route.snapshot.paramMap;
    this.uuidTask = String(routeParams.get('uuidTask'));

    let taskTemp = await this.repository.tasks.read(this.uuidTask)
    //if (!taskTemp)
    //      this.router.navigateByUrl("/start")
    let taskR = <TaskModel>taskTemp

    taskR.due = new Date(taskR.due)
    if (!taskR.due || taskR.due <= new Date(0)) {
      // no due active; use 'now'
      this.hasDateDue = false;
      this.textDue = CommonsService.formatDateTime0(new Date())
    } else {
      this.textDue = CommonsService.formatDateTime0(taskR.due)
      this.hasDateDue = true;
    }

    this.doneBool = (taskR.done);

    this.task = taskR
    log(this.task)
    this.taskBeforeEdit.copyFromObject(this.task);
  }

  resetTimeDue() {
  }

  showTimeDue() {
    this.hasDateDue = true
    log(this.task)
    if (this.task.due.getTime() == 0) {
      this.task.due = new Date()
      this.textDue = CommonsService.formatDateTime0(this.task.due)
    }
  }

  hideTimeDue() {
    this.hasDateDue = false
  }

  async save() {

    if (this.hasDateDue) {
      try {
        this.task.due = CommonsService.parseDateTime($("#input-date-due").val())
      } catch (err) {
        CommonsService.showErrorMessage(this.translate.instant("TASK_EDIT_DATE_TIME_NOT_VALID"))
        return
      }
    } else {
      this.task.due = new Date(CommonsService.TIME_NOT_VALID_MS);
    }

    this.saveSpecific()
  }

  async saveSpecific() {
    if (this.doneBool) {
      if (!this.taskBeforeEdit.done)
        this.task.dateDone = new Date()
      this.task.done = true
    } else {
      this.task.dateDone = new Date(CommonsService.TIME_NOT_VALID_MS)
      this.task.done = false
    }

    await this.repository.tasks.update(this.taskBeforeEdit, this.task)
    //this.location.back()
    CommonsService.showSuccessMessage(this.translate.instant("GENERAL_SAVED"))
  }

}
