import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CommonsService } from '../commons-service';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateCustomParserFormatter } from '../custom-date-parser-formatter'
import { NetworkService } from '../network.service';
import { CustomerModel } from '../models/customer-model';
import { RepositoryService } from '../repository.service';
declare const $: any;
declare const log: any;

@Component({
  selector: 'app-view-customer-sales-history',
  templateUrl: './view-customer-sales-history.component.html',
  styleUrls: ['./view-customer-sales-history.component.css'],
  providers: [
    { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter }
  ]
})
export class ViewCustomerSalesHistoryComponent implements OnInit {

  uuidCustomer = "";
  customer = new CustomerModel()

  htmlSalesHistory = ""

  dateFrom = new Date()
  dateTo = new Date()
  textFrom = CommonsService.formatDate0(this.dateFrom)
  textTo = CommonsService.formatDate0(this.dateTo)

  constructor(
    protected router: Router,
    private route: ActivatedRoute,
    protected translate: TranslateService,
    private repository: RepositoryService,
    private networkService: NetworkService,
  ) { }

  ngOnInit(): void {
    this.init()
  }

  async init() {
    const routeParams = this.route.snapshot.paramMap;
    this.uuidCustomer = String(routeParams.get('uuidCustomer'));
    this.customer = <CustomerModel> await this.repository.customers.read(this.uuidCustomer)

    this.dateFrom = new Date()
    this.dateFrom.setMonth(this.dateFrom.getMonth() - 1)

    this.dateTo = new Date()

    this.textFrom = CommonsService.formatDate0(this.dateFrom)
    this.textTo = CommonsService.formatDate0(this.dateTo)

    let this_ = this
    $('#div-date-begin').datetimepicker({
      format: 'DD.MM.YYYY'
    });
    $('#div-date-end').datetimepicker({
      format: 'DD.MM.YYYY'
    });
    $("#div-date-begin").on("dp.change", function (e: any) {
      this_.changedDateTimeBegin();
    });
    $("#div-date-end").on("dp.change", function (e: any) {
      this_.changedDateTimeEnd();
    });

    this.loadCustomerSalesHistory()
  }

  changedDateTimeBegin() {
    this.dateFrom = CommonsService.parseDate($("#input-date-begin").val())
    this.dateTo = CommonsService.parseDate($("#input-date-end").val())

    if (this.dateTo.getTime() < this.dateFrom.getTime()) {
      this.dateTo = new Date(this.dateFrom.getTime())
      $("#input-date-end").val($("#input-date-begin").val())
    }

    this.loadCustomerSalesHistory()
  }

  changedDateTimeEnd() {
    this.dateFrom = CommonsService.parseDate($("#input-date-begin").val())
    this.dateTo = CommonsService.parseDate($("#input-date-end").val())

    if (this.dateTo.getTime() < this.dateFrom.getTime()) {
      this.dateFrom = new Date(this.dateTo.getTime())
      $("#input-date-begin").val($("#input-date-end").val())
    }

    this.loadCustomerSalesHistory()
  }

  async loadCustomerSalesHistory() {
    let dateJsFrom = this.dateFrom
    let dateJsTo = this.dateTo

    this.htmlSalesHistory = ""

    dateJsFrom.setHours(0);
    dateJsFrom.setMinutes(0);
    dateJsFrom.setSeconds(0);

    dateJsTo.setHours(23);
    dateJsTo.setMinutes(59);
    dateJsTo.setSeconds(59);

    let dateFromStr = CommonsService.formatDateDb(dateJsFrom)
    let dateToStr = CommonsService.formatDateDb(dateJsTo)

    log(dateFromStr, dateToStr)
    let this_ = this

    let rows = await this.networkService.readCustomerSalesHistory(this.uuidCustomer, dateFromStr, dateToStr)

    if (rows && rows.length > 0) {
      log("something received");
      log(rows);
      this_.translate.get('CUSTOMER_SALES_HISTORY_TABLE_DATE').subscribe((translated: string) => {

        let textMsg0 = translated;
        let textMsg1 = this_.translate.instant("CUSTOMER_SALES_HISTORY_TABLE_ARTICLE_NUMBER")
        let textMsg2 = this_.translate.instant("CUSTOMER_SALES_HISTORY_TABLE_ARTICLE_NAME")
        let textMsg3 = this_.translate.instant("CUSTOMER_SALES_HISTORY_TABLE_AMOUNT")
        let textMsg4 = this_.translate.instant("CUSTOMER_SALES_HISTORY_TABLE_ITEM_NET")
        let textMsg5 = this_.translate.instant("CUSTOMER_SALES_HISTORY_TABLE_ITEM_GROS")
        let textMsg6 = this_.translate.instant("CUSTOMER_SALES_HISTORY_TABLE_SUM_NET")
        let textMsg7 = this_.translate.instant("CUSTOMER_SALES_HISTORY_TABLE_SUM_GROS")

        var html = "<table class='table table-striped'>";
        html += "<thead>";
        html += "<tr>";
        html += "<th class='table-header-col-no-sort'>" + textMsg0 + "</th>";
        html += "<th class='table-header-col-no-sort'>" + textMsg1 + "</th>";
        html += "<th class='table-header-col-no-sort'>" + textMsg2 + "</th>";
        html += "<th class='table-header-col-no-sort'>" + textMsg3 + "</th>";
        html += "<th class='table-header-col-no-sort'>" + textMsg4 + "</th>";
        html += "<th class='table-header-col-no-sort'>" + textMsg5 + "</th>";
        html += "<th class='table-header-col-no-sort'>" + textMsg6 + "</th>";
        html += "<th class='table-header-col-no-sort'>" + textMsg7 + "</th>";
        html += "</tr>";
        html += "</thead>";

        html += "<tbody>";
        for (let iRow = 0; iRow < rows.length; iRow++) {
          html += "<tr>";
          var datePurchase = CommonsService.formatDate0(new Date(rows[iRow]["Datum"]));
          html += "<td>" + datePurchase + "</td>";
          html += "<td>" + rows[iRow]["Artikelnummer_5"] + "</td>";
          html += "<td>" + rows[iRow]["Bezeichnung_5"] + "</td>";
          html += "<td>" + rows[iRow]["Menge_6"] + "</td>";
          html += "<td>" + rows[iRow]["Einzelpreis_Netto_10"] + "</td>";
          html += "<td>" + rows[iRow]["Einzelpreis_Brutto_88"] + "</td>";
          html += "<td>" + rows[iRow]["Nettosumme_Gesamt_17"] + "</td>";
          html += "<td>" + rows[iRow]["Bruttosumme_Gesamt_16"] + "</td>";
          html += "</tr>";
        }
        html += "</tbody>";
        html += "</table>"

        this_.htmlSalesHistory = html;
      });
    } else {
      log("htmlResponse: nothing received");

      this_.translate.get('CUSTOMER_SALES_HISTORY_NOTHING_FOUND').subscribe((translated: string) => {
        let html = "<div class='container-fluid'>"
        html += "<div class='row'>"
        html += "<div class='col-lg-12 col-md-12 col-sm-12 col-xs-13'>"
        html += translated;
        html += "</div>";
        html += "</div>";
        html += "</div>";

        this_.htmlSalesHistory = html
      })
    }
  }
}
