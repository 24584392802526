import { Component, OnInit } from '@angular/core';
import { EditPartnerComponent } from '../edit-partner/edit-partner.component';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Location } from '@angular/common'
import { UUID } from 'angular2-uuid';
import { CustomerModel } from '../models/customer-model';
import { RepositoryService } from '../repository.service';
import { PartnerModel } from '../models/partner-model';


@Component({
  selector: 'app-add-partner',
  templateUrl: '../edit-partner/edit-partner.component.html',
  styleUrls: ['./add-partner.component.css']
})
export class AddPartnerComponent extends EditPartnerComponent implements OnInit {

  uuidCustomer = ""
  customer: CustomerModel = new CustomerModel()

  constructor(
    location: Location,
    route: ActivatedRoute,
    router: Router,
    translate: TranslateService,
    repository: RepositoryService
  ) {
    super(location, route, router, translate, repository)
  }

  override ngOnInit(): void {
    super.ngOnInit()
  }

  override async initSpecific() {
    this.translate.get("PARTNER_ADD_TITLE").subscribe((translated) => this.textTitle = translated)

    const routeParams = this.route.snapshot.paramMap;
    this.uuidCustomer = String(routeParams.get('uuidCustomer'));

    try {
      this.customer = <CustomerModel>await this.repository.customers.read(this.uuidCustomer)
      if (!this.customer)
        this.router.navigateByUrl("/")
    } catch (err) {
      this.router.navigateByUrl("/")
    }

    this.partner = new PartnerModel()
    this.partner.uuid = UUID.UUID()
    this.partner.uuidCustomer = this.customer.uuid
    this.partner.uuidSex = this.sexes[0].uuid
  }

  override async saveSpecific() {
    await this.repository.partners.create(this.partner)
    this.location.back()
  }
}
