<div role="main">
  <div class="">
    <div class="page-title">
      <div class="title_left">
        <h3>
          {{ "APPOINTMENTS_TITLE" | translate }}

          <div [hidden]="!showLinkCustomer" class="display-inline">
            -
            <span
              class="cursor-pointer"
              routerLink="/editCustomer/{{ customer.uuid }}"
              title="{{ 'APPOINTMENTS_TITLE_TOOLTIP_CUSTOMER' | translate }}"
              >{{ textNameCustomer }}</span
            >
            <span
              class="span-icon-header"
              routerLink="/editCustomer/{{ customer.uuid }}"
              data-toggle="tooltip"
              data-placement="top"
              title="{{ 'APPOINTMENTS_TITLE_TOOLTIP_CUSTOMER' | translate }}"
            >
              <i class="mdi mdi-account icon-header"></i
            ></span>
          </div>
        </h3>
      </div>
    </div>
  </div>

  <div class="clearfix"></div>

  <div class="x_panel">
    <div class="x_title display-flex">
      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-3">
        <button
          class="btn btn-default"
          type="button"
          (click)="addAppointment()"
          data-toggle="tooltip"
          data-placement="top"
          title="{{ 'APPOINTMENTS_TOOLTIP_ADD' | translate }}"
        >
          {{ "APPOINTMENTS_BUTTON_NEW" | translate }}
          <i class="mdi mdi-plus-circle indicator-color-green"></i>
        </button>
      </div>
    </div>

    <div class="x_content">
      <table class="table table-striped">
        <thead>
          <tr>
            <th class="table-header-col-no-sort">
              {{ "APPOINTMENTS_TABLE_BEGIN" | translate }}
            </th>
            <th class="table-header-col-no-sort">
              {{ "APPOINTMENTS_TABLE_END" | translate }}
            </th>
            <th class="table-header-col-no-sort">
              {{ "APPOINTMENTS_TABLE_CUSTOMER" | translate }}
            </th>
            <th class="table-header-col-no-sort">
              {{ "APPOINTMENTS_TABLE_SUBJECT" | translate }}
            </th>
            <th class="table-header-col-no-sort">
              {{ "APPOINTMENTS_TABLE_LOCATION" | translate }}
            </th>
            <th class="tableColWidthIcon">
              <div
                class="table-header-col"
                (click)="addAppointment()"
                data-toggle="tooltip"
                data-placement="top"
                title="{{ 'APPOINTMENTS_TABLE_TOOLTIP_ADD' | translate }}"
              >
                <i class="mdi mdi-plus-circle indicator-color-green"></i>
              </div>
            </th>
            <!--th class="tableColWidthIcon"></th>
                    <th class="tableColWidthIcon"></th-->
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="
              let appointment of appointments
                | limitFromTo
                  : currentPage * pageSize
                  : (currentPage + 1) * pageSize;
              let i = index
            "
          >
            <td routerLink="/editAppointment/{{ appointment.uuid }}">
              {{ appointment.textBegin }}
            </td>
            <td routerLink="/editAppointment/{{ appointment.uuid }}">
              {{ appointment.textEnd }}
            </td>
            <!-- td>{{ entry.begin | date:'dd.MM.yyyy HH:mm'}}</td>
                <td>{{ entry.end | date:'dd.MM.yyyy HH:mm'}}</td-->
            <td
              routerLink="/editAppointment/{{ appointment.uuid }}"
              class="word-break-250px"
            >
              {{ appointment.nameCustomer }}
            </td>
            <td
              routerLink="/editAppointment/{{ appointment.uuid }}"
              class="word-break-250px"
            >
              {{ appointment.subject }}
            </td>
            <td
              routerLink="/editAppointment/{{ appointment.uuid }}"
              class="word-break-250px"
            >
              {{ appointment.location }}
            </td>

            <td ng-show="1">
              <div class="dropdown">
                <button
                  class="btn btn-sm btn-default dropdown-toggle btn-action"
                  type="button"
                  id="dropdownMenuButton"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {{
                    "GENERAL_TABLE_DROPDOWN_ACTIONS" | translate | capitalize
                  }}
                </button>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <li>
                    <a
                      class="dropdown-item"
                      routerLink="/editAppointment/{{ appointment.uuid }}"
                      data-toggle="tooltip"
                      data-placement="top"
                    >
                      <i class="mdi mdi-pencil"></i>&nbsp;{{
                        "APPOINTMENTS_TABLE_TOOLTIP_EDIT"
                          | translate
                          | capitalize
                      }}</a
                    >
                  </li>

                  <li [hidden]="!appointment.enableButtonCustomer">
                    <a
                      class="dropdown-item"
                      routerLink="/editCustomer/{{
                        appointment.uuidCustomer
                      }}"
                      data-toggle="tooltip"
                      data-placement="top"
                    >
                      <i class="mdi mdi-account"></i>&nbsp;{{
                        "APPOINTMENTS_TABLE_TOOLTIP_CUSTOMER"
                          | translate
                          | capitalize
                      }}</a
                    >
                  </li>

                  <li>
                    <a
                      class="dropdown-item"
                      routerLink="/deleteAppointment/{{ appointment.uuid }}"
                      ng-click="$event.stopPropagation();"
                      data-toggle="tooltip"
                      data-placement="top"
                    >
                      <i class="mdi mdi-delete-outline"></i>&nbsp;{{
                        "APPOINTMENTS_TABLE_TOOLTIP_DELETE"
                          | translate
                          | capitalize
                      }}</a
                    >
                  </li>
                </ul>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <button
          class="btn btn-sm btn-default btn-navigation"
          [disabled]="currentPage == 0 || numberOfPages() == 0"
          (click)="currentPage = 0; storeCurrentPage()"
        >
          {{ "NAVIGATION_PAGE_FIRST" | translate }}
        </button>
        <button
          class="btn btn-sm btn-default btn-navigation"
          [disabled]="currentPage == 0 || numberOfPages() == 0"
          (click)="currentPage = currentPage - 1; storeCurrentPage()"
        >
          {{ "NAVIGATION_PAGE_PREVIOUS" | translate }}
        </button>
        <span class="text-navigation">
          {{ currentPage + 1 }}/{{ numberOfPages() }}
        </span>
        <button
          class="btn btn-sm btn-default btn-navigation"
          [disabled]="currentPage >= numberOfPages() - 1"
          (click)="currentPage = currentPage + 1; storeCurrentPage()"
        >
          {{ "NAVIGATION_PAGE_NEXT" | translate }}
        </button>
        <button
          class="btn btn-sm btn-default btn-navigation"
          [disabled]="currentPage >= numberOfPages() - 1"
          (click)="currentPage = numberOfPages() - 1; storeCurrentPage()"
        >
          {{ "NAVIGATION_PAGE_LAST" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
