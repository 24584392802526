import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { CommonsService } from '../commons-service';
import { AppointmentForViewModel } from '../models/appointment-for-view-model';
import { AppointmentModel } from '../models/appointment-model';
import { CustomerModel } from '../models/customer-model';
import { ModelFactory } from '../models/model-factory';
import { ModelInterface } from '../models/model-interface';
import { RepositoryService } from '../repository.service';
declare const log: any;

@Component({
  selector: 'app-view-appointments',
  templateUrl: './view-appointments.component.html',
  styleUrls: ['./view-appointments.component.css']
})
export class ViewAppointmentsComponent implements OnInit {

  uuidCustomer = ""
  showLinkCustomer = false
  customer: CustomerModel = new CustomerModel()
  textNameCustomer = ""
  appointments: AppointmentModel[] = []
  currentPage = 0
  pageSize = environment.entriesPerPage

  constructor(
    private route: ActivatedRoute,
    private location: Location,
    protected router: Router,
    protected repository: RepositoryService,
    private modelFactory: ModelFactory<ModelInterface>
  ) { }

  ngOnInit(): void {
    this.init()
  }

  async init() {
    //this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    const routeParams = this.route.snapshot.paramMap;
    this.uuidCustomer = String(routeParams.get('uuidCustomer'));

    this.currentPage = + await this.repository.readUtilityData("showAppointmentsPage")
    let customerTemp =
      this.uuidCustomer != "all" ?
        await this.repository.customers.read(this.uuidCustomer) :
        null
    if (customerTemp) {
      this.customer = <CustomerModel>customerTemp
      this.showLinkCustomer = true;
      this.textNameCustomer = this.customer.firstName + " " + this.customer.lastName;
    } else {
      this.showLinkCustomer = false;
      this.uuidCustomer = "all"
    }

    let appointmentsR = await this.repository.appointments.readAll()
    appointmentsR.sort(function (a: any, b: any) { return (a.begin > b.begin ? 1 : -1) });
    appointmentsR = appointmentsR.filter((appointment: any) => {
      return this.uuidCustomer == "all" || appointment.uuidCustomer == this.uuidCustomer
    })

    let appointmentsL: any[] = []
    let this_ = this
    let dateNow = new Date()
    let customers = await this.repository.customers.readAll("name", true)

    await CommonsService.doSequentially(appointmentsR, (appointmentO: AppointmentModel) => new Promise((resolve, reject) => {
      // filter appointments now or in the future
      if (appointmentO.end && appointmentO.end.getTime() >= dateNow.getTime()) {
        let appointmentV = new AppointmentForViewModel(appointmentO)
        appointmentsL.push(appointmentV);
        appointmentV.textBegin = CommonsService.formatDateTime0(appointmentV.begin);

        if (appointmentV.begin.getFullYear() == appointmentV.end.getFullYear() &&
          appointmentV.begin.getMonth() == appointmentV.end.getMonth() &&
          appointmentV.begin.getDay() == appointmentV.end.getDay())
          appointmentV.textEnd = CommonsService.formatTime0(appointmentV.end);
        else
          appointmentV.textEnd = CommonsService.formatDateTime0(appointmentV.end);

        // load customer for each appointment
        let customer = CommonsService.getObjectByUuid(customers, appointmentV.uuidCustomer)
        if (customer) {
          appointmentV.nameCustomer = customer.firstName + " " + customer.lastName;
          appointmentV.enableButtonCustomer = true;
        } else {
          appointmentV.nameCustomer = "";
          appointmentV.enableButtonCustomer = false;
        }
        resolve(null)
      } else
        resolve(null)
    }
    ))
    this.appointments = appointmentsL

    if (this.currentPage > this.numberOfPages() - 1) {
      this.currentPage = this.numberOfPages() - 1;
      this.storeCurrentPage();
    }
  }

  addAppointment() {
    this.router.navigateByUrl("/addAppointment/" + (this.customer ? this.customer.uuid : "all"))
  }

  numberOfPages() {
    if (this.appointments && this.appointments.length > 0)
      return Math.ceil(this.appointments.length / this.pageSize);
    else
      return 1;
  }

  storeCurrentPage() {
    this.repository.updateUtilityData("showAppointmentsPage", "" + this.currentPage);
  }

}
