import { Component, OnInit } from '@angular/core';
import { CommonsService } from '../commons-service';
import { WebcamImage, WebcamInitError } from 'ngx-webcam';
import { Observable, Subject } from 'rxjs';
import { ImageModel } from '../models/image-model';
import { HavingImagesModel } from '../models/having-images-model';
import { UUID } from 'angular2-uuid';
import { TranslateService } from '@ngx-translate/core';
declare var $: any;
declare const log: any;

@Component({
  selector: 'app-edit-having-images',
  templateUrl: './edit-having-images.component.html',
  styleUrls: ['./edit-having-images.component.css']
})
export class EditHavingImagesComponent implements OnInit {

  entity: HavingImagesModel = new HavingImagesModel();
  public idTable: number = 0

  pathImageFile = ""

  public showWebcam = false;
  public allowCameraSwitch = true;
  public multipleWebcamsAvailable = false;
  public deviceId: string = "";
  public videoOptions: MediaTrackConstraints = {
    // width: {ideal: 1024},
    // height: {ideal: 576}
  };
  public errors: WebcamInitError[] = [];
  // latest snapshot
  public webcamImage: WebcamImage | null = null;
  // webcam snapshot trigger
  private trigger: Subject<void> = new Subject<void>();
  // switch to next / previous / specific webcam; true/false: forward/backwards, string: deviceId
  private nextWebcam: Subject<boolean | string> = new Subject<boolean | string>();

  imageToRemove: ImageModel = new ImageModel()

  constructor(
  ) {
  }

  public setIdTable(idTable: number) {
    this.idTable = idTable;
  }

  ngOnInit(): void {
  }

  public setEntity(entitiy: HavingImagesModel) {
    this.entity = entitiy
  }

  public cameraWasSwitched(deviceId: string): void {
    log('active device: ' + deviceId);
    this.deviceId = deviceId;
  }

  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  public get nextWebcamObservable(): Observable<boolean | string> {
    return this.nextWebcam.asObservable();
  }

  public handleImage(webcamImage: WebcamImage): void {
    console.info('received webcam image', webcamImage);

    this.webcamImage = webcamImage;

    let newImage = new ImageModel()
    newImage.data = webcamImage.imageAsDataUrl
    newImage.name = "Bild" + new Date().getTime() + ".jpg"
    newImage.uuidParent = this.idTable + ":" + this.entity.uuid
    newImage.isLink = false
    newImage.type = 5
    newImage.uuid = UUID.UUID()
    this.entity.images.push(newImage)

    if (this.entity.uuidsImages != "")
      this.entity.uuidsImages += ","
    this.entity.uuidsImages += newImage.uuid

    this.saveAfterImageAdded()
  }

  public handleInitError(error: WebcamInitError): void {
    this.errors.push(error);
  }

  public showNextWebcam(directionOrDeviceId: boolean | string): void {
    // true => move forward through devices
    // false => move backwards through devices
    // string => move to device with given deviceId
    this.nextWebcam.next(directionOrDeviceId);
  }

  public triggerSnapshot(): void {
    this.trigger.next();
  }

  public toggleWebcam(): void {
    this.showWebcam = !this.showWebcam;
  }

  public selectImageFile() {
    $("#input-select-image-file").click()
  }

  public selectedImageFile() {
  }

  selectedImageListener($event: any): void {
    this.readImageFile($event.target);
  }

  readImageFile(inputValue: any): void {
    var file: File = inputValue.files[0];
    var myReader: FileReader = new FileReader();

    if (typeof file == undefined || file == null)
      return;

    let this_ = this

    myReader.onloadend = function (e) {
      // you can perform an action with readed data here
      let data = <string>myReader.result
      let tempSplits = data.split("base64")
      if (tempSplits.length < 2)
        return;
      let type = -1
      if (tempSplits[0].toLocaleLowerCase().indexOf("jpg") >= 0 || tempSplits[0].indexOf("jpeg") >= 0)
        type = CommonsService.IMAGE_TYPE_JPG
      else if (tempSplits[0].toLocaleLowerCase().indexOf("png") >= 0)
        type = CommonsService.IMAGE_TYPE_PNG
      else {
        this_.showErrorMessage("ERROR_UNKNOWN_TYPE")
        return;
      }

      let newImage = new ImageModel()
      newImage.data = <string>myReader.result
      newImage.name = file.name
      newImage.uuidParent = this_.idTable + ":" + this_.entity.uuid
      newImage.isLink = false

      newImage.type = type
      newImage.uuid = UUID.UUID()
      this_.entity.images.push(newImage)

      if (this_.entity.uuidsImages != "")
        this_.entity.uuidsImages += ","
      this_.entity.uuidsImages += newImage.uuid

      this_.saveAfterImageAdded()
    }

    myReader.readAsDataURL(file);
  }

  public showErrorMessage(key: string) {
  }

  public async removeImageStart(uuid: string) {
    let img = this.entity.getImage(uuid)
    if (img == null)
      return Promise.resolve()

    this.imageToRemove = img

    $('#confirmRemoveImage').modal('show')
  }

  public async removeImage() {
    if (this.imageToRemove == null)
      return Promise.resolve()

    $('#confirmRemoveImage').modal('hide')

    this.entity.removeImage(this.imageToRemove.uuid)

    await this.saveAfterImageRemoved()
  }

  public async saveAfterImageAdded(): Promise<void> {
  }

  public async saveAfterImageRemoved(): Promise<void> {
  }
}
