import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CommonsService } from '../commons-service';
import { RepositoryService } from '../repository.service';
import { TaskCategoryModel } from '../models/task-category-model';
import { TaskModel } from '../models/task-model';
import { TaskForViewModel } from '../models/task-for-view-model';
declare const log: any

@Component({
  selector: 'app-view-tasks',
  templateUrl: './view-tasks.component.html',
  styleUrls: ['./view-tasks.component.css']
})
export class ViewTasksComponent implements OnInit {

  tasks: TaskForViewModel[] = []
  sort = {
    column: "DatumFaellig_7",
    ascending: true
  }
  showOpenTasksOnly = false
  showTaskCategoryUuid = ""
  taskCategories: TaskCategoryModel[] = []
  timeLastSorting = new Date();

  constructor(
    private router: Router,
    private translate: TranslateService,
    private repository: RepositoryService
  ) { }

  ngOnInit(): void {
    this.init()
  }

  async init() {
    this.sort.column = await this.repository.readUtilityData("sortTasksColumn")
    this.sort.ascending = "true" == await this.repository.readUtilityData("sortTasksAscending")
    //this.currentPage = +await this.repository.readUtilityData("showTasksPage")
    this.showOpenTasksOnly = "true" == await this.repository.readUtilityData("showOpenTasksOnly")
    this.showTaskCategoryUuid = await this.repository.readUtilityData("showTaskCategoryUuid")
    this.taskCategories = <TaskCategoryModel[]>await this.repository.taskCategories.readAll()

    var taskCategoryAll: TaskCategoryModel = new TaskCategoryModel();
    taskCategoryAll.uuid = "";
    this.translate.get("TASKS_NAME_CATEGORY_ALL")
      .subscribe((translated: string) => taskCategoryAll.name = translated)
    this.taskCategories.unshift(taskCategoryAll);

    await this.loadTasks()
  }

  async loadTasks() {
    let tasksL: TaskForViewModel[] = []
    let tasksR = await this.repository.tasks.readAll(this.sort.column, this.sort.ascending)
    let this_ = this
    tasksR.forEach(function (taskO: TaskModel) {
      let taskV = new TaskForViewModel(taskO)
      taskV.textDue = CommonsService.formatDate0(taskV.due);
      if (!taskV.due || taskV.due <= new Date(0))
        taskV.textDue = "";
      else if (taskV.due.getHours() != 0 || taskV.due.getMinutes() != 0)
        taskV.textDue = CommonsService.formatDateTime0(taskV.due);

      var taskCategory: any = CommonsService.getObjectByUuid(this_.taskCategories, taskV.uuidTaskCategory);
      if (taskCategory && taskCategory.uuid != "")
        taskV.textCategory = taskCategory.name;
      else
        taskV.textCategory = "";

      taskV.classTextDecoration = taskV.done ? "textDecorationLineThrough" : "textDecorationNone";

      if ((!this_.showOpenTasksOnly || !taskV.done) &&
        (!this_.showTaskCategoryUuid || this_.showTaskCategoryUuid == "" || taskV.uuidTaskCategory == this_.showTaskCategoryUuid)) {
        tasksL.push(taskV);
      }
    });

    if (this_.sort.column == "uuidTaskCategory")
      tasksL.sort((a: any, b: any) => {
        if (this.sort.ascending) {
          if (a.textCategory > b.textCategory)
            return 1;
          if (a.textCategory < b.textCategory)
            return -1;
          return 0
        } else {
          if (a.textCategory < b.textCategory)
            return 1;
          if (a.textCategory > b.textCategory)
            return -1;
          return 0
        }
      })

    log("the tasks")
    log(tasksL)

    this.tasks = tasksL
  }

  async showTasksCategoryChanged() {
    var timeNow = new Date();
    if (timeNow.getTime() - this.timeLastSorting.getTime() < CommonsService.TIME_TRIGGER_SORTING_MS)
      return;
    this.timeLastSorting = new Date();

    await this.repository.updateUtilityData("showTaskCategoryUuid", this.showTaskCategoryUuid);

    await this.loadTasks()
  }

  async showOpenTasksOnlyChanged() {
    await this.repository.updateUtilityData("showOpenTasksOnly", this.showOpenTasksOnly ? "true" : "false");
    await this.loadTasks()
  }

  async doneChanged(task: TaskForViewModel) {
    var taskBeforeEdit: TaskModel = new TaskModel(task);
    taskBeforeEdit.done = !task.done // assure update
    if (task.done) {
      task.dateDone = new Date();
    } else {
    }

    task.classTextDecoration = task.done ? "textDecorationLineThrough" : "textDecorationNone"

    await this.repository.tasks.update(taskBeforeEdit, task)
  }

  async changeSorting(column: string) {
    var timeNow = new Date();
    if (timeNow.getTime() - this.timeLastSorting.getTime() < CommonsService.TIME_TRIGGER_SORTING_MS)
      return;
    this.timeLastSorting = new Date();

    if (this.sort.column == column) {
      this.sort.ascending = !this.sort.ascending;
    } else {
      this.sort.column = column;
      this.sort.ascending = false;
    }

    await this.repository.updateUtilityData("sortTasksColumn", this.sort.column);
    await this.repository.updateUtilityData("sortTasksAscending", this.sort.ascending ? "true" : "false");
    await this.loadTasks()
  };
}
