import { Component, OnInit } from '@angular/core';
import { CommonsService } from '../commons-service';
import { CustomerModel } from '../models/customer-model';
import { RepositoryService } from '../repository.service';
declare const log: any

@Component({
  selector: 'app-view-customers-sugg-next',
  templateUrl: './view-customers-sugg-next.component.html',
  styleUrls: ['./view-customers-sugg-next.component.css']
})
export class ViewCustomersSuggNextComponent implements OnInit {

  customers: CustomerModel[] = []
  currentPage = 0

  constructor(
    private repository: RepositoryService
  ) { }

  ngOnInit(): void {
    this.init()
  }

  async init() {
    let customersR: any[] = await this.repository.customers.readAll("nextContact", true)

    let dateMin = new Date(0);

    let dateCompare = new Date();
    dateCompare.setHours(23);
    dateCompare.setMinutes(59);
    dateCompare.setSeconds(59);
    dateCompare.setMilliseconds(999);

    customersR = customersR.filter((customer: any) => {
      if (customer.nextContact) {
        if (customer.nextContact.getTime() > dateMin.getTime() &&
          customer.nextContact.getTime() <= dateCompare.getTime()) {
          customer.textNextContact = CommonsService.formatDate0(customer.nextContact);
          return true
        } else
          return false
      } else
        return false
    })

    log(customersR)

    this.customers = customersR
  }

}
