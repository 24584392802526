import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CommonsService } from 'src/app/commons-service';
import { CustomerModel } from 'src/app/models/customer-model';
import { DeviceModel } from 'src/app/models/device-model';
import { MaintenanceForViewModel } from 'src/app/models/maintenance-for-view-model';
import { MaintenanceModel } from 'src/app/models/maintenance-model';
import { RepositoryService } from 'src/app/repository.service';
declare const log: any;

@Component({
  selector: 'app-view-maintenances',
  templateUrl: './view-maintenances.component.html',
  styleUrls: ['./view-maintenances.component.css']
})
export class ViewMaintenancesComponent implements OnInit {

  type: string = ""
  textTitle = ""
  showOwnOnly = false
  maintenances: MaintenanceForViewModel[] = []

  textSearch = ""

  constructor(
    protected route: ActivatedRoute,
    protected router: Router,
    protected translate: TranslateService,
    protected repository: RepositoryService,
  ) { }

  ngOnInit(): void {
    this.init()
  }

  async init() {
    const routeParams = this.route.snapshot.paramMap;
    this.type = String(routeParams.get('type'));

    this.textSearch = await this.repository.readUtilityData("searchMaintenancesText")

    this.showOwnOnly = "true" == await this.repository.readUtilityData("showOwnMaintenancesOnly")

    this.search()
  }

  async search() {
    await this.repository.updateUtilityData("searchMaintenancesText", this.textSearch)

    let maintenancesR: MaintenanceModel[] = await this.repository.maintenances.readAll("begin", true)

    if (this.type == "done") {
      this.translate.get('MAINTENANCES_TITLE_DONE').subscribe((translated: string) => {
        this.textTitle = translated;
      })
      let threeDaysBefore = new Date()
      threeDaysBefore.setDate(threeDaysBefore.getDate() - 3);
      maintenancesR = maintenancesR.filter((maintenance) => (!this.showOwnOnly || maintenance.isOwn) && maintenance.done && maintenance.end.getTime() >= threeDaysBefore.getTime())
    } else {
      this.translate.get('MAINTENANCES_TITLE_DUE').subscribe((translated: string) => {
        this.textTitle = translated;
      })
      maintenancesR = maintenancesR.filter((maintenance) => (!this.showOwnOnly || maintenance.isOwn) && !maintenance.done)
    }

    let maintenancesV = []
    let customers = await this.repository.customers.readAll()
    let devices = await this.repository.devices.readAll()
    let articles = await this.repository.articles.readAll()

    for (let maintT of maintenancesR) {
      let maintV = new MaintenanceForViewModel(maintT)
      let customer: CustomerModel = CommonsService.getObjectByUuid(customers, maintV.uuidCustomer)
      maintV.setCustomer(customer)
      let device: DeviceModel = CommonsService.getObjectByUuid(devices, maintV.uuidDevice)
      maintV.setDevice(device)
      maintV.textBegin = CommonsService.formatDateTime0Omit00(maintV.begin)
      let article = CommonsService.getObjectByUuid(articles, maintV.uuidArticle)
      maintV.setArticle(article)
      maintenancesV.push(maintV)
    }

    var textSearchLC = "";
    if (this.textSearch && typeof this.textSearch != 'undefined' && this.textSearch != "")
      textSearchLC = this.textSearch.toLowerCase();

    maintenancesV = maintenancesV.filter((maintenance: MaintenanceForViewModel) => {
      if (textSearchLC != "") {
        if ((maintenance.deviceName.toLowerCase().indexOf(textSearchLC) >= 0) ||
          (maintenance.number.toLowerCase().indexOf(textSearchLC) >= 0) ||
          (maintenance.customerName.toLowerCase().indexOf(textSearchLC) >= 0) ||
          (maintenance.customerCity.toLowerCase().indexOf(textSearchLC) >= 0) ||
          (maintenance.customerZipCode.toLowerCase().indexOf(textSearchLC) >= 0) ||
          (maintenance.customerStreet.toLowerCase().indexOf(textSearchLC) >= 0))
          return true
        else
          return false
      } else
        return true
    })

    log("the maintenances")
    log(maintenancesV)

    this.maintenances = maintenancesV
  }

  async reload() {
    await this.search()
  }

  async showOwnOnlyChanged() {
    await this.repository.updateUtilityData("showOwnMaintenancesOnly", this.showOwnOnly ? "true" : "false");
    await this.search()
  }

  private keyTimeout: any = null;

  keyUpEvent(event: any) {
    if (event.key.length != 1 && event.key != "Backspace") {
      event.preventDefault()
      return false;
    }

    if (this.keyTimeout) {
      clearTimeout(this.keyTimeout);
      this.keyTimeout = null;
    }

    this.keyTimeout = setTimeout(() => {
      this.search();
    }, 300)

    return true
  }
}
