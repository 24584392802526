import { BaseModel } from "./base-model";

export class SexModel extends BaseModel{
  formOfAddress: string = ""

  constructor(params: any = null) {
    super(params)
    this.copyFromObject(params)
  }

  static override newModel(object: any = null) {
    return new SexModel(object)
  }
}
