<div role="main">
  <div class="">
    <div class="page-title">
      <div class="title_left">
        <h3>
          {{ "CUSTOMER_SALES_HISTORY_TITLE" | translate }}

          -
          <span
            class="cursor-pointer"
            routerLink="/editCustomer/{{ uuidCustomer }}"
            title="{{ 'SALES_TITLE_TOOLTIP_CUSTOMER' | translate }}"
            >{{ customer.firstName }} {{ customer.lastName }}</span
          >
          <span
            class="span-icon-header"
            routerLink="/editCustomer/{{ uuidCustomer }}"
            data-toggle="tooltip"
            data-placement="top"
            title="{{ 'SALES_TITLE_TOOLTIP_CUSTOMER' | translate }}"
          >
            <i class="mdi mdi-account icon-header"></i
          ></span>
        </h3>
      </div>
    </div>
  </div>

  <div class="clearfix"></div>

  <div class="x_panel">
    <div class="col-lg-6 col-md-8 col-sm-8 col-xs-12">
      <div class="form-horizontal form-label-left">
        <div class="form-group">
          <label
            class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
            for="input-date-begin"
          >
            {{ "CUSTOMER_SALES_HISTORY_FROM" | translate }}
          </label>
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
            <div class="input-group date" id="div-date-begin">
              <input
                onkeydown="return false"
                id="input-date-begin"
                type="text"
                class="form-control"
                placeholder="dd.mm.yyyy HH:MM"
                value="{{ textFrom }}"
              />
              <span class="input-group-addon">
                <span class="glyphicon glyphicon-calendar"></span>
                <!--i class="mdi mdi-calendar icon-datepicker"></i-->
              </span>
            </div>
          </div>
        </div>

        <div class="form-group">
          <label
            class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
            for="input-date-end"
          >
            {{ "CUSTOMER_SALES_HISTORY_TO" | translate }}
          </label>
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
            <div class="input-group date" id="div-date-end">
              <input
                onkeydown="return false"
                id="input-date-end"
                type="text"
                class="form-control"
                placeholder="dd.mm.yyyy HH:MM"
                value="{{ textTo }}"
              />
              <span class="input-group-addon">
                <span class="glyphicon glyphicon-calendar"></span>
                <!--i class="mdi mdi-calendar icon-datepicker"></i-->
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div class="post-content" [innerHTML]="htmlSalesHistory"></div>
    </div>
  </div>
</div>
