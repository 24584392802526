<div role="main">
  <div class="">
    <div class="page-title">
      <div class="title_left">
        <h3>Login</h3>
      </div>
    </div>
  </div>

  <div class="clearfix"></div>

  <div class="x_panel">
    <div class="col-lg-6 col-md-8 col-sm-8 col-xs-12">
      <div class="form-horizontal form-label-left">
        <div class="form-group">
          <label
            class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
            for="input-user-name"
            >{{ "LOGIN_USER_NAME" | translate }}
          </label>
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
            <input
              id="input-user-name"
              class="form-control"
              type="text"
              [(ngModel)]="username"
              name="user-name"
              #idInputUsername
            />
          </div>
        </div>
      </div>
      <div class="form-horizontal form-label-left">
        <div class="form-group">
          <label
            class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
            for="input-password"
            >{{ "LOGIN_PASSWORD" | translate }}
          </label>
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
            <input
              id="input-password"
              class="form-control"
              type="password"
              [(ngModel)]="password"
              name="password"
              (keyup.enter)="doLogin()"
              #idInputPassword
            />
          </div>
        </div>
      </div>
    </div>

    <div class="clearfix"></div>

    <div class="">
      <div class="col-lg-6 col-md-8 col-sm-8 col-xs-12">
        <div class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"></div>
        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
          <button (click)="doLogin()" class="btn btn-default button-row">
            <span>Login </span><i class="mdi mdi-login color-main"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
