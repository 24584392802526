import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonsService } from '../commons-service';
import { ContactTypeModel } from '../models/contact-type-model';
import { CustomerContactModel } from '../models/customer-contact-model';
import { RepositoryService } from '../repository.service';
declare const log: any

@Component({
  selector: 'app-delete-customer-contact',
  templateUrl: './delete-customer-contact.component.html',
  styleUrls: ['./delete-customer-contact.component.css']
})
export class DeleteCustomerContactComponent implements OnInit {

  uuidCustomerContact = ""
  customerContact: CustomerContactModel = new CustomerContactModel()
  contactTypes: ContactTypeModel[] = []
  textDate = ""

  constructor(
    private location: Location,
    private route: ActivatedRoute,
    private router: Router,
    private repository: RepositoryService
  ) { }

  ngOnInit(): void {
    this.init()
  }

  async init() {
    const routeParams = this.route.snapshot.paramMap;
    this.uuidCustomerContact = String(routeParams.get('uuidCustomerContact'));
    this.customerContact = await this.repository.readCustomerContact(this.uuidCustomerContact)

    this.contactTypes = <ContactTypeModel[]>await this.repository.contactTypes.readAll()
    this.textDate = CommonsService.formatDate0(new Date(this.customerContact.date));
  }

  async delete() {
    await this.repository.deleteCustomerContact(this.uuidCustomerContact)
    this.location.back()
  }

}
