import { BaseModel } from "./base-model"

export class PartnerModel extends BaseModel {

  uuidCustomer: string = ""
  uuidSex: string = ""
  academicTitle: string = ""
  lastName: string = ""
  firstName: string = ""
  position: string = ""
  telephone1: string = ""
  telephone2: string = ""
  fax: string = ""
  email: string = ""
  remarks: string = ""

  constructor(params: any = null) {
    super(params)
    this.copyFromObject(params)
  }

  static override newModel(object: any = null) {
    return new PartnerModel(object)
  }
}
