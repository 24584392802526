import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { CommonsService } from '../commons-service';
import { ContactTypeModel } from '../models/contact-type-model';
import { CustomerContactModel } from '../models/customer-contact-model';
import { CustomerModel } from '../models/customer-model';
import { RepositoryService } from '../repository.service';
declare const log: any

@Component({
  selector: 'app-view-customer-contacts',
  templateUrl: './view-customer-contacts.component.html',
  styleUrls: ['./view-customer-contacts.component.css']
})
export class ViewCustomerContactsComponent implements OnInit {

  uuidCustomer = ""
  customer: CustomerModel = new CustomerModel()
  customerContacts: CustomerContactModel[] = []
  contactTypes: ContactTypeModel[] = []
  textNameCustomer = ""
  currentPage = 0
  pageSize = environment.entriesPerPage

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private repository: RepositoryService
  ) { }

  ngOnInit(): void {
    this.init()
  }

  async init() {
    const routeParams = this.route.snapshot.paramMap;
    this.uuidCustomer = String(routeParams.get('uuidCustomer'));
    if (!this.uuidCustomer)
      this.uuidCustomer = ""

    this.contactTypes = <ContactTypeModel[]>await this.repository.contactTypes.readAll()

    try {
      this.customerContacts = await this.repository.readCustomerContacts(this.uuidCustomer)
      this.customerContacts.forEach(customerContact => {
        let contactType: ContactTypeModel = CommonsService.getObjectByUuid(this.contactTypes, customerContact.uuidContactType)
        if (contactType)
          customerContact.nameContactType = contactType.name
        customerContact.textDate = CommonsService.formatDate0(customerContact.date)
      });
      this.customer = <CustomerModel>await this.repository.customers.read(this.uuidCustomer)
      this.textNameCustomer = this.customer.firstName + " " + this.customer.lastName;
    } catch (err) {
      log(err)
      //this.router.navigateByUrl("/")
    }
  }

  numberOfPages() {
    if (this.customerContacts && this.customerContacts.length > 0)
      return Math.ceil(this.customerContacts.length / this.pageSize);
    else
      return 1;
  }

  storeCurrentPage() {

  }

}
