import { BaseModel } from "./base-model"

export class StockDataArticleModel extends BaseModel {
  active: boolean = true
  uuidArticle: string = ""
  uuidWarehouse: string = ""
  amount: number = 0

  constructor(params: any = null) {
    super(params)
    this.copyFromObject(params)
  }

  static override newModel(object: any = null) {
    return new StockDataArticleModel(object)
  }
}
