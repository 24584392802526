<div id="div_body" class="nav-md">
  <div class="container body">
    <div class="main_container">
      <div class="col-md-3 left_col" [hidden]="!parametersStart.showNavbar">
        <div class="left_col scroll-view">
          <div class="navbar nav_title">
            <a routerLink="/start" class="site_title"
              ><img width="30px" src="./../../assets/Icons/favicon.ico" /><span>
                futureapp</span
              ></a
            >
          </div>

          <div class="clearfix"></div>

          <div class="profile clearfix">
            <div class="profile_info">
              <!--span>Welcome,</span-->
              <h2>{{ parametersStart.nameUser }}</h2>
            </div>
          </div>

          <div class="clearfix"></div>

          <!-- menu profile quick info -->
          <!--div class="profile clearfix">
            <div class="profile_pic">
              <img
                src="images/img.jpg"
                alt="..."
                class="img-circle profile_img"
              />
            </div>
            <div class="profile_info">
              <span>Welcome,</span>
              <h2>John Doe</h2>
            </div>
          </div-->
          <!-- /menu profile quick info -->

          <!-- sidebar menu -->
          <div id="sidebar-menu" class="main_menu_side hidden-print main_menu">
            <div class="menu_section">
              <ul class="nav side-menu">
                <li>
                  <a routerLink="/start"
                    ><i class="mdi mdi-home color-bright"></i>
                    <span> {{ "NAV_START" | translate }}</span>
                  </a>
                </li>
                <li>
                  <a routerLink="/viewCustomers"
                    ><i class="mdi mdi-account-group color-bright"></i>
                    <span> {{ "NAV_CUSTOMERS" | translate }}</span>
                  </a>
                </li>
                <li>
                  <a routerLink="/viewArticles"
                    ><i class="mdi mdi-garage-variant color-bright"></i>
                    {{ "NAV_ARTICLES" | translate }}
                  </a>
                </li>
                <li [hidden]="!environment.enableAppointments">
                  <a routerLink="/viewAppointments/all"
                    ><i class="mdi mdi-calendar color-bright"></i>
                    {{ "NAV_APPOINTMENTS" | translate }}
                  </a>
                </li>
                <li [hidden]="!environment.enableTasks">
                  <a routerLink="/viewTasks"
                    ><i
                      class="mdi mdi-checkbox-marked-outline color-bright"
                    ></i>
                    {{ "NAV_TASKS" | translate }}
                  </a>
                </li>
                <li [hidden]="!environment.enableOpenOffers">
                  <a routerLink="/viewOpenSales/Offer"
                    ><i class="mdi mdi-clipboard-text-outline color-bright"></i>
                    {{ "NAV_OPEN_OFFERS" | translate }}
                  </a>
                </li>
                <li [hidden]="!environment.enableOpenOrders">
                  <a routerLink="/viewOpenSales/Order"
                    ><i
                      class="mdi mdi-clipboard-text-play-outline color-bright"
                    ></i>
                    {{ "NAV_OPEN_ORDERS" | translate }}
                  </a>
                </li>
                <li [hidden]="!environment.enableEnquiries">
                  <a routerLink="/viewEnquiries"
                    ><i class="mdi mdi-account-question color-bright"></i>
                    {{ "NAV_ENQUIRIES" | translate }}
                  </a>
                </li>
                <li [hidden]="!environment.enableCustomersSuggNext">
                  <a routerLink="/viewCustomersSuggNext"
                    ><i class="mdi mdi-forum-outline color-bright"></i>
                    {{ "NAV_CUSTOMERS_SUGG_NEXT" | translate }}
                  </a>
                </li>
                <li [hidden]="!environment.enableCustomersSuggFreq">
                  <a routerLink="/viewCustomersSuggFreq"
                    ><i class="mdi mdi-phone color-bright"></i>
                    {{ "NAV_CUSTOMERS_SUGG_FREQ" | translate }}
                  </a>
                </li>
                <li [hidden]="">
                  <a routerLink="/viewDevices"
                    ><i class="mdi mdi-meter-gas-outline color-bright"></i>
                    {{ "NAV_DEVICES" | translate }}
                  </a>
                </li>
                <li [hidden]="">
                  <a routerLink="/viewMaintenances/due"
                    ><i class="mdi mdi-wrench-clock color-bright"></i>
                    {{ "NAV_MAINTENANCE_OPEN" | translate }}
                  </a>
                </li>
                <li [hidden]="">
                  <a routerLink="/viewMaintenances/done"
                    ><i class="mdi mdi-wrench-check color-bright"></i>
                    {{ "NAV_MAINTENANCE_DONE" | translate }}
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <!-- /sidebar menu -->

          <!-- /menu footer buttons -->
          <!--div class="sidebar-footer hidden-small">
            <a data-toggle="tooltip" data-placement="top" title="Settings">
              <span class="glyphicon glyphicon-cog" aria-hidden="true"></span>
            </a>
            <a data-toggle="tooltip" data-placement="top" title="FullScreen">
              <span
                class="glyphicon glyphicon-fullscreen"
                aria-hidden="true"
              ></span>
            </a>
            <a data-toggle="tooltip" data-placement="top" title="Lock">
              <span
                class="glyphicon glyphicon-eye-close"
                aria-hidden="true"
              ></span>
            </a>
            <a
              data-toggle="tooltip"
              data-placement="top"
              title="Logout"
              href="login.html"
            >
              <span class="glyphicon glyphicon-off" aria-hidden="true"></span>
            </a>
          </div-->
          <!-- /menu footer buttons -->
        </div>
      </div>

      <!-- top navigation -->
      <div class="top_nav" [hidden]="!parametersStart.showNavbar">
        <div class="nav_menu">
          <nav>
            <div class="nav toggle">
              <a id="menu_toggle"><i class="fa fa-bars"></i></a>
            </div>

            <ul class="nav navbar-nav navbar-right">
              <!-- li>
                <button
                  class="btn btn-primary"
                  title="{{ 'NAV_SYNC_TOOLTIP' | translate }}"
                  (click)="doSynchronization()"
                >
                  {{ parametersStart.buttonSyncText }}
                </button>
              </!-->
              <li [hidden]="parametersStart.nameUser == ''">
                <button
                  title="Logout"
                  class="btn btn-default"
                  type="button"
                  (click)="logout()"
                >
                  Logout
                </button>
              </li>

              <li [hidden]="!environment.enableProjectTime">
                <button
                  class="btn btn-button {{
                    parametersStart.buttonStartColor
                  }}"
                  title="{{ 'NAV_TIME_RECORDING_TOOLTIP' | translate }}"
                  (click)="startStopTimerecording()"
                  [disabled]="parametersStart.isSyncRunning"
                >
                  {{ parametersStart.buttonStartText }}
                </button>
              </li>

              <li>
                <div
                  class="online-indicator {{
                    networkService.hasServer && parametersStart.nameUser != ''
                      ? 'indicator-green'
                      : 'indicator-red'
                  }}"
                  title="{{ 'NAV_LOGGED_IN_INDICATOR' | translate }}"
                ></div>
              </li>

              <li>
                <div
                  class="online-indicator {{
                    networkService.hasServer
                      ? 'indicator-green'
                      : 'indicator-red'
                  }}"
                  title="{{ 'NAV_SERVER_INDICATOR' | translate }}"
                ></div>
              </li>

              <li>
                <div
                  class="online-indicator {{
                    networkService.isOnline
                      ? 'indicator-green'
                      : 'indicator-red'
                  }}"
                  title="{{ 'NAV_NETWORK_INDICATOR' | translate }}"
                ></div>
              </li>
            </ul>
          </nav>
        </div>
      </div>
      <!-- /top navigation -->

      <!-- page content -->
      <div class="right_col" role="main">
        <router-outlet
          (activate)="onActivateRouterOutlet($event)"
        ></router-outlet>
      </div>
      <!-- /page content -->

      <!-- footer content -->
      <footer [hidden]="!parametersStart.showNavbar">
        <div class="pull-right">
          designed by
          <a href="https://futurefactory.at">future factory</a>
        </div>
        <div class="clearfix"></div>
      </footer>
      <!-- /footer content -->
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="modalIsProcessing"
  tabindex="-1"
  aria-hidden="true"
  data-keyboard="false"
  data-backdrop_="static"
>
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <!-- div class="modal-header display-flex">
    <h5 class="modal-title" id="confirmModalLabel">
      {{ "NAV_OPERATION_PENDING_TITLE" | translate }}
    </h5>
  <div -->
      <div class="modal-body modal-body-sele">
        {{ "NAV_OPERATION_PENDING_MESSAGE" | translate }}
      </div>
      <!-- div class="modal-footer">
  <div -->
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="modalIsSyncing"
  tabindex="-1"
  aria-hidden="true"
  data-keyboard="false"
  data-backdrop_="static"
>
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <!-- div class="modal-header display-flex">
  <h5 class="modal-title" id="confirmModalLabel">
    {{ "NAV_OPERATION_PENDING_TITLE" | translate }}
  </h5>
<div -->
      <div class="modal-body modal-body-sele">
        {{ "NAV_SYNC_PENDING_MESSAGE" | translate }}
        {{ networkService.countManipulationBatches }}
      </div>
      <!-- div class="modal-footer">
<div -->
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="selectOrderModal"
  tabindex="-1"
  aria-labelledby="conrimModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header display-flex">
        <h5 class="modal-title" id="confirmModalLabel">
          {{ "NAV_SELECT_SALE_TIME_RECORDING_TITLE" | translate }}
        </h5>
        <button
          type="button"
          class="btn-close-modal"
          data-dismiss="modal"
          aria-label="Close"
        >
          <i class="mdi mdi-close color-main"></i>
        </button>
      </div>
      <div class="modal-body modal-body-select">
        <app-view-open-sales-select
          [type]="'Order'"
          (selectedSale)="selectedOrderForTimeRecording($event)"
        >
        </app-view-open-sales-select>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-default" data-dismiss="modal">
          {{ "BUTTON_CANCEL" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
