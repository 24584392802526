import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common'
import { ActivatedRoute } from '@angular/router';
import { CommonsService } from '../commons-service';
import { RepositoryService } from '../repository.service';
import { TaskModel } from '../models/task-model';
declare const log: any;

@Component({
  selector: 'app-delete-task',
  templateUrl: './delete-task.component.html',
  styleUrls: ['./delete-task.component.css']
})
export class DeleteTaskComponent implements OnInit {

  uuidTask = ""
  task: TaskModel = new TaskModel()
  doneBool = false
  textDue = ""

  constructor(
    private location: Location,
    private route: ActivatedRoute,
    private repository: RepositoryService,
  ) { }

  ngOnInit(): void {
    this.init()
  }

  async init() {
    const routeParams = this.route.snapshot.paramMap;
    this.uuidTask = String(routeParams.get('uuidTask'));

    let taskR: TaskModel = <TaskModel>await this.repository.tasks.read(this.uuidTask)
    this.doneBool = taskR.done;
    if (!taskR.due || taskR.due <= new Date(0))
      this.textDue = "";
    else
      this.textDue = CommonsService.formatDateTime0(new Date(taskR.due));

    this.task = taskR
  }

  async delete() {
    await this.repository.tasks.delete(this.uuidTask)

    this.location.back()
  }

}
