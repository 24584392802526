import { CommonsService } from "../commons-service";
import { ModelFactory } from "./model-factory";
import { ModelInterface } from "./model-interface";
import { OfferPositionModel } from "./offer-position-model";
import { SaleModel } from "./sale-model";
declare const log: any;

export class OfferModel extends SaleModel {
  uuidOrder: string = ""
  enquiryDate: Date = new Date(0)
  textEnquiryDate: string = CommonsService.formatDate0(this.enquiryDate)

  constructor(params: any = null) {
    super(params)
    this.copyFromObject(params)
    this.textDate = CommonsService.formatDate0(this.date)
  }

  static override newModel(object: any = null) {
    return new OfferModel(object)
  }

  override clone(modelFactory: ModelFactory<ModelInterface>): OfferModel {
    let clone: OfferModel = new OfferModel(this)
    clone.positions =
      <OfferPositionModel[]>modelFactory.newModels(OfferPositionModel, this.positions)
    return clone
  }
}
