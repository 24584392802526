<div role="main">
  <div class="">
    <div class="page-title">
      <div class="title_left">
        <h3>{{ "DEVICE_EDIT_TITLE" | translate }}</h3>
      </div>
    </div>
  </div>

  <div class="clearfix"></div>

  <div class="x_panel">
    <div class="" role="tabpanel" data-example-id="togglable-tabs">
      <ul id="myTab1" class="nav nav-tabs bar_tabs left" role="tablist">
        <li role="presentation" class="active">
          <a
            href="#tab_content11"
            id="home-tabb"
            role="tab"
            data-toggle="tab"
            aria-controls="home"
            aria-expanded="true"
            >{{ "DEVICE_EDIT_TAB_GENERAL" | translate }}</a
          >
        </li>
        <li role="presentation" class="">
          <a
            href="#tab_content22"
            role="tab"
            id="profile-tabb"
            data-toggle="tab"
            aria-controls="profile"
            aria-expanded="false"
            >{{ "DEVICE_EDIT_TAB_MAINTENANCES" | translate }}</a
          >
        </li>
        <li role="presentation" class="">
          <a
            href="#tab_content33"
            role="tab"
            id="profile-tabb3"
            data-toggle="tab"
            aria-controls="profile"
            aria-expanded="false"
            >{{ "DEVICE_EDIT_TAB_IMAGES" | translate }}</a
          >
        </li>
      </ul>
      <div id="myTabContent2" class="tab-content">
        <div
          role="tabpanel"
          class="tab-pane fade active in"
          id="tab_content11"
          aria-labelledby="home-tab"
        >
          <div class="visible-xs col-xs-12" style="margin-bottom: 40px"></div>
          <div class="col-lg-6 col-md-8 col-sm-8 col-xs-12">
            <div class="form-horizontal form-label-left">
              <div class="form-group">
                <label
                  class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
                  for="input-serialnumber"
                >
                  {{ "DEVICE_EDIT_SERIAL_NUMBER" | translate }}
                </label>
                <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
                  <input
                    id="input-serialnumber"
                    class="form-control"
                    type="text"
                    [(ngModel)]="device.serialNumber"
                    name="device.serialNumber"
                  />
                </div>
              </div>

              <div class="form-group">
                <label
                  class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
                  for="input-name"
                >
                  {{ "DEVICE_EDIT_NAME" | translate }}
                </label>
                <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
                  <input
                    id="input-name"
                    class="form-control"
                    type="text"
                    [(ngModel)]="device.name"
                    name="device.name"
                  />
                </div>
              </div>

              <div class="form-group">
                <label
                  class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
                  for="input-ean"
                >
                  {{ "DEVICE_EDIT_EAN" | translate }}
                </label>
                <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
                  <input
                    id="input-ean"
                    class="form-control"
                    type="text"
                    [(ngModel)]="device.ean"
                    name="device.ean"
                  />
                </div>
              </div>

              <div class="form-group">
                <label
                  class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
                  for="select-sex"
                  >{{ "DEVICE_EDIT_ARTICLE" | translate }}
                </label>
                <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
                  <span
                    class="cursor-pointer move-down-button-row"
                    [hidden]="!showLinkArticle"
                    (click)="gotoArticle()"
                    title="{{
                      'DEVICE_EDIT_ARTICLE_TOOLTIP_ARTICLE' | translate
                    }}"
                  >
                    {{ nameArticle }}
                  </span>
                  <span
                    class="cursor-pointer button-row move-down-button-row"
                    [hidden]="!showLinkArticle"
                    (click)="gotoArticle()"
                    title="{{
                      'DEVICE_EDIT_ARTICLE_TOOLTIP_ARTICLE' | translate
                    }}"
                    ><i class="mdi mdi-garage-variant color-main"></i
                  ></span>

                  <button
                    class="btn btn-default button-row"
                    (click)="selectArticle()"
                    title="{{
                      'DEVICE_EDIT_ARTICLE_TOOLTIP_SEARCH' | translate
                    }}"
                  >
                    <i class="mdi mdi-magnify"></i>
                  </button>
                  <button
                    [hidden]="!showLinkArticle"
                    class="btn btn-default button-row"
                    (click)="clearArticle()"
                    title="{{
                      'DEVICE_EDIT_ARTICLE_TOOLTIP_CLEAR' | translate
                    }}"
                  >
                    <i class="mdi mdi-cancel"></i>
                  </button>
                </div>
              </div>

              <div class="form-group">
                <label
                  class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
                  for="select-sex"
                  >{{ "DEVICE_EDIT_CUSTOMER" | translate }}
                </label>
                <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
                  <span
                    class="cursor-pointer move-down-button-row"
                    [hidden]="!showLinkCustomer"
                    (click)="gotoCustomer()"
                    title="{{
                      'DEVICE_EDIT_CUSTOMER_TOOLTIP_CUSTOMER' | translate
                    }}"
                  >
                    {{ nameCustomer }}
                  </span>
                  <span
                    class="cursor-pointer button-row move-down-button-row"
                    [hidden]="!showLinkCustomer"
                    (click)="gotoCustomer()"
                    title="{{
                      'DEVICE_EDIT_CUSTOMER_TOOLTIP_CUSTOMER' | translate
                    }}"
                    ><i class="mdi mdi-account color-main"></i
                  ></span>

                  <button
                    class="btn btn-default button-row"
                    (click)="selectCustomer()"
                    title="{{
                      'DEVICE_EDIT_CUSTOMER_TOOLTIP_SEARCH' | translate
                    }}"
                  >
                    <i class="mdi mdi-magnify"></i>
                  </button>
                  <button
                    [hidden]="!showLinkCustomer"
                    class="btn btn-default button-row"
                    (click)="clearCustomer()"
                    title="{{
                      'DEVICE_EDIT_CUSTOMER_TOOLTIP_CLEAR' | translate
                    }}"
                  >
                    <i class="mdi mdi-cancel"></i>
                  </button>
                </div>
              </div>

              <div class="form-group">
                <label
                  class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
                  for="input-street"
                >
                  {{ "DEVICE_EDIT_STREET" | translate }}
                </label>
                <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
                  <input
                    id="input-street"
                    class="form-control"
                    type="text"
                    [(ngModel)]="device.street"
                    name="device.street"
                  />
                </div>
              </div>

              <div class="form-group">
                <label
                  class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
                  for="input-zipCode"
                >
                  {{ "DEVICE_EDIT_ZIP_CODE" | translate }}
                </label>
                <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
                  <input
                    id="input-zipCode"
                    class="form-control"
                    type="text"
                    [(ngModel)]="device.zipCode"
                    name="device.zipCode"
                  />
                </div>
              </div>

              <div class="form-group">
                <label
                  class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
                  for="input-city"
                >
                  {{ "DEVICE_EDIT_CITY" | translate }}
                </label>
                <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
                  <input
                    id="input-city"
                    class="form-control"
                    type="text"
                    [(ngModel)]="device.city"
                    name="device.city"
                  />
                </div>
              </div>

              <div class="form-group">
                <label
                  class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
                  for="textarea-location-description"
                >
                  {{ "DEVICE_EDIT_LOCATION_DESCRIPTION" | translate }}
                </label>
                <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
                  <textarea
                    class="form-control"
                    rows="3"
                    id="textarea-location-description"
                    [(ngModel)]="device.locationDescription"
                    name="device.locationDescription"
                  ></textarea>
                </div>
              </div>

              <!-- div class="form-group">
                <label
                  class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
                  for="input-last-meter-reading"
                >
                  {{ "DEVICE_EDIT_LAST_METER_READING" | translate }}
                </label>
                <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
                  <input
                    id="input-last-meter-reading"
                    class="form-control"
                    type="number"
                    [(ngModel)]="device.lastMeterReading"
                    name="device.lastMeterReading"
                  />
                </div>
              <-->

              <div class="form-group">
                <label
                  class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
                  for="input-last-meter-reading"
                >
                </label>
                <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
                  <button (click)="save()" class="btn btn-primary button-row">
                    <span>{{ "BUTTON_SAVE" | translate }} </span
                    ><i class="mdi mdi-content-save color-bright"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          role="tabpanel"
          class="tab-pane fade"
          id="tab_content22"
          aria-labelledby="home-tab"
        >
          <div class="visible-xs col-xs-12" style="margin-bottom: 40px"></div>
          <div class="form-group">
            <label
              class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
              for="input-last-meter-reading"
            >
              <button
                class="btn btn-default"
                type="button"
                (click)="addMaintenance()"
                data-toggle="tooltip"
                data-placement="top"
                title="{{ 'DEVICE_EDIT_TOOLTIP_ADD_MAINTENANCE' | translate }}"
              >
                {{ "BUTTON_NEW" | translate }}
                <i class="mdi mdi-plus-circle indicator-color-green"></i>
              </button>
            </label>
          </div>
          <table class="table table-striped">
            <thead>
              <tr>
                <th class="table-header-col-no-sort">
                  {{ "MAINTENANCES_TABLE_DONE" | translate }}
                </th>
                <th class="table-header-col-no-sort">
                  {{ "MAINTENANCES_TABLE_NUMBER" | translate }}
                </th>
                <th class="table-header-col-no-sort">
                  {{ "MAINTENANCES_TABLE_BEGIN" | translate }}
                </th>
                <th class="table-header-col-no-sort">
                  {{ "MAINTENANCES_TABLE_CUSTOMER_NAME" | translate }}
                </th>
                <th class="table-header-col-no-sort">
                  {{ "MAINTENANCES_TABLE_CUSTOMER_FIRST_NAME" | translate }}
                </th>
                <!-- th class="table-header-col-no-sort">
              {{ "MAINTENANCES_TABLE_CUSTOMER_STREET" | translate }}
            <th>
            <th class="table-header-col-no-sort">
              {{ "MAINTENANCES_TABLE_CUSTOMER_ZIP_CODE" | translate }}
            </th>
            <th class="table-header-col-no-sort">
              {{ "MAINTENANCES_TABLE_CUSTOMER_CITY" | translate }}
            </th>
            <th class="table-header-col-no-sort">
              {{ "MAINTENANCES_TABLE_CUSTOMER_TELEPHONE" | translate }}
            </th -->
                <!-- th class="table-header-col-no-sort">
              {{ "MAINTENANCES_TABLE_DEVICE_NAME" | translate }}
            <th -->
                <th class="table-header-col-no-sort">
                  {{ "MAINTENANCES_TABLE_OWN" | translate }}
                </th>
                <th class="table-header-col-no-sort tableColWidthIcon">
                  <div
                    class="table-header-col"
                    (click)="addMaintenance()"
                    title="{{
                      'DEVICE_EDIT_TOOLTIP_ADD_MAINTENANCE' | translate
                    }}"
                  >
                    <i class="mdi mdi-plus-circle indicator-color-green"></i>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let maintenance of maintenances; let i = index">
                <td
                  [class.disabled]="
                    disableForeignMaintenances && !maintenance.isOwn
                  "
                  routerLink="/editMaintenance/{{ maintenance.uuid }}"
                >
                  <input
                    type="checkbox"
                    disabled
                    [checked]="maintenance.done"
                  />
                </td>
                <td
                  [class.disabled]="
                    disableForeignMaintenances && !maintenance.isOwn
                  "
                  routerLink="/editMaintenance/{{ maintenance.uuid }}"
                >
                  {{ maintenance.number }}
                </td>
                <td
                  [class.disabled]="
                    disableForeignMaintenances && !maintenance.isOwn
                  "
                  routerLink="/editMaintenance/{{ maintenance.uuid }}"
                >
                  {{ maintenance.textBegin }}
                </td>
                <td
                  [class.disabled]="
                    disableForeignMaintenances && !maintenance.isOwn
                  "
                  routerLink="/editMaintenance/{{ maintenance.uuid }}"
                >
                  {{ maintenance.customerName }}
                </td>
                <td
                  [class.disabled]="
                    disableForeignMaintenances && !maintenance.isOwn
                  "
                  routerLink="/editMaintenance/{{ maintenance.uuid }}"
                >
                  {{ maintenance.customerFirstName }}
                </td>
                <!-- td
              [class.disabled]="!maintenance.isOwn"
              routerLink="/editMaintenance/{{ maintenance.uuid }}"
            >
              {{ maintenance.customerStreet }}
          </td>
            <td
              [class.disabled]="!maintenance.isOwn"
              routerLink="/editMaintenance/{{ maintenance.uuid }}"
            >
              {{ maintenance.customerZipCode }}
            </td>
            <td
              [class.disabled]="!maintenance.isOwn"
              routerLink="/editMaintenance/{{ maintenance.uuid }}"
            >
              {{ maintenance.customerCity }}
            </td>
            <td
              [class.disabled]="!maintenance.isOwn"
              routerLink="/editMaintenance/{{ maintenance.uuid }}"
            >
              {{ maintenance.customerTelephone }}
            </td -->
                <!-- td
              [class.disabled]="!maintenance.isOwn"
              routerLink="/editMaintenance/{{ maintenance.uuid }}"
            >
              {{ maintenance.deviceName }}
            <td -->
                <td
                  [class.disabled]="
                    disableForeignMaintenances && !maintenance.isOwn
                  "
                  routerLink="/editMaintenance/{{ maintenance.uuid }}"
                >
                  <input
                    type="checkbox"
                    disabled
                    [checked]="maintenance.isOwn"
                  />
                </td>

                <td ng-show="1">
                  <div class="dropdown">
                    <button
                      class="btn btn-sm btn-default dropdown-toggle btn-action"
                      type="button"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      [hidden]="
                        disableForeignMaintenances &&
                        !maintenance.isOwn &&
                        maintenance.customer == null
                      "
                    >
                      {{
                        "GENERAL_TABLE_DROPDOWN_ACTIONS"
                          | translate
                          | capitalize
                      }}
                    </button>
                    <ul
                      class="dropdown-menu"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <li
                        [hidden]="
                          disableForeignMaintenances && !maintenance.isOwn
                        "
                      >
                        <a
                          class="dropdown-item"
                          routerLink="/editMaintenance/{{ maintenance.uuid }}"
                        >
                          <i class="mdi mdi-pencil"></i>&nbsp;{{
                            "MAINTENANCES_TABLE_TOOLTIP_EDIT"
                              | translate
                              | capitalize
                          }}</a
                        >
                      </li>
                      <li [hidden]="maintenance.customer == null">
                        <a
                          class="dropdown-item"
                          routerLink="/editCustomer/{{
                            maintenance.uuidCustomer
                          }}"
                        >
                          <i class="mdi mdi-account"></i>&nbsp;{{
                            "MAINTENANCES_TABLE_TOOLTIP_CUSTOMER"
                              | translate
                              | capitalize
                          }}</a
                        >
                      </li>
                    </ul>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          role="tabpanel"
          class="tab-pane fade"
          id="tab_content33"
          aria-labelledby="profile-tab"
        >
          <div class="visible-xs col-xs-12" style="margin-bottom: 40px"></div>
          <webcam
            [height]="500"
            [width]="500"
            [trigger]="triggerObservable"
            (imageCapture)="handleImage($event)"
            *ngIf="showWebcam"
            [allowCameraSwitch]="allowCameraSwitch"
            [switchCamera]="nextWebcamObservable"
            [videoOptions]="videoOptions"
            [imageQuality]="1"
            (cameraSwitched)="cameraWasSwitched($event)"
            (initError)="handleInitError($event)"
          ></webcam>

          <div [hidden]="!showWebcam" class="flex-nowrap">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <button
                (click)="triggerSnapshot()"
                class="btn btn-primary button-row"
              >
                <span>{{ "BUTTON_SNAPSHOT" | translate }} </span
                ><i class="mdi mdi-circle-slice-8 color-bright"></i>
              </button>

              <button
                [hidden]="!multipleWebcamsAvailable"
                (click)="showNextWebcam(true)"
                class="btn btn-primary button-row"
              >
                <span>{{ "BUTTON_CAMERA_FLIP" | translate }} </span
                ><i class="mdi mdi-camera-flip color-bright"></i>
              </button>
            </div>
          </div>

          <div class="flex-nowrap">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <button
                (click)="toggleWebcam()"
                class="btn btn-primary button-row"
              >
                <span>{{ "BUTTON_CAMERA" | translate }} </span
                ><i
                  class="mdi {{
                    showWebcam ? 'mdi-camera-off' : 'mdi-camera'
                  }} color-bright"
                ></i>
              </button>

              <input
                id="input-select-image-file-"
                type="file"
                hidden
                [(ngModel)]="pathImageFile"
                (ngModelChange)="selectedImageFile()"
                accept=".png,.jpg,.jpeg"
              />

              <input
                id="input-select-image-file"
                accept=".png,.jpg,.jpeg"
                hidden
                type="file"
                (change)="selectedImageListener($event)"
              />

              <button
                (click)="selectImageFile()"
                class="btn btn-primary button-row"
                hidde
              >
                <span>{{ "BUTTON_SELECT_FILE" | translate }} </span
                ><i class="mdi mdi-file-image color-bright"></i>
              </button>
            </div>

            <div
              *ngFor="let image of device.getImagesWithoutSignature()"
              class="col-lg-12 col-md-12 col-sm-12 col-xs-12"
            >
              <hr class="image-separator" />
              {{ image.name }}
              <img width="100%" src="{{ image.data }}" />

              <button
                [hidden]="image.isLink"
                (click)="removeImageStart(image.uuid)"
                class="btn btn-primary button-row"
              >
                <span>{{ "BUTTON_REMOVE" | translate }} </span
                ><i class="mdi mdi-delete-outline color-bright"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-6 col-md-8 col-sm-8 col-xs-12"></div>

    <!-- div class="clearfix"></div>

    <div class="">
      <div class="col-lg-6 col-md-8 col-sm-8 col-xs-12">
        <div class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"></div>
        <div class="flex-nowrap">
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
            <button (click)="save()" class="btn btn-primary button-row">
              <span>{{ "BUTTON_SAVE" | translate }} </span
              ><i class="mdi mdi-content-save color-bright"></i>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="clearfix"></div -->

    <div class="clearfix"></div>

    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div class="x_content"></div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="selectCustomerModal"
  tabindex="-1"
  aria-labelledby="conrimModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header display-flex">
        <h5 class="modal-title" id="confirmModalLabel">
          {{ "DEVICE_EDIT_SELECT_CUSTOMER_TITLE" | translate }}
        </h5>
        <button
          type="button"
          class="btn-close-modal"
          data-dismiss="modal"
          aria-label="Close"
        >
          <i class="mdi mdi-close color-main"></i>
        </button>
      </div>
      <div class="modal-body modal-body-select">
        <app-view-customers-select
          (selectedCustomerModal)="selectedCustomerModal($event)"
        >
        </app-view-customers-select>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-default" data-dismiss="modal">
          {{ "BUTTON_CANCEL" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Modal select article dialog -->
<div
  class="modal fade"
  id="selectArticleModal"
  tabindex="-1"
  aria-labelledby="conrimModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header display-flex">
        <h5 class="modal-title" id="confirmModalLabel">
          {{ "DEVICE_EDIT_SELECT_ARTICLE_TITLE" | translate }}
        </h5>
        <button
          type="button"
          class="btn-close-modal"
          data-dismiss="modal"
          aria-label="Close"
        >
          <i class="mdi mdi-close color-main"></i>
        </button>
      </div>
      <div class="modal-body modal-body-select">
        <app-view-articles-select (selectedArticleModal)="selectedArticleModal($event)">
        </app-view-articles-select>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-primary button-row"
          data-dismiss="modal"
        >
          {{ "BUTTON_CANCEL" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Modal confirm remove image dialog -->
<div
  class="modal fade"
  id="confirmRemoveImage"
  tabindex="-1"
  aria-labelledby=""
  aria-hidden="true"
>
  <div class="modal-dialog modal-md">
    <div class="modal-content">
      <div class="modal-header display-flex">
        <h5 class="modal-title">
          {{ "MAINTENANCE_EDIT_TITLE_REMOVE_IMAGE" | translate }}
        </h5>
        <button
          type="button"
          class="btn-close-modal"
          data-dismiss="modal"
          aria-label="Close"
        >
          <i class="mdi mdi-close color-main"></i>
        </button>
      </div>
      <div class="modal-body modal-body-selec">
        <div>
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div class="form-horizontal form-label-left">
              {{ imageToRemove.name }}
              <img width="100%" src="{{ imageToRemove.data }}" />
            </div>
          </div>
          <div class="clearfix"></div>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-primary button-row"
          (click)="removeImage()"
        >
          <span>{{ "BUTTON_REMOVE" | translate }} </span
          ><i class="mdi mdi-delete-outline color-bright"></i>
        </button>
        <button
          type="button"
          class="btn btn-primary button-row"
          data-dismiss="modal"
        >
          {{ "BUTTON_CANCEL" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
