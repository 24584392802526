import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { CustomerModel } from '../models/customer-model';
import { PartnerModel } from '../models/partner-model';
import { RepositoryService } from '../repository.service';
declare const log: any;

@Component({
  selector: 'app-view-partners',
  templateUrl: './view-partners.component.html',
  styleUrls: ['./view-partners.component.css']
})
export class ViewPartnersComponent implements OnInit {

  uuidCustomer = ""
  partners: PartnerModel[] = []
  customer: CustomerModel = new CustomerModel()
  textNameCustomer = ""
  currentPage = 0
  pageSize = environment.entriesPerPage

  constructor(
    private route: ActivatedRoute,
    protected router: Router,
    protected repository: RepositoryService,
  ) { }

  ngOnInit(): void {
    this.init()
  }

  async init() {
    const routeParams = this.route.snapshot.paramMap;
    this.uuidCustomer = String(routeParams.get('uuidCustomer'));
    if (!this.uuidCustomer || this.uuidCustomer == "")
      this.router.navigateByUrl("/")

    let partnersR = await this.repository.partners.readAll("lastName", true)
    partnersR = partnersR.filter((partner: any) => {
      return partner.uuidCustomer == this.uuidCustomer
    })
    this.partners = partnersR
    log("the partners")
    log(this.partners)

    this.customer = <CustomerModel>await this.repository.customers.read(this.uuidCustomer)
    if(!this.customer)
      this.router.navigateByUrl("/start")
    this.textNameCustomer = this.customer.firstName + " " + this.customer.lastName;
  }

  numberOfPages() {
    if (this.partners && this.partners.length > 0)
      return Math.ceil(this.partners.length / this.pageSize);
    else
      return 1;
  }

  storeCurrentPage() {

  }
}
