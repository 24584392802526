import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ParametersStartService } from './parameters-start.service';
import { TranslateService } from '@ngx-translate/core';
import { NgbDateStruct, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap'
import { DbService } from './db.service';
declare const log: any;
declare var $: any;
declare const dataModels: any;
declare const moment: any;

@Injectable({
  providedIn: 'root'
})
export class CommonsService {

  static NAME_FILE_SIGNATURE_IMAGE = "signature.png";

  static ID_TABLE_OFFERS = 150;
  static NAME_COLUMN_OFFERS_IMAGES = "Bilder_277";
  static ID_TABLE_ORDERS = 152;
  static NAME_COLUMN_ORDERS_IMAGES = "Bilder_136";
  static ID_TABLE_MAINTENANCES = 246;
  static NAME_COLUMN_MAINTENANCES_IMAGES = "Bilder_69"
  static ID_TABLE_DEVICES = 112;
  static NAME_COLUMN_DEVICES_IMAGES = "Bilder_43"

  static TIME_TRIGGER_SORTING_MS = 200;
  static TIME_NOT_VALID_MS = 0// -2208988800000;
  static UUID_DUMMY = "00000000-0000-0000-0000-000000000000"

  static TIME_DELAY_FOCUS_MS = 500

  static IMAGE_TYPE_JPG = 5
  static IMAGE_TYPE_PNG = 8

  static SLEEP_MS_FOR_SHOW_MODALS = 50

  constructor(
    private router: Router,
    private parametersStart: ParametersStartService,
    private translate: TranslateService,
    private dbService: DbService) {
  }

  static getIdTable(nameModel: string) {
    if (nameModel == "Angebot")
      return CommonsService.ID_TABLE_OFFERS
    else if (nameModel == "Auftrag")
      return CommonsService.ID_TABLE_ORDERS
    else if (nameModel == "Wartungsauftrag")
      return CommonsService.ID_TABLE_MAINTENANCES
    else if (nameModel == "Gerät")
      return CommonsService.ID_TABLE_DEVICES
    else
      return 0
  }


  // check any response from server, throws error or returns true
  public checkResponse(response: any) {
    const this_ = this;
    log("response to check: ", response);

    if (!response) {
      this.parametersStart.buttonSyncReset();
      CommonsService.showErrorMessage(this.translate.instant("START_SYNC_UNKNOWN_ERROR"))

      throw Error("No response.");
    } else if (response.status != "OK") {
      this.parametersStart.buttonSyncReset();
      if (response.data === "INVALID_TOKEN") {
        this_.translate.get("ERROR_NOT_LOGGED_IN").subscribe((translated: string) => CommonsService.showErrorMessage(translated))
        this.dbService.writeNameUser("").then(() => {
          this_.parametersStart.nameUser = "";
          this_.router.navigateByUrl("/login");
        })

      } else {
        CommonsService.showErrorMessage(this.translate.instant("START_SYNC_UNKNOWN_ERROR") + " " + JSON.stringify(response))
      }
      throw Error("No response status not ok.");
    } else return true;
  }

  // connection error with server occured
  public onErrorNoConnection() {
    this.parametersStart.buttonSyncReset();
    CommonsService.showErrorMessage(this.translate.instant("START_SYNC_CONNECTION_REFUSED"))
  }

  private static times: any = {}

  static startTime(name: string) {
    CommonsService.times[name] = new Date()
  }

  static stopTime(name: string = "") {
    let time = CommonsService.times[name]

    if (!time) {
      CommonsService.times[name] = new Date()
    } else {
      let endTime = new Date();
      var timeDiff = endTime.getTime() - time.getTime(); //in ms
      // strip the ms
      timeDiff /= 1000;

      // get seconds
      var seconds = timeDiff
      console.log("duration (" + name + "): " + seconds + " seconds");
      CommonsService.times[name] = null
    }
  }

  static sort(objs: any[], memberName: string, ascending: boolean): any[] {

    objs.sort(function (obj0: any, obj1: any) {
      var cmp = 0;
      var val0 = obj0[memberName];
      var val1 = obj1[memberName];
      if (val0 == val1) {
        cmp = 0;
      } else {
        let type = typeof val0
        if (type == "object" && val0 instanceof Date) {
          if (val0 == null)
            cmp = -1
          if (val1 == null)
            cmp = 1
          else if (val0.getTime() == val1.getTime())
            cmp = 0
          else
            cmp = val0.getTime() > val1.getTime() ? 1 : -1
        } else if (type == "boolean") {
          cmp = val0 ? 1 : -1
        } else {
          var isNaN0 = isNaN(val0);
          var isNaN1 = isNaN(val1);

          if (!isNaN0 && !isNaN1) {
            cmp = parseFloat(val0) > parseFloat(val1) ? 1 : -1;
          } else if (!isNaN0) {
            cmp = -1;
          } else if (!isNaN1) {
            cmp = 1;
          } else {
            cmp = val0.localeCompare(val1);
          }
        }
      }

      return cmp;
    });

    if (!ascending)
      objs.reverse();

    return objs
  }

  public static getObjectByIdAtClient(objects: any[], idAtClient: number) {
    var objectFound = null;
    if (objects) {
      objects.some(function (object) {
        if (object.idAtClient == idAtClient) {
          objectFound = object;
          return;
        }
      });
    }
    return objectFound;
  }

  public static getObjectByUuid(objects: any[], uuid: string) {
    var objectFound = null;
    if (objects) {
      for (var iO = 0; iO < objects.length; iO++) {
        if (objects[iO].uuid == uuid) {
          objectFound = objects[iO];
          break;
        }
      }
    }
    return objectFound;
  }

  // does async function on array of parameters sequentially
  public static async doSequentially(parameters: any[], asyncFunc: (parameter: any) => Promise<any>) {
    var isOk = true
    return new Promise((resolve, reject) => {
      parameters.reduce((prev, parameter) => {
        return prev.then(() => {
          // log("BEGIN ", parameter)
          if (isOk)
            return asyncFunc(parameter).then(() => {
              // log("END ", parameter)
              return Promise.resolve();
            })
          else {
            // ignoring remaining items after error
            // log("IGNORING", parameter)
            return Promise.resolve()
          }
        }).catch(() => {
          isOk = false
          return Promise.resolve()
        })
      }, Promise.resolve()
      ).then(() => {
        return resolve(isOk)
      })
    })
  }

  public static checkNumberString(discountString: string) {
    if (!discountString || isNaN(+discountString.replace(',', '.'))) {
      return "0";
    } else {
      while (discountString.charAt(0) == '0' &&
        discountString.length > 1 &&
        discountString.charAt(1) != '.' &&
        discountString.charAt(1) != ',') {
        discountString = discountString.substring(1, discountString.length);
      }
      return discountString
    }
  }

  // multiplies a value-currency tuple by the factor mult
  public static valueAndCurrencyMult(valueAndCurrency: string, mult: number) {
    var valueAndCurrencyArr = valueAndCurrency.split(" ");

    while (valueAndCurrencyArr[0].indexOf(".") >= 0)
      valueAndCurrencyArr[0] = valueAndCurrencyArr[0].replace(".", "");

    var value = parseFloat(valueAndCurrencyArr[0].replace(",", "."));
    return CommonsService.convertValueToPrice(value * mult, valueAndCurrencyArr[1]);
  }

  public static parseValueWithCurrency(valueWithCurrency: string, currency: string) {
    var priceAndCurrencyGross = valueWithCurrency.split(" ");
    return this.convertValueToPrice(parseFloat(priceAndCurrencyGross[0].replace(",", ".")), currency)
  }


  public static getValueFromValueWithCurrency(valueAndCurrency: string) {
    return parseFloat(valueAndCurrency.split(" ")[0].replace(",", "."));
  }

  // strips rtf format data from a rtf string
  public static convertRtfToPlain(rtf: string) {
    if (!rtf) return "";
    rtf = rtf.replace(/\\par[d]?/g, "");
    return rtf
      .replace(/\{\*?\\[^{}]+}|[{}]|\\\n?[A-Za-z]+\n?(?:-?\d+)?[ ]?/g, "")
      .trim();
  }

  public static getNgbDateStructFromDate(date: Date): NgbDateStruct {
    var dateNgb: NgbDateStruct = { year: 1970, month: 1, day: 1 }
    dateNgb.year = date.getFullYear()
    dateNgb.month = date.getMonth() + 1
    dateNgb.day = date.getDate()
    return dateNgb
  }

  public static getNgbTimeStructFromDate(date: Date): NgbTimeStruct {
    var timeNgb: NgbTimeStruct = { hour: 0, minute: 0, second: 0 }
    timeNgb.hour = date.getHours()
    timeNgb.minute = date.getMinutes()
    timeNgb.second = date.getSeconds()
    return timeNgb
  }

  public static getDateFromNgbDateStruct(date: Date, ngbDate: NgbDateStruct): Date {
    if (isNaN(ngbDate.year) || isNaN(ngbDate.month) || isNaN(ngbDate.day))
      return date;
    date.setFullYear(ngbDate.year);
    date.setMonth(ngbDate.month - 1);
    date.setDate(ngbDate.day);
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    return date;
  }

  public static getTimeFromNgbTimeStruct(date: Date, ngbTime: NgbTimeStruct): Date {
    if (isNaN(ngbTime.hour) || isNaN(ngbTime.minute) || isNaN(ngbTime.second))
      throw Error("Invalid time")
    date.setHours(ngbTime.hour);
    date.setMinutes(ngbTime.minute);
    date.setSeconds(ngbTime.second);
    return date;
  }

  public static showErrorMessage(message: string) {
    $.bootstrapPurr(message, {
      type: "danger",
      allowDismissType: 'hover',
      offset: {
        amount: 60, // (number)
        from: 'top', // ('top', 'bottom')
        stackupSpacing: 5
      }
    })
  }

  public static showSuccessMessage(message: string) {
    $.bootstrapPurr(message, {
      type: "success",
      allowDismissType: 'hover',
      offset: {
        amount: 60, // (number)
        from: 'top', // ('top', 'bottom')
        stackupSpacing: 5
      },
    })
  }

  public static dateToMySQLString(date: Date) {
    return (
      date.getFullYear() +
      "-" +
      ("00" + (date.getMonth() + 1)).slice(-2) +
      "-" +
      ("00" + date.getDate()).slice(-2) +
      " " +
      ("00" + date.getHours()).slice(-2) +
      ":" +
      ("00" + date.getMinutes()).slice(-2) +
      ":" +
      ("00" + date.getSeconds()).slice(-2)
    );
  }

  // formats all values (member names end with 'V') to a double decimal number with the currency and stores it as a new member
  public static setPrices(object: any, currency: string) {
    if (!object)
      return
    for (var propertyName in object) {
      if (propertyName.endsWith("V")) {
        var propertyNamePrice = propertyName.substring(
          0,
          propertyName.length - 1
        );
        object[propertyNamePrice] = CommonsService.convertValueToPrice(
          object[propertyName],
          currency
        );
      }
    }
  }

  // formats a value to a double decimal number with the currency added
  public static convertValueToPrice(value: number, currency: string) {
    if (!value) return "0,00 " + currency;
    else {
      var price = (Math.round(value * 100.0) / 100.0).toLocaleString("de-DE");
      if (price.charAt(price.length - 3) == ",") {
        //
      } else if (price.charAt(price.length - 2) == ",") {
        price = price + "0";
      }
      else {
        price = price + ",00";
      }

      return price + " " + currency;
    }
  }

  public static getModelColumnDefinitionByNameLocal(nameTableModel: string, nameColumnLocal: string) {
    var tableDefinition = dataModels[nameTableModel];
    if (!tableDefinition) return undefined;

    for (var iColumn = 0; iColumn < tableDefinition.columns.length; iColumn++) {
      var columnDefinition = tableDefinition.columns[iColumn];
      if (columnDefinition.nameLocal == nameColumnLocal) {
        return columnDefinition;
      }
    }

    return undefined;
  }

  // 02.04.1987
  public static formatDate0(date: Date) {
    if (date)
      return moment(date.getTime()).format("DD.MM.YYYY")
    else
      return ("")
  }

  public static formatDate(date: Date) {
    if (date)
      return (
        date.getDate() +
        "." +
        (date.getMonth() + 1) +
        "." +
        date.getFullYear()
      );
    else return "";
  }

  public static formatDateTime(date: Date) {
    if (date && date.getTime() > 0)
      return CommonsService.formatDate(date) + " " + CommonsService.formatTime(date);
    else return "";
  }

  public static formatDateTime0(date: Date) {
    return moment(date.getTime()).format("DD.MM.YYYY HH:mm")
    //return moment.utc(date).local().format("DD.MM.YYYY HH:mm")
  }

  public static formatDateTime0Omit00(date: Date) {
    if (date.getHours() != 0 || date.getMinutes() != 0)
      return moment(date.getTime()).format("DD.MM.YYYY HH:mm")
    else
      return moment(date.getTime()).format("DD.MM.YYYY")
  }

  public static formatTime(date: Date) {
    if (!date)
      return ""

    return (
      date.getHours() +
      ":" +
      (date.getMinutes() < 10 ? "0" : "") +
      date.getMinutes()
    );
  }

  public static parseDate(input: String) {
    var parts: RegExpMatchArray | null = input.match(/(\d+)/g);
    let date = new Date(1900, 0, 1);
    if (parts != null && parts.length >= 3) {
      date = new Date(+parts[2], +parts[1] - 1, +parts[0]);
    }

    return moment(date).utc(true).toDate();
  }

  public static parseDateTime(input: String) {
    let parts = input.split(' ');
    let dateTime = this.parseDate(parts[0])
    let hoursMinutes = parts[1].split(':')
    dateTime.setHours(+hoursMinutes[0])
    dateTime.setMinutes(+hoursMinutes[1])
    return moment(dateTime).utc(true).toDate();
  }

  public static formatTime0(date: Date) {
    return (
      (date.getHours() < 10 ? "0" : "") +
      date.getHours() +
      ":" +
      (date.getMinutes() < 10 ? "0" : "") +
      date.getMinutes()
    );
  }

  public static formatHoursMinutes0(timeHours: number) {
    let hours = Math.floor(timeHours);
    let minutes = Math.floor((timeHours * 60) % 60);
    let text = (hours < 10 ? "0" : "") + hours + ":" + (minutes < 10 ? "0" : "") + minutes;
    return text
  }

  // YYYY-MM-DD HH:mm:ss
  public static formatDateDb(date: Date) {
    if (date)
      return date.getFullYear() +
        "-" +
        (date.getMonth() + 1 < 10 ? "0" : "") +
        (date.getMonth() + 1) +
        "-" +
        (date.getDate() < 10 ? "0" : "") +
        date.getDate() +
        " " +
        (date.getHours() < 10 ? "0" : "") +
        date.getHours() +
        ":" +
        (date.getMinutes() < 10 ? "0" : "") +
        date.getMinutes() +
        ":" +
        (date.getSeconds() < 10 ? "0" : "") +
        date.getSeconds()
    else return ""
  }

  public static buildSequenceOfPositionUuidsOfSale_(sale: any) {
    // rebuild sequence of postion ids
    var stringOfIds = "";
    var stringOfUuids = ""
    for (var iPos = 0; iPos < sale.positions.length; iPos++) {
      if (stringOfIds) stringOfIds += ",";
      if (stringOfUuids) stringOfUuids += ",";
      stringOfIds += sale.positions[iPos].idAtClient;
      stringOfUuids += sale.positions[iPos].uuid;
    }
    sale.sequenceOfPositionIdsAtClient = stringOfIds;
    sale.uuidsSalePositions = stringOfUuids;
  }

  public static async sleep(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  public static async showProcessingMessage(calledBy: string) {
    log("showProcessingMessage: " + calledBy)
    if (!$('#modalIsProcessing').is(':visible')) {
      $('#modalIsProcessing').modal('show')
    }
    await CommonsService.sleep(CommonsService.SLEEP_MS_FOR_SHOW_MODALS) // make it visble
  }

  public static async hideProcessingMessage(calledBy: string) {
    log("hideProcessingMessage: " + calledBy)
    if ($('#modalIsProcessing').is(':visible')) {
      $('#modalIsProcessing').modal('hide')
      //$('body').removeClass('modal-open');
      //$('body').css("padding-right", "0px");
      //$('.modal-backdrop').remove();
    }
    await CommonsService.sleep(CommonsService.SLEEP_MS_FOR_SHOW_MODALS) // make it visble
  }

  public static async showProcessingMessageSync(calledBy: string, countManipulationBatches: number) {
    log("showProcessingMessageSync: " + calledBy)
    if (countManipulationBatches > 0 && !$('#modalIsSyncing').is(':visible')) {
      $('#modalIsSyncing').modal('show')
    }
    await CommonsService.sleep(CommonsService.SLEEP_MS_FOR_SHOW_MODALS) // make it visble
  }

  public static async hideProcessingMessageSync(calledBy: string) {
    log("hideProcessingMessageSync: " + calledBy)
    if ($('#modalIsSyncing').is(':visible')) {
      $('#modalIsSyncing').modal('hide')
      //$('body').removeClass('modal-open');
      //$('body').css("padding-right", "0px");
      //$('.modal-backdrop').remove();
    }
    await CommonsService.sleep(CommonsService.SLEEP_MS_FOR_SHOW_MODALS) // make it visble
  }

}
