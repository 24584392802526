import { BaseModel } from "./base-model";

export class StockMovementModel extends BaseModel{
  uuidArticle = ""
  idStockMovementTypeAtServer = 0
  date = new Date()
  amount = 1.0
  uuidMaintenance = ""

  constructor(params: any = null) {
    super(params)
    this.copyFromObject(params)
  }

  static override newModel(params: any = null): StockMovementModel {
    return new StockMovementModel(params)
  }
}
