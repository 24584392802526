import { BaseModel } from "./base-model";

export class TaxRateModel extends BaseModel{

  active: boolean = true
  name = ""
  nameShort = ""
  percentTotal = 0.0

  constructor(params: any = null) {
    super(params)
    this.copyFromObject(params)
  }

  static override newModel(params: any = null): TaxRateModel {
    return new TaxRateModel(params)
  }
}
