<div role="main">
  <div class="">
    <div class="page-title">
      <div class="title_left">
        <h3>{{ textTitle }}</h3>
      </div>
    </div>
  </div>

  <div class="clearfix"></div>

  <div class="x_panel">
    <div class="col-lg-6 col-md-8 col-sm-8 col-xs-12">
      <div class="form-horizontal form-label-left">
        <div class="form-group">
          <label
            class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
            for="checkbox-done"
          >
            {{ "TASK_EDIT_DONE" | translate }}
          </label>
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
            <input
              style="top: 6px; position: relative"
              id="checkbox-done"
              class=""
              type="checkbox"
              [(ngModel)]="doneBool"
            />
          </div>
        </div>

        <div class="form-group" [hidden]="hasDateDue">
          <label class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3">
            {{ "TASK_EDIT_DUE" | translate }}
          </label>
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
            <button
              class="btn btn-default button-row"
              (click)="showTimeDue()"
              title="{{ 'TASK_EDIT_TIME_DUE_OPEN' | translate }}"
            >
              <i class="mdi mdi-calendar-clock"></i>
            </button>
          </div>
        </div>

        <div class="form-group" [hidden]="!hasDateDue">
          <label class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3">
            {{ "TASK_EDIT_DUE" | translate }}
          </label>
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
            <button
              class="btn btn-default button-row"
              (click)="hideTimeDue()"
              title="{{ 'TASK_EDIT_TIME_DUE_CANCEL' | translate }}"
            >
              <i class="mdi mdi-cancel"></i>
            </button>
          </div>
        </div>

        <div class="form-group" [hidden]="!hasDateDue">
          <label
            class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
            for="input-date-due"
          >
          </label>
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
            <div class="input-group date" id="div-date-due">
              <input
                id="input-date-due"
                type="text"
                class="form-control"
                placeholder="dd.mm.yyyy HH:MM"
                value="{{ textDue }}"
              />
              <!-- onkeydown="return false" -->
              <span class="input-group-addon">
                <span class="glyphicon glyphicon-calendar"></span>
                <!--i class="mdi mdi-calendar icon-datepicker"></i-->
              </span>
            </div>
          </div>
        </div>

        <div class="form-group">
          <label
            class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
            for="select-category"
            >{{ "TASK_EDIT_CATEGORY" | translate }}
          </label>
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
            <select
              id="select-category"
              class="form-control"
              [(ngModel)]="task.uuidTaskCategory"
              name="task.uuidTaskCategory"
            >
              <option
                *ngFor="let taskCategory of taskCategories"
                [ngValue]="taskCategory.uuid"
              >
                {{ taskCategory.name }}
              </option>
            </select>
          </div>
        </div>

        <div class="form-group">
          <label
            class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
            for="input-priority"
          >
            {{ "TASK_EDIT_PRIORITY" | translate }}
          </label>
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
            <input
              id="input-priority"
              class="form-control"
              type="number"
              min="0.0"
              max="100.0"
              step="1"
              [(ngModel)]="task.priority"
              name="task.priority"
            />
          </div>
        </div>

        <div class="form-group">
          <label
            class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
            for="textarea-title"
          >
            {{ "TASK_EDIT_SUBJECT" | translate }}
          </label>
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
            <textarea
              class="form-control"
              rows="3"
              id="textarea-title"
              [(ngModel)]="task.title"
              name="task.title"
            ></textarea>
          </div>
        </div>

        <div class="form-group">
          <label
            class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
            for="textarea-description"
          >
            {{ "TASK_EDIT_DESCRIPTION" | translate }}
          </label>
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
            <textarea
              class="form-control"
              rows="3"
              id="textarea-description"
              [(ngModel)]="task.description"
              name="task.description"
            ></textarea>
          </div>
        </div>
      </div>
    </div>

    <div class="clearfix"></div>

    <div class="">
      <div class="col-lg-6 col-md-8 col-sm-8 col-xs-12">
        <div class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"></div>
        <div class="flex-nowrap">
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
            <button (click)="save()" class="btn btn-primary button-row">
              <span>{{ "BUTTON_SAVE" | translate }} </span
              ><i class="mdi mdi-content-save color-bright"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
