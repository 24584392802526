import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common'
import { PartnerModel } from '../models/partner-model';
import { RepositoryService } from '../repository.service';
import { SexModel } from '../models/sex-model';
declare const log: any

@Component({
  selector: 'app-delete-partner',
  templateUrl: './delete-partner.component.html',
  styleUrls: ['./delete-partner.component.css']
})
export class DeletePartnerComponent implements OnInit {

  uuidPartner = ""
  partner: PartnerModel = new PartnerModel()
  sexes: SexModel[] = []

  constructor(protected location: Location,
    private route: ActivatedRoute,
    private router: Router,
    protected repository: RepositoryService) { }

  ngOnInit(): void {
    this.init()
  }

  async init() {
    const routeParams = this.route.snapshot.paramMap;
    this.uuidPartner = String(routeParams.get('uuidPartner'));

    this.sexes = <SexModel[]>await this.repository.sexes.readAll()

    this.partner = <PartnerModel>await this.repository.partners.read(this.uuidPartner)
  }

  async delete() {
    this.repository.partners.delete(this.uuidPartner)
    this.location.back()
  }

}
