<div role="main">
  <div class="">
    <div class="page-title">
      <div class="title_left">
        <h3>{{ "SALEPOS_EDIT_TITLE" | translate }}</h3>
      </div>
    </div>
  </div>

  <div class="clearfix"></div>

  <div class="x_panel">
    <div class="col-lg-6 col-md-8 col-sm-8 col-xs-12">
      <div class="form-horizontal form-label-left">
        <div class="form-group">
          <label
            class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
            for="input-name"
          >
            {{ "SALEPOS_EDIT_NAME" | translate }}
          </label>
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
            <input
              id="input-name"
              type="text"
              class="form-control"
              [(ngModel)]="salePosition.name"
            />
            <!-- <input
            id="input-name"
            type="text"
            ng-disabled="true"
            class="form-control"
            readonly
            [(ngModel)]="salePosition.name"
          /> -->
          </div>
        </div>
        <div class="form-group">
          <label
            class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
            for="textarea-description"
            >{{ "ARTICLE_VIEW_DESCRIPTION" | translate }}
          </label>
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
            <textarea
              id="textarea-description"
              class="form-control"
              rows="4"
              id="subject"
              [(ngModel)]="salePosition.description"
            ></textarea>
            <!-- <textarea
            id="textarea-description"
            class="form-control"
            rows="4"
            id="subject"
            readonly
            [(ngModel)]="salePosition.description_plain"
          ></textarea> -->
          </div>
        </div>
        <div class="form-group">
          <label
            class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
            for="input-amount"
          >
            {{ "SALEPOS_EDIT_AMOUNT" | translate }}
          </label>
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
            <input
              id="input-amount"
              type="text"
              class="form-control"
              (change)="changeAmount()"
              [(ngModel)]="amount"
            />
          </div>
        </div>
        <div class="form-group">
          <label
            class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
            for="input-discount"
          >
            {{ "SALEPOS_EDIT_DISCOUNT" | translate }}
          </label>
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
            <input
              id="input-discount"
              type="text"
              class="form-control"
              (change)="changeDiscount()"
              [(ngModel)]="discount"
            />
          </div>
        </div>
        <div class="form-group">
          <label
            class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
            for="input-net"
          >
            {{ "SALEPOS_EDIT_PRICE_NET" | translate }}
          </label>
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
            <input
              id="input-net"
              type="text"
              class="form-control"
              (blur)="changeNet()"
              [(ngModel)]="salePosition.priceUnitNet"
            />
          </div>
        </div>
        <div class="form-group">
          <label
            class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
            for="input-gross"
          >
            {{ "SALEPOS_EDIT_PRICE_GROSS" | translate }}
          </label>
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
            <input
              id="input-gross"
              type="text"
              class="form-control"
              (blur)="changeGross()"
              [(ngModel)]="salePosition.priceUnitGross"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="clearfix"></div>

    <div class="">
      <div class="col-lg-6 col-md-8 col-sm-8 col-xs-12">
        <div class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"></div>
        <div class="flex-nowrap">
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
            <button (click)="save()" class="btn btn-primary button-row">
              <span>{{ "BUTTON_SAVE" | translate }} </span
              ><i class="mdi mdi-content-save color-bright"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
