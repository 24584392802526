import { Injectable } from '@angular/core';
import { CommonsService } from './commons-service';
import { DbNoConnectionError } from './db-no-connection-error';
import { DbOutOfDateError } from './db-out-of-date-error';
declare const log: any;
declare const lf: any;
declare const dataModels: any;

@Injectable({
  providedIn: 'root'
})
export class DbService {
  private db: any = null;

  constructor() {
    this.doInit();
  }

  private doInit() {
    log("In init db service.")

    var schemaBuilder = lf.schema.create('FF_DB', 117); // last parameter is db version

    /*
    schemaBuilder.createTable("testTable00").
      addColumn('idAtClient', lf.Type.INTEGER).
      addColumn('name', lf.Type.STRING).
      addPrimaryKey(['idAtClient'], true).
      addNullable(['name']);
    log("created table testTable00");
    */

    schemaBuilder.createTable(dataModels.Kunde.table).
      addColumn('idAtClient', lf.Type.INTEGER).
      //addColumn('idAtServer', lf.Type.INTEGER).
      addColumn('uuid', lf.Type.STRING).
      addColumn('deleted', lf.Type.BOOLEAN).
      addColumn('NameFirma_5', lf.Type.STRING).
      addColumn('Titel_8', lf.Type.STRING).
      addColumn('Vorname_9', lf.Type.STRING).
      addColumn('KundenNr_10', lf.Type.STRING).
      addColumn('Strasse_11', lf.Type.STRING).
      addColumn('PLZ_12', lf.Type.STRING).
      addColumn('Ort_13', lf.Type.STRING).
      addColumn('Telefon1_15', lf.Type.STRING).
      addColumn('Telefon2_16', lf.Type.STRING).
      addColumn('Fax_17', lf.Type.STRING).
      addColumn('E_Mail_18', lf.Type.STRING).
      addColumn('Geburtsdatum_51', lf.Type.DATE_TIME).
      addColumn('Homepage_19', lf.Type.STRING).
      addColumn('Bemerkung_30', lf.Type.STRING).
      addColumn('UID_Nummer_41', lf.Type.STRING).
      addColumn('Stichworte_56', lf.Type.STRING).
      //addColumn('idSexAtClient_9999', lf.Type.INTEGER).	// id sex at client
      //addColumn('Geschlecht_52', lf.Type.INTEGER).		// id sex at server
      addColumn('uuidSex', lf.Type.STRING).
      //addColumn('idCountryAtClient_9999', lf.Type.INTEGER). // id country at client
      //addColumn('Land_14', lf.Type.INTEGER).		// id country at server
      addColumn('uuidCountry', lf.Type.STRING).
      //addColumn('idsCustomerCategoriesAtClient_9999', lf.Type.STRING). // id at client
      //addColumn('Kundenkategorie_21', lf.Type.STRING). // id at server
      addColumn('uuidsCustomerCategories', lf.Type.STRING).
      //addColumn('idPaymentTypeAtClient_9999', lf.Type.INTEGER).	// id paymenttype at client
      //addColumn('Zahlungsart_61', lf.Type.INTEGER).				// id paymenttype at server
      addColumn('uuidPaymentType', lf.Type.STRING).
      //addColumn('idPaymentTargetAtClient_9999', lf.Type.INTEGER).	// id paymenttarget at client
      //addColumn('Zahlungsziel_62', lf.Type.INTEGER).				// id paymenttarget at server
      addColumn('uuidPaymentTarget', lf.Type.STRING).
      //addColumn('idPricelistAtClient_9999', lf.Type.INTEGER).		// id pricelist at client
      //addColumn('Preisliste_60', lf.Type.INTEGER).				// id pricelist at server
      addColumn('uuidPricelist', lf.Type.STRING).
      addColumn('Rabatt_69', lf.Type.NUMBER).
      addColumn('Ust_befreit_122', lf.Type.BOOLEAN).
      addColumn('letzter_Kontakt_Werbung_142', lf.Type.DATE_TIME).
      addColumn('naechster_Kontakt_163', lf.Type.DATE_TIME).
      addColumn('AnredePersoenlichDu_176', lf.Type.BOOLEAN).
      addColumn('Kontaktfrequenz_Tage_185', lf.Type.INTEGER).
      addColumn('Wiederanruf_Thema_238', lf.Type.STRING).
      addPrimaryKey(['idAtClient'], true).
      addIndex('idxUuid', ['uuid']).
      //addIndex('idxIdAtServer', ['idAtServer'], false, lf.Order.ASC).
      addNullable(['NameFirma_5', 'Titel_8', 'Vorname_9', 'KundenNr_10', 'Strasse_11', 'PLZ_12',
        'Ort_13', 'Telefon1_15', 'Telefon2_16', 'Fax_17', 'E_Mail_18', 'Homepage_19',
        'uuidSex', 'uuidCountry', 'uuidPaymentType',
        'uuidPaymentTarget', 'uuidPricelist', 'uuidsCustomerCategories',
        'UID_Nummer_41',
        'Ust_befreit_122', 'AnredePersoenlichDu_176', 'Kontaktfrequenz_Tage_185',
        'Wiederanruf_Thema_238']);
    log("created table " + dataModels.Kunde.table);

    schemaBuilder.createTable(dataModels.Artikel.table).
      addColumn('idAtClient', lf.Type.INTEGER).
      //addColumn('idAtServer', lf.Type.INTEGER).
      addColumn('uuid', lf.Type.STRING).
      addColumn('deleted', lf.Type.BOOLEAN).
      addColumn('Artikelnr_5', lf.Type.STRING).
      addColumn('Bezeichnung_6', lf.Type.STRING).
      addColumn('EAN_Code_8', lf.Type.STRING).
      addColumn('Artikelgruppe_24', lf.Type.INTEGER).
      addColumn('uuidArticlegroup', lf.Type.STRING).
      addColumn('Beschreibung_33', lf.Type.STRING).
      addColumn('Beschreibung2_34', lf.Type.STRING).
      addColumn('GewichtKg_39', lf.Type.NUMBER).
      //addColumn('Steuersatz_62', lf.Type.INTEGER).
      addColumn('uuidTaxRate', lf.Type.STRING).
      addColumn('Gesamtsteuersatz_63', lf.Type.NUMBER).
      addColumn('Provisionsstufe_65', lf.Type.NUMBER).
      addColumn('PreisBrutto_69V', lf.Type.STRING).
      addColumn('PreisNetto_70V', lf.Type.STRING).
      addColumn('StandardDB_71V', lf.Type.STRING).
      addColumn('StandardDBProzent_72', lf.Type.STRING).
      addColumn('Kalk_EK_Preis_per_Stk_103V', lf.Type.STRING).
      addColumn('Bezugskosten_104V', lf.Type.STRING).
      addColumn('Kosten_Material_105V', lf.Type.STRING).
      addColumn('Einstandspreis_106V', lf.Type.STRING).
      addColumn('Kosten_Arbeit_107V', lf.Type.STRING).
      addColumn('Kosten_Ressourcen_108V', lf.Type.STRING).
      addColumn('Sonstige_Kosten_109V', lf.Type.STRING).
      addColumn('GesamtkostenProStk_110V', lf.Type.STRING).
      addColumn('Gemeinkostenanteil_126', lf.Type.STRING).
      addColumn('ArtikelgruppeName', lf.Type.STRING).
      addPrimaryKey(['idAtClient'], true).
      addIndex('idxUuid', ['uuid']).
      //addIndex('idxIdAtServer', ['idAtServer'], false, lf.Order.ASC).
      addNullable(['Artikelnr_5', 'EAN_Code_8', 'uuidArticlegroup', 'Artikelgruppe_24', 'Bezeichnung_6', 'Gesamtsteuersatz_63', 'ArtikelgruppeName', 'Kalk_EK_Preis_per_Stk_103V', 'Bezugskosten_104V', 'Kosten_Material_105V', 'Kosten_Arbeit_107V', 'Kosten_Ressourcen_108V', 'Sonstige_Kosten_109V', 'Gemeinkostenanteil_126', 'uuidTaxRate']);
    log("created table " + dataModels.Artikel.table);


    schemaBuilder.createTable(dataModels.Aufgabe.table).
      addColumn('idAtClient', lf.Type.INTEGER).
      //addColumn('idAtServer', lf.Type.INTEGER).
      addColumn('uuid', lf.Type.STRING).
      addColumn('deleted', lf.Type.BOOLEAN).
      addColumn('Titel_5', lf.Type.STRING).
      addColumn('DatumFaellig_7', lf.Type.DATE_TIME).
      //addColumn('idTaskCategoryAtClient_9999', lf.Type.INTEGER).	// id or category at client
      //addColumn('Kategorie_9', lf.Type.INTEGER).					// id of category at server
      addColumn('uuidTaskCategory', lf.Type.STRING).
      addColumn('Prioritaet_10', lf.Type.INTEGER).
      addColumn('Beschreibung_11', lf.Type.STRING).
      addColumn('erledigt_17', lf.Type.INTEGER).
      addColumn('erledigtAm_18', lf.Type.DATE_TIME).
      addPrimaryKey(['idAtClient'], true).
      addIndex('idxUuid', ['uuid']).
      addNullable(['Titel_5', 'Beschreibung_11', 'uuidTaskCategory']);
    log("created table " + dataModels.Aufgabe.table);


    schemaBuilder.createTable(dataModels.Auftragsstatus.table).
      addColumn('idAtClient', lf.Type.INTEGER).
      addColumn('uuid', lf.Type.STRING).
      addColumn('deleted', lf.Type.BOOLEAN).
      addColumn('Bezeichnung_5', lf.Type.STRING).
      addPrimaryKey(['idAtClient'], true).
      addIndex('idxUuid', ['uuid']).
      addNullable(['Bezeichnung_5']);
    log("created table " + dataModels.Auftragsstatus.table);


    schemaBuilder.createTable(dataModels.Zahlungsart.table).
      addColumn('idAtClient', lf.Type.INTEGER).
      addColumn('uuid', lf.Type.STRING).
      addColumn('deleted', lf.Type.BOOLEAN).
      addColumn('active', lf.Type.INTEGER).
      addColumn('Bezeichnung_5', lf.Type.STRING).
      addPrimaryKey(['idAtClient'], true).
      addIndex('idxUuid', ['uuid']).
      addNullable(['Bezeichnung_5']);
    log("created table " + dataModels.Zahlungsart.table);


    schemaBuilder.createTable(dataModels.Zahlungsziel.table).
      addColumn('idAtClient', lf.Type.INTEGER).
      //addColumn('idAtServer', lf.Type.INTEGER).
      addColumn('uuid', lf.Type.STRING).
      addColumn('deleted', lf.Type.BOOLEAN).
      addColumn('active', lf.Type.INTEGER).
      addColumn('Bezeichnung_5', lf.Type.STRING).
      addPrimaryKey(['idAtClient'], true).
      addIndex('idxUuid', ['uuid']).
      addNullable(['Bezeichnung_5']);
    log("created table " + dataModels.Zahlungsziel.table);


    schemaBuilder.createTable(dataModels.Preisliste.table).
      addColumn('idAtClient', lf.Type.INTEGER).
      //addColumn('idAtServer', lf.Type.INTEGER).
      addColumn('uuid', lf.Type.STRING).
      addColumn('deleted', lf.Type.BOOLEAN).
      addColumn('active', lf.Type.INTEGER).
      addColumn('Bezeichnung_5', lf.Type.STRING).
      addPrimaryKey(['idAtClient'], true).
      addIndex('idxUuid', ['uuid']).
      addNullable(['Bezeichnung_5']);
    log("created table " + dataModels.Preisliste.table);


    schemaBuilder.createTable(dataModels.Kontaktart.table).
      addColumn('idAtClient', lf.Type.INTEGER).
      //addColumn('idAtServer', lf.Type.INTEGER).
      addColumn('uuid', lf.Type.STRING).
      addColumn('deleted', lf.Type.BOOLEAN).
      addColumn('Bezeichnung_5', lf.Type.STRING).
      addPrimaryKey(['idAtClient'], true).
      addIndex('idxUuid', ['uuid']).
      addNullable(['Bezeichnung_5']);
    log("created table " + dataModels.Kontaktart.table);


    schemaBuilder.createTable(dataModels.Stornierungsgrund.table).
      addColumn('idAtClient', lf.Type.INTEGER).
      //addColumn('idAtServer', lf.Type.INTEGER).
      addColumn('uuid', lf.Type.STRING).
      addColumn('deleted', lf.Type.BOOLEAN).
      addColumn('Bezeichnung_5', lf.Type.STRING).
      addPrimaryKey(['idAtClient'], true).
      addIndex('idxUuid', ['uuid']).
      addNullable(['Bezeichnung_5']);
    log("created table " + dataModels.Stornierungsgrund.table);


    schemaBuilder.createTable(dataModels.Aufgabenkategorie.table).
      addColumn('idAtClient', lf.Type.INTEGER).
      //addColumn('idAtServer', lf.Type.INTEGER).
      addColumn('uuid', lf.Type.STRING).
      addColumn('deleted', lf.Type.BOOLEAN).
      addColumn('Bezeichnung_5', lf.Type.STRING).
      addPrimaryKey(['idAtClient'], true).
      addIndex('idxUuid', ['uuid']).
      addNullable(['Bezeichnung_5']);
    log("created table " + dataModels.Aufgabenkategorie.table);


    schemaBuilder.createTable(dataModels.Geschlecht.table).
      addColumn('idAtClient', lf.Type.INTEGER).
      //addColumn('idAtServer', lf.Type.INTEGER).
      addColumn('uuid', lf.Type.STRING).
      addColumn('deleted', lf.Type.BOOLEAN).
      addColumn('Kurzanrede_6', lf.Type.STRING).
      addPrimaryKey(['idAtClient'], true).
      addIndex('idxUuid', ['uuid']).
      //addPrimaryKey(['uuid']).
      addNullable(['Kurzanrede_6']);
    log("created table " + dataModels.Geschlecht.table);


    schemaBuilder.createTable(dataModels.Land.table).
      addColumn('idAtClient', lf.Type.INTEGER).
      //addColumn('idAtServer', lf.Type.INTEGER).
      addColumn('uuid', lf.Type.STRING).
      addColumn('deleted', lf.Type.BOOLEAN).
      addColumn('Bezeichnung_5', lf.Type.STRING).
      addPrimaryKey(['idAtClient'], true).
      addIndex('idxUuid', ['uuid']).
      addNullable(['Bezeichnung_5']);
    log("created table " + dataModels.Land.table);

    schemaBuilder.createTable(dataModels.Kundenkategorie.table).
      addColumn('idAtClient', lf.Type.INTEGER).
      //addColumn('idAtServer', lf.Type.INTEGER).
      addColumn('uuid', lf.Type.STRING).
      addColumn('deleted', lf.Type.BOOLEAN).
      addColumn('Bezeichnung_5', lf.Type.STRING).
      addPrimaryKey(['idAtClient'], true).
      addIndex('idxUuid', ['uuid']).
      addNullable(['Bezeichnung_5']);
    log("created table " + dataModels.Kundenkategorie.table);

    schemaBuilder.createTable(dataModels.Angebot.table).
      addColumn('idAtClient', lf.Type.INTEGER).
      //addColumn('idAtServer', lf.Type.INTEGER).
      addColumn('uuid', lf.Type.STRING).
      addColumn('deleted', lf.Type.BOOLEAN).
      addColumn('Nummer_9999', lf.Type.STRING).
      addColumn('Nummer_5', lf.Type.STRING).
      addColumn('Datum_6', lf.Type.DATE_TIME).
      //addColumn('idCustomerAtClient', lf.Type.INTEGER).	// id customer at client
      //addColumn('Kunde_9', lf.Type.INTEGER).				// id customer at server
      addColumn('uuidCustomer', lf.Type.STRING).
      addColumn('Name_15', lf.Type.STRING).
      addColumn('Vorname_19', lf.Type.STRING).
      addColumn('Strasse_21', lf.Type.STRING).
      addColumn('PLZ_22', lf.Type.STRING).
      addColumn('Ort_23', lf.Type.STRING).
      addColumn('Bemerkung_48', lf.Type.STRING).
      //addColumn('idOrderAtClient_9999', lf.Type.INTEGER).	// id order at client
      //addColumn('Auftrag_50', lf.Type.INTEGER).			// id order at server
      addColumn('uuidOrder', lf.Type.STRING).
      //addColumn('idPaymentTypeAtClient_9999', lf.Type.INTEGER).	// id paymenttype at client
      //addColumn('Zahlungsart_60', lf.Type.INTEGER).				// id paymenttype at server
      addColumn('uuidPaymentType', lf.Type.STRING).
      addColumn('Rabatt_68', lf.Type.NUMBER).
      addColumn('Ust_befreit_79', lf.Type.BOOLEAN).
      //addColumn('seqPosIdsAtClient_9999', lf.Type.STRING).	// sequence of positions at client
      //addColumn('Positionen_82', lf.Type.STRING).				// sequence of positions at server
      addColumn('uuidsSalePositions', lf.Type.STRING).
      addColumn('PositionssummeNetto_83V', lf.Type.STRING).
      addColumn('PositionssummeUst_84V', lf.Type.STRING).
      addColumn('PositionssummeBrutto_87V', lf.Type.STRING).
      addColumn('SummeNetto_93V', lf.Type.STRING).
      addColumn('SummeUst_94V', lf.Type.STRING).
      addColumn('SummeBrutto_97V', lf.Type.STRING).
      addColumn('DB1_112V', lf.Type.STRING).
      addColumn('DB2_113V', lf.Type.STRING).
      addColumn('Einstandspreis_110V', lf.Type.STRING).
      addColumn('Gesamtkosten_111V', lf.Type.STRING).
      addColumn('AnteilDB1_114', lf.Type.STRING).
      addColumn('AnteilDB2_115', lf.Type.STRING).
      addColumn('Summenrabatt_116V', lf.Type.STRING).
      addColumn('GewichtKgBrutto_117', lf.Type.NUMBER).
      //addColumn('idCancellationReasonAtClient_9999', lf.Type.INTEGER).	// idCancellationReason at client
      //addColumn('Stornierungsgrund_149', lf.Type.INTEGER).				// idCancellationReason at server
      addColumn('uuidCancellationReason', lf.Type.STRING).
      addColumn('EnquiryDate_171', lf.Type.DATE_TIME).
      // addColumn('isOpen_9999', lf.Type.BOOLEAN). // 9999 => not synchronized
      // addColumn('UnterschriftArray_9999', lf.Type.OBJECT). // 9999 => not synchronized
      addColumn('UnterschriftBild_0000', lf.Type.STRING). // 0000 => special treatment at server
      addPrimaryKey(['idAtClient'], true).
      addIndex('idxUuid', ['uuid']).
      //addIndex('idxIdOrderAtClient_9999', ['idOrderAtClient_9999'], false, lf.Order.ASC).
      addNullable(['Nummer_9999', 'Nummer_5', 'Datum_6', 'Name_15', 'Vorname_19',
        'Strasse_21', 'PLZ_22', 'Ort_23', 'Bemerkung_48', 'Rabatt_68',
        'Ust_befreit_79',
        'PositionssummeNetto_83V', 'PositionssummeUst_84V', 'PositionssummeBrutto_87V',
        'SummeNetto_93V', 'SummeUst_94V', 'SummeBrutto_97V', 'Gesamtkosten_111V', 'DB1_112V', 'DB2_113V',
        'AnteilDB1_114', 'AnteilDB2_115', 'Summenrabatt_116V',
        'GewichtKgBrutto_117',
        'EnquiryDate_171', 'UnterschriftBild_0000',
        'uuidCustomer', 'uuidCancellationReason', 'uuidPaymentType', 'uuidOrder']);
    log("created table " + dataModels.Angebot.table);

    schemaBuilder.createTable(dataModels.Angebotspositionen.table).
      addColumn('idAtClient', lf.Type.INTEGER).
      //addColumn('idAtServer', lf.Type.INTEGER).
      addColumn('uuid', lf.Type.STRING).
      addColumn('deleted', lf.Type.BOOLEAN).
      //addColumn('idParentAtClient_9999', lf.Type.INTEGER). 	// id at client; 9999 => not synchronized
      //addColumn('parent_link', lf.Type.INTEGER).				// id at server
      addColumn('uuidParent', lf.Type.STRING).
      addColumn('Positionstyp_3', lf.Type.INTEGER).
      //addColumn('idArticleAtClient_9999', lf.Type.INTEGER). 	// id at client; 9999 => not synchronized
      //addColumn('Artikel_4', lf.Type.INTEGER).				// id at server
      addColumn('uuidArticle', lf.Type.STRING).
      addColumn('BezeichnungText_5', lf.Type.STRING).
      addColumn('Menge_6', lf.Type.NUMBER).
      addColumn('EinzelpreisNetto_10V', lf.Type.STRING).
      addColumn('NettosummeArtikel_11V', lf.Type.STRING).
      //addColumn('Steuersatz_12', lf.Type.INTEGER).
      addColumn('uuidTaxRate', lf.Type.STRING).
      addColumn('UmsatzsteuerGesamt_13V', lf.Type.STRING).
      addColumn('BruttosummeGesamt_16V', lf.Type.STRING).
      addColumn('NettosummeGesamt_17V', lf.Type.STRING).
      addColumn('Beschreibung1_19', lf.Type.STRING).
      addColumn('Beschreibung2_20', lf.Type.STRING).
      addColumn('GewichtKgBrutto_25', lf.Type.NUMBER).
      addColumn('GesamtEinstandspreis_27V', lf.Type.STRING).
      addColumn('Gesamtkosten_28V', lf.Type.STRING).
      addColumn('DB1_29V', lf.Type.STRING).
      addColumn('DB2_30V', lf.Type.STRING).
      addColumn('EinzelEinkaufspreis_32V', lf.Type.STRING).
      addColumn('Provisionsstufe_37', lf.Type.NUMBER).
      addColumn('Artikelgruppe_41', lf.Type.INTEGER).
      addColumn('RabattArtikel_42', lf.Type.NUMBER).
      addColumn('DB2Percent_73', lf.Type.NUMBER).
      addColumn('EinzelpreisBrutto_88V', lf.Type.STRING).
      addColumn('GewichtKgEinheit_9999', lf.Type.NUMBER). // 9999 => not synchronized
      addColumn('Gesamtsteuersatz_9999', lf.Type.NUMBER). // 9999 => not synchronized
      addColumn('EinzelEinstandspreis_9999V', lf.Type.STRING).
      addPrimaryKey(['idAtClient'], true).
      addIndex('idxUuid', ['uuid']).
      addNullable(['Positionstyp_3', 'BezeichnungText_5', 'Menge_6',
        'GewichtKgBrutto_25', 'Gesamtkosten_28V', 'DB1_29V', 'DB2_30V', 'EinzelEinstandspreis_9999V', 'DB2Percent_73',
        'EinzelpreisBrutto_88V', 'Gesamtsteuersatz_9999', 'GewichtKgEinheit_9999', 'RabattArtikel_42',
        'uuidArticle', 'uuidTaxRate']);
    log("created table " + dataModels.Angebotspositionen.table);

    schemaBuilder.createTable(dataModels.Auftrag.table).
      addColumn('idAtClient', lf.Type.INTEGER).
      //addColumn('idAtServer', lf.Type.INTEGER).
      addColumn('uuid', lf.Type.STRING).
      addColumn('deleted', lf.Type.BOOLEAN).
      addColumn('Nummer_9999', lf.Type.STRING).
      addColumn('Nummer_5', lf.Type.STRING).
      addColumn('Datum_6', lf.Type.DATE_TIME).
      //addColumn('idCustomerAtClient', lf.Type.INTEGER).	// id customer at client
      //addColumn('Kunde_9', lf.Type.INTEGER).				// id customer at server
      addColumn('uuidCustomer', lf.Type.STRING).
      addColumn('Name_15', lf.Type.STRING).
      addColumn('Vorname_19', lf.Type.STRING).
      addColumn('Strasse_21', lf.Type.STRING).
      addColumn('PLZ_22', lf.Type.STRING).
      addColumn('Ort_23', lf.Type.STRING).
      addColumn('Bemerkung_48', lf.Type.STRING).
      //addColumn('idOfferAtClient_9999', lf.Type.INTEGER).	// id offer at client
      //addColumn('Angebot_49', lf.Type.INTEGER).			// id offer at server
      addColumn('uuidOffer', lf.Type.STRING).
      //addColumn('idInvoiceAtClient_9999', lf.Type.INTEGER).	// id invoice at client
      //addColumn('Rechnung_52', lf.Type.INTEGER).				// id invoice at server
      addColumn('uuidInvoice', lf.Type.STRING).
      //addColumn('idPaymentTypeAtClient_9999', lf.Type.INTEGER).	// id paymenttype at client
      //addColumn('Zahlungsart_60', lf.Type.INTEGER).				// id paymenttype at server
      addColumn('uuidPaymentType', lf.Type.STRING).
      addColumn('Rabatt_68', lf.Type.NUMBER).
      addColumn('Ust_befreit_79', lf.Type.BOOLEAN).
      //addColumn('seqPosIdsAtClient_9999', lf.Type.STRING).	// sequence of positions at client
      //addColumn('Positionen_82', lf.Type.STRING).				// sequence of positions at server
      addColumn('uuidsSalePositions', lf.Type.STRING).
      addColumn('PositionssummeNetto_83V', lf.Type.STRING).
      addColumn('PositionssummeUst_84V', lf.Type.STRING).
      addColumn('PositionssummeBrutto_87V', lf.Type.STRING).
      addColumn('SummeNetto_93V', lf.Type.STRING).
      addColumn('SummeUst_94V', lf.Type.STRING).
      addColumn('SummeBrutto_97V', lf.Type.STRING).
      addColumn('Einstandspreis_110V', lf.Type.STRING).
      addColumn('Gesamtkosten_111V', lf.Type.STRING).
      addColumn('DB1_112V', lf.Type.STRING).
      addColumn('DB2_113V', lf.Type.STRING).
      addColumn('AnteilDB1_114', lf.Type.STRING).
      addColumn('AnteilDB2_115', lf.Type.STRING).
      addColumn('Summenrabatt_116V', lf.Type.STRING).
      addColumn('GewichtKg_117', lf.Type.NUMBER).
      addColumn('gebucht_154', lf.Type.BOOLEAN).
      //addColumn('idCancellationReasonAtClient_9999', lf.Type.INTEGER).	// idCancellationReason at client
      //addColumn('Stornierungsgrund_149', lf.Type.INTEGER).				// idCancellationReason at server
      addColumn('uuidCancellationReason', lf.Type.STRING).
      //addColumn('idStateOrderAtClient_9999', lf.Type.INTEGER).	// state order at client
      //addColumn('Status_159', lf.Type.INTEGER).					// state order at server
      addColumn('uuidStateOrder', lf.Type.STRING).
      // addColumn('isOpen_9999', lf.Type.BOOLEAN). 	// 9999 => not synchronized
      // addColumn('UnterschriftArray_9999', lf.Type.OBJECT). // 9999 => not synchronized
      addColumn('UnterschriftBild_0000', lf.Type.STRING). // 0000 => special treatment at server
      addPrimaryKey(['idAtClient'], true).
      addIndex('idxUuid', ['uuid']).
      //addIndex('idxIdOfferAtClient_9999', ['idOfferAtClient_9999'], false, lf.Order.ASC).
      addNullable(['Nummer_9999', 'Nummer_5', 'Datum_6', 'Name_15', 'Vorname_19', 'Strasse_21', 'PLZ_22', 'Ort_23', 'Bemerkung_48',
        'Rabatt_68', 'Ust_befreit_79',
        'PositionssummeNetto_83V', 'PositionssummeUst_84V', 'PositionssummeBrutto_87V',
        'SummeNetto_93V', 'SummeUst_94V', 'SummeBrutto_97V', 'Gesamtkosten_111V', 'DB1_112V', 'DB2_113V',
        'AnteilDB1_114', 'AnteilDB2_115', 'Summenrabatt_116V',
        'GewichtKg_117',
        'UnterschriftBild_0000',
        'uuidCustomer', 'uuidOffer', 'uuidPaymentType', 'uuidCancellationReason',
        'uuidStateOrder', 'uuidInvoice']);
    log("created table " + dataModels.Auftrag.table);

    schemaBuilder.createTable(dataModels.Auftragspositionen.table).
      addColumn('idAtClient', lf.Type.INTEGER).
      //addColumn('idAtServer', lf.Type.INTEGER).
      addColumn('uuid', lf.Type.STRING).
      addColumn('deleted', lf.Type.BOOLEAN).
      //addColumn('idParentAtClient_9999', lf.Type.INTEGER).	// id at client; 9999 => not synchronized
      //addColumn('parent_link', lf.Type.INTEGER).				// id at server
      addColumn('uuidParent', lf.Type.STRING).
      addColumn('Positionstyp_3', lf.Type.INTEGER).
      //addColumn('idArticleAtClient_9999', lf.Type.INTEGER).	// id at client; 9999 => not synchronized
      //addColumn('Artikel_4', lf.Type.INTEGER).				// id at server
      addColumn('uuidArticle', lf.Type.STRING).
      addColumn('BezeichnungText_5', lf.Type.STRING).
      addColumn('Menge_6', lf.Type.NUMBER).
      addColumn('EinzelpreisNetto_10V', lf.Type.STRING).
      addColumn('NettosummeArtikel_11V', lf.Type.STRING).
      //addColumn('Steuersatz_12', lf.Type.INTEGER).
      addColumn('uuidTaxRate', lf.Type.STRING).
      addColumn('UmsatzsteuerGesamt_13V', lf.Type.STRING).
      addColumn('BruttosummeGesamt_16V', lf.Type.STRING).
      addColumn('NettosummeGesamt_17V', lf.Type.STRING).
      addColumn('Beschreibung1_19', lf.Type.STRING).
      addColumn('Beschreibung2_20', lf.Type.STRING).
      addColumn('GewichtKg_25', lf.Type.NUMBER).
      addColumn('GesamtEinstandspreis_27V', lf.Type.STRING).
      addColumn('Gesamtkosten_28V', lf.Type.STRING).
      addColumn('DB1_29V', lf.Type.STRING).
      addColumn('DB2_30V', lf.Type.STRING).
      addColumn('EinzelEinkaufspreis_32V', lf.Type.STRING).
      addColumn('Provisionsstufe_37', lf.Type.NUMBER).
      addColumn('Artikelgruppe_41', lf.Type.INTEGER).
      addColumn('RabattArtikel_42', lf.Type.NUMBER).
      addColumn('DB2Percent_73', lf.Type.NUMBER).
      addColumn('EinzelpreisBrutto_88V', lf.Type.STRING).
      addColumn('GewichtKgEinheit_9999', lf.Type.NUMBER). // 9999 => not synchronized
      addColumn('Gesamtsteuersatz_9999', lf.Type.NUMBER). // 9999 => not synchronized
      addColumn('EinzelEinstandspreis_9999V', lf.Type.STRING).
      addPrimaryKey(['idAtClient'], true).
      addIndex('idxUuid', ['uuid']).
      addNullable(['Positionstyp_3', 'BezeichnungText_5', 'Menge_6',
        'GewichtKg_25', 'Gesamtkosten_28V', 'DB1_29V', 'DB2_30V', 'EinzelEinstandspreis_9999V', 'DB2Percent_73',
        'EinzelpreisBrutto_88V', 'Gesamtsteuersatz_9999', 'GewichtKgEinheit_9999', 'RabattArtikel_42', 'uuidArticle',
        'uuidArticle', 'uuidTaxRate']);
    log("created table " + dataModels.Auftragspositionen.table);

    schemaBuilder.createTable(dataModels.Rechnung.table).
      addColumn('idAtClient', lf.Type.INTEGER).
      //addColumn('idAtServer', lf.Type.INTEGER).
      addColumn('uuid', lf.Type.STRING).
      addColumn('deleted', lf.Type.BOOLEAN).
      addColumn('Nummer_9999', lf.Type.STRING).
      addColumn('Nummer_5', lf.Type.STRING).
      addColumn('Datum_6', lf.Type.DATE_TIME).
      //addColumn('idCustomerAtClient', lf.Type.INTEGER).	// id customer at client
      //addColumn('Kunde_9', lf.Type.INTEGER).				// id customer at server
      addColumn('uuidCustomer', lf.Type.STRING).
      addColumn('Name_15', lf.Type.STRING).
      addColumn('Vorname_19', lf.Type.STRING).
      addColumn('Strasse_21', lf.Type.STRING).
      addColumn('PLZ_22', lf.Type.STRING).
      addColumn('Ort_23', lf.Type.STRING).
      addColumn('Bemerkung_48', lf.Type.STRING).
      //addColumn('idOrderAtClient_9999', lf.Type.INTEGER).	// id order at client
      //addColumn('Auftrag_50', lf.Type.INTEGER).			// id order at server
      addColumn('uuidOrder', lf.Type.STRING).
      //addColumn('idPaymentTypeAtClient_9999', lf.Type.INTEGER).	// id paymenttype at client
      //addColumn('Zahlungsart_60', lf.Type.INTEGER).				// id paymenttype at server
      addColumn('uuidPaymentType', lf.Type.STRING).
      addColumn('Rabatt_68', lf.Type.NUMBER).
      addColumn('Ust_befreit_79', lf.Type.BOOLEAN).
      //addColumn('seqPosIdsAtClient_9999', lf.Type.STRING).	// sequence of positions at client
      //addColumn('Positionen_82', lf.Type.STRING).				// sequence of positions at server
      addColumn('uuidsSalePositions', lf.Type.STRING).
      addColumn('PositionssummeNetto_83V', lf.Type.STRING).
      addColumn('PositionssummeUst_84V', lf.Type.STRING).
      addColumn('PositionssummeBrutto_87V', lf.Type.STRING).
      addColumn('SummeNetto_93V', lf.Type.STRING).
      addColumn('SummeUst_94V', lf.Type.STRING).
      addColumn('SummeBrutto_97V', lf.Type.STRING).
      addColumn('Einstandspreis_110V', lf.Type.STRING).
      addColumn('Gesamtkosten_111V', lf.Type.STRING).
      addColumn('DB1_112V', lf.Type.STRING).
      addColumn('DB2_113V', lf.Type.STRING).
      addColumn('AnteilDB1_114', lf.Type.STRING).
      addColumn('AnteilDB2_115', lf.Type.STRING).
      addColumn('Summenrabatt_116V', lf.Type.STRING).
      addColumn('GewichtKg_117', lf.Type.NUMBER).
      //addColumn('idCancellationReasonAtClient_9999', lf.Type.INTEGER).	// idCancellationReason at client
      //addColumn('Stornierungsgrund_149', lf.Type.INTEGER).				// idCancellationReason at server
      addColumn('uuidCancellationReason', lf.Type.STRING).
      // addColumn('isOpen_9999', lf.Type.BOOLEAN). 	// 9999 => not synchronized
      // addColumn('UnterschriftArray_9999', lf.Type.OBJECT). // 9999 => not synchronized
      //addColumn('UnterschriftBild_0000', lf.Type.STRING). // 0000 => special treatment at server
      addPrimaryKey(['idAtClient'], true).
      addIndex('idxUuid', ['uuid']).
      //addIndex('idxIdOrderAtClient_9999', ['idOrderAtClient_9999'], false, lf.Order.ASC).
      addNullable(['Nummer_9999', 'Nummer_5', 'Datum_6', 'Name_15', 'Vorname_19', 'Strasse_21', 'PLZ_22', 'Ort_23', 'Bemerkung_48',
        'Rabatt_68', 'Ust_befreit_79',
        'PositionssummeNetto_83V', 'PositionssummeUst_84V', 'PositionssummeBrutto_87V',
        'SummeNetto_93V', 'SummeUst_94V', 'SummeBrutto_97V', 'Gesamtkosten_111V', 'DB1_112V', 'DB2_113V',
        'AnteilDB1_114', 'AnteilDB2_115', 'Summenrabatt_116V',
        'GewichtKg_117',
        'uuidCustomer'
        //'Status_159',
        //'UnterschriftBild_0000'
      ]);
    log("created table " + dataModels.Rechnung.table);

    schemaBuilder.createTable(dataModels.Rechnungspositionen.table).
      addColumn('idAtClient', lf.Type.INTEGER).
      //addColumn('idAtServer', lf.Type.INTEGER).
      addColumn('uuid', lf.Type.STRING).
      addColumn('deleted', lf.Type.BOOLEAN).
      //addColumn('idParentAtClient_9999', lf.Type.INTEGER).	// id at client; 9999 => not synchronized
      //addColumn('parent_link', lf.Type.INTEGER).				// id at server
      addColumn('uuidParent', lf.Type.STRING).
      addColumn('Positionstyp_3', lf.Type.INTEGER).
      //addColumn('idArticleAtClient_9999', lf.Type.INTEGER).	// id at client; 9999 => not synchronized
      //addColumn('Artikel_4', lf.Type.INTEGER).				// id at server
      addColumn('uuidArticle', lf.Type.STRING).
      addColumn('BezeichnungText_5', lf.Type.STRING).
      addColumn('Menge_6', lf.Type.NUMBER).
      addColumn('EinzelpreisNetto_10V', lf.Type.STRING).
      addColumn('NettosummeArtikel_11V', lf.Type.STRING).
      //addColumn('Steuersatz_12', lf.Type.INTEGER).
      addColumn('uuidTaxRate', lf.Type.STRING).
      addColumn('UmsatzsteuerGesamt_13V', lf.Type.STRING).
      addColumn('BruttosummeGesamt_16V', lf.Type.STRING).
      addColumn('NettosummeGesamt_17V', lf.Type.STRING).
      addColumn('Beschreibung1_19', lf.Type.STRING).
      addColumn('Beschreibung2_20', lf.Type.STRING).
      addColumn('GewichtKg_25', lf.Type.NUMBER).
      addColumn('GesamtEinstandspreis_27V', lf.Type.STRING).
      addColumn('Gesamtkosten_28V', lf.Type.STRING).
      addColumn('DB1_29V', lf.Type.STRING).
      addColumn('DB2_30V', lf.Type.STRING).
      addColumn('EinzelEinkaufspreis_32V', lf.Type.STRING).
      addColumn('Provisionsstufe_37', lf.Type.NUMBER).
      addColumn('Artikelgruppe_41', lf.Type.INTEGER).
      addColumn('RabattArtikel_42', lf.Type.NUMBER).
      addColumn('DB2Percent_73', lf.Type.NUMBER).
      addColumn('EinzelpreisBrutto_88V', lf.Type.STRING).
      addColumn('GewichtKgEinheit_9999', lf.Type.NUMBER). // 9999 => not synchronized
      addColumn('Gesamtsteuersatz_9999', lf.Type.NUMBER). // 9999 => not synchronized
      addColumn('EinzelEinstandspreis_9999V', lf.Type.STRING).
      addPrimaryKey(['idAtClient'], true).
      addIndex('idxUuid', ['uuid']).
      addNullable(['Positionstyp_3', 'BezeichnungText_5', 'Menge_6',
        'GewichtKg_25', 'Gesamtkosten_28V', 'DB1_29V', 'DB2_30V', 'EinzelEinstandspreis_9999V', 'DB2Percent_73',
        'EinzelpreisBrutto_88V', 'Gesamtsteuersatz_9999', 'GewichtKgEinheit_9999', 'RabattArtikel_42',
        'uuidArticle', 'uuidTaxRate']);
    log("created table " + dataModels.Rechnungspositionen.table);

    schemaBuilder.createTable(dataModels.Kundenkontakt.table).
      addColumn('idAtClient', lf.Type.INTEGER).
      //addColumn('idAtServer', lf.Type.INTEGER).
      addColumn('uuid', lf.Type.STRING).
      addColumn('deleted', lf.Type.BOOLEAN).
      addColumn('Datum_5', lf.Type.STRING).
      //addColumn('idCustomerAtClient', lf.Type.INTEGER).	// id customer at client
      //addColumn('Kunde_6', lf.Type.INTEGER).				// id customer at server
      addColumn('uuidCustomer', lf.Type.STRING).
      //addColumn('idContactTypeAtClient_9999', lf.Type.INTEGER).	// id contact type at client
      //addColumn('Kontaktart_7', lf.Type.STRING).					// id contact type at server
      addColumn('uuidContactType', lf.Type.STRING).
      addColumn('Thema_16', lf.Type.STRING).
      addColumn('Zusagen_17', lf.Type.STRING).
      addColumn('ZuTun_18', lf.Type.STRING).
      addPrimaryKey(['idAtClient'], true).
      addIndex('idxUuid', ['uuid']).
      addNullable(['uuidCustomer', 'uuidContactType', 'Thema_16', 'Zusagen_17', 'ZuTun_18']);
    log("created table " + dataModels.Kundenkontakt.table);

    schemaBuilder.createTable(dataModels.Ansprechpartner.table).
      addColumn('idAtClient', lf.Type.INTEGER).
      //addColumn('idAtServer', lf.Type.INTEGER).
      addColumn('uuid', lf.Type.STRING).
      addColumn('deleted', lf.Type.BOOLEAN).
      addColumn('Name_5', lf.Type.STRING).
      addColumn('Vorname_6', lf.Type.STRING).
      addColumn('Titel_7', lf.Type.STRING).
      //addColumn('idSexAtClient_9999', lf.Type.INTEGER).	// id sex at client
      //addColumn('Geschlecht_8', lf.Type.INTEGER).			// id sex at server
      addColumn('uuidSex', lf.Type.STRING).
      addColumn('Position_9', lf.Type.STRING).
      addColumn('Tel1_10', lf.Type.STRING).
      addColumn('Tel2_11', lf.Type.STRING).
      addColumn('Fax_12', lf.Type.STRING).
      addColumn('EMail_13', lf.Type.STRING).
      addColumn('Bemerkung_15', lf.Type.STRING).
      //addColumn('idCustomerAtClient', lf.Type.INTEGER).	// id customer at client
      //addColumn('Kunde_28', lf.Type.INTEGER).				// id customer at server
      addColumn('uuidCustomer', lf.Type.STRING).
      addPrimaryKey(['idAtClient'], true).
      addIndex('idxUuid', ['uuid']).
      addNullable(['uuidSex', 'uuidCustomer', 'Titel_7', 'Fax_12', 'Name_5', 'Vorname_6', 'Position_9', 'Bemerkung_15', 'EMail_13', 'Tel1_10', 'Tel2_11']);
    log("created table " + dataModels.Ansprechpartner.table);

    schemaBuilder.createTable(dataModels.Termin.table).
      addColumn('idAtClient', lf.Type.INTEGER).
      //addColumn('idAtServer', lf.Type.INTEGER).
      addColumn('uuid', lf.Type.STRING).
      addColumn('deleted', lf.Type.BOOLEAN).
      addColumn('Beginn_5', lf.Type.DATE_TIME).
      addColumn('Ende_6', lf.Type.DATE_TIME).
      addColumn('Dauer_7', lf.Type.NUMBER).
      addColumn('Titel_8', lf.Type.STRING).
      addColumn('Ort_9', lf.Type.STRING).
      addColumn('BemerkungAnfahrt_15', lf.Type.STRING).
      //addColumn('idCustomerAtClient', lf.Type.INTEGER).	// id customer at client
      //addColumn('Kunde_29', lf.Type.STRING).				// id customer at server
      addColumn('uuidCustomer', lf.Type.STRING).
      addPrimaryKey(['idAtClient'], true).
      addIndex('idxUuid', ['uuid']).
      addNullable(['Titel_8', 'Ort_9', 'BemerkungAnfahrt_15', 'uuidCustomer']);
    log("created table " + dataModels.Termin.table);

    schemaBuilder.createTable(dataModels.Projektzeiterfassung.table).
      addColumn('idAtClient', lf.Type.INTEGER).
      //addColumn('idAtServer', lf.Type.INTEGER).
      addColumn('uuid', lf.Type.STRING).
      addColumn('deleted', lf.Type.BOOLEAN).
      addColumn('DatumZeitVon_5', lf.Type.DATE_TIME).
      addColumn('ZeitBis_6', lf.Type.DATE_TIME).
      addColumn('Dauer_7', lf.Type.NUMBER).
      //addColumn('idOrderAtClient_9999', lf.Type.INTEGER).	// id order at client
      //addColumn('Auftrag_13', lf.Type.INTEGER).			// id order at server
      addColumn('uuidOrder', lf.Type.STRING).
      //addColumn('idCustomerAtClient', lf.Type.INTEGER).	// id customer at client
      //addColumn('Kunde_14', lf.Type.INTEGER).				// id customer at server
      addColumn('uuidCustomer', lf.Type.STRING).
      addColumn('uuidMaintenance', lf.Type.STRING).
      addPrimaryKey(['idAtClient'], true).
      addIndex('idxUuid', ['uuid']).
      addNullable(['ZeitBis_6', 'uuidCustomer']);
    log("created table " + dataModels.Projektzeiterfassung.table);


    schemaBuilder.createTable('manipulations').
      addColumn('id', lf.Type.INTEGER).
      addColumn('type', lf.Type.STRING).
      addColumn('nameTable', lf.Type.STRING).
      //addColumn('idAtClient', lf.Type.INTEGER).
      addColumn('uuid', lf.Type.STRING).
      addColumn('nameColumn', lf.Type.STRING).
      addColumn('time', lf.Type.STRING).
      addPrimaryKey(['id'], true).
      addNullable(['nameColumn', 'time']);
    log("created table manipulations");


    schemaBuilder.createTable('utilityData').
      addColumn('key', lf.Type.STRING).
      addColumn('value', lf.Type.STRING).
      addPrimaryKey(['key'], false).
      addNullable(['value']);
    log("created table utilityData");


    schemaBuilder.createTable(dataModels.Firma.table).
      addColumn('idAtClient', lf.Type.INTEGER).
      //addColumn('idAtServer', lf.Type.INTEGER).
      addColumn('uuid', lf.Type.STRING).
      addColumn('Name', lf.Type.STRING).
      addColumn('Strasse', lf.Type.STRING).
      addColumn('PLZ', lf.Type.STRING).
      addColumn('Ort', lf.Type.STRING).
      addColumn('Telefon', lf.Type.STRING).
      addColumn('Fax', lf.Type.STRING).
      addColumn('E_mail', lf.Type.STRING).
      addColumn('Homepage', lf.Type.STRING).
      addPrimaryKey(['idAtClient'], true).
      addNullable(['Name']);
    log("created table company");

    schemaBuilder.createTable(dataModels.Gerät.table).
      addColumn('idAtClient', lf.Type.INTEGER).
      addColumn('uuid', lf.Type.STRING).
      addColumn('deleted', lf.Type.BOOLEAN).
      addColumn('Seriennummer_5', lf.Type.STRING).
      addColumn('uuidArticle', lf.Type.STRING).
      addColumn('Bezeichnung_6', lf.Type.STRING).
      addColumn('EAN_9', lf.Type.STRING).
      addColumn('uuidCustomer', lf.Type.STRING).
      addColumn('Strasse_16', lf.Type.STRING).
      addColumn('PLZ_17', lf.Type.STRING).
      addColumn('Ort_18', lf.Type.STRING).
      addColumn('Standortbeschreibung_20', lf.Type.STRING).
      addColumn('LetzterZaehlerstand_31', lf.Type.NUMBER).
      addColumn('uuidsImages', lf.Type.STRING).
      addPrimaryKey(['idAtClient'], true).
      addIndex('idxUuid', ['uuid']).
      addNullable(['Bezeichnung_6', 'Strasse_16', 'uuidsImages']);
    log("created table geraet");

    schemaBuilder.createTable(dataModels.Wartungsauftrag.table).
      addColumn('idAtClient', lf.Type.INTEGER).
      addColumn('uuid', lf.Type.STRING).
      addColumn('deleted', lf.Type.BOOLEAN).
      addColumn('WartungsNr_5', lf.Type.STRING).
      addColumn('Beginn_6', lf.Type.DATE_TIME).
      addColumn('uuidCustomer', lf.Type.STRING).
      //addColumn('Kategorie_9', lf.Type.INTEGER).
      addColumn('uuidMaintenanceCategory', lf.Type.STRING).
      addColumn('KategorieName', lf.Type.STRING).
      //addColumn('Status_11', lf.Type.INTEGER).
      addColumn('uuidMaintenanceStatus', lf.Type.STRING).
      addColumn('StatusName', lf.Type.STRING).
      addColumn('uuidArticle', lf.Type.STRING).
      addColumn('uuidDevice', lf.Type.STRING).
      addColumn('SerienNr_17', lf.Type.STRING).
      addColumn('Eigener', lf.Type.BOOLEAN).
      addColumn('FehlerbeschreibungKunde_21', lf.Type.STRING).
      addColumn('Bemerkung_22', lf.Type.STRING).
      addColumn('Abgeschlossen_59', lf.Type.BOOLEAN).
      addColumn('Diagnose_66', lf.Type.STRING).
      addColumn('uuidsImages', lf.Type.STRING).
      addColumn('uuidImageSignature', lf.Type.STRING).
      addColumn('Ende_77', lf.Type.DATE_TIME).
      addPrimaryKey(['idAtClient'], true).
      addIndex('idxUuid', ['uuid']).
      addNullable(['Bemerkung_22', 'Diagnose_66', 'uuidArticle']);
    log("created table wartungsauftrag");

    schemaBuilder.createTable(dataModels.Bilder.table).
      addColumn('idAtClient', lf.Type.INTEGER).
      addColumn('uuid', lf.Type.STRING).
      addColumn('uuidParent', lf.Type.STRING).
      addColumn('Name_5', lf.Type.STRING).
      addColumn('Daten_6', lf.Type.STRING).
      addColumn('Type_14', lf.Type.INTEGER).
      addColumn('IsLink_15', lf.Type.BOOLEAN).
      addPrimaryKey(['idAtClient'], true).
      addIndex('idxUuid', ['uuid']).
      addNullable([]);
    log("created table bilder");

    schemaBuilder.createTable(dataModels.Lagerbewegung.table).
      addColumn('idAtClient', lf.Type.INTEGER).
      addColumn('uuid', lf.Type.STRING).
      addColumn('deleted', lf.Type.BOOLEAN).
      addColumn('uuidArticle', lf.Type.STRING).
      addColumn('Bewegungsart_6', lf.Type.INTEGER).
      addColumn('Datum_7', lf.Type.DATE_TIME).
      addColumn('Menge_8', lf.Type.NUMBER).
      addColumn('uuidMaintenance', lf.Type.STRING).
      addPrimaryKey(['idAtClient'], true).
      addIndex('idxUuid', ['uuid']).
      addNullable([]);
    log("created table " + dataModels.Lagerbewegung.table);

    schemaBuilder.createTable(dataModels.WartungsauftragMaterial.table).
      addColumn('idAtClient', lf.Type.INTEGER).
      addColumn('uuid', lf.Type.STRING).
      addColumn('deleted', lf.Type.BOOLEAN).
      addColumn('uuidPart', lf.Type.STRING).
      addColumn('Menge_6', lf.Type.NUMBER).
      addColumn('Einzelpreis_7V', lf.Type.NUMBER).
      addColumn('Gesamtpreis_8V', lf.Type.NUMBER).
      addColumn('uuidMaintenance', lf.Type.STRING).
      addPrimaryKey(['idAtClient'], true).
      addIndex('idxUuid', ['uuid']).
      addNullable([]);
    log("created table " + dataModels.WartungsauftragMaterial.table);

    schemaBuilder.createTable(dataModels.Wartungskategorie.table).
      addColumn('idAtClient', lf.Type.INTEGER).
      addColumn('uuid', lf.Type.STRING).
      addColumn('deleted', lf.Type.BOOLEAN).
      addColumn('active', lf.Type.INTEGER).
      addColumn('Bezeichnung_5', lf.Type.STRING).
      addPrimaryKey(['idAtClient'], true).
      addIndex('idxUuid', ['uuid']).
      addNullable(['Bezeichnung_5']);
    log("created table " + dataModels.Wartungskategorie.table);

    schemaBuilder.createTable(dataModels.Wartungsstatus.table).
      addColumn('idAtClient', lf.Type.INTEGER).
      addColumn('uuid', lf.Type.STRING).
      addColumn('deleted', lf.Type.BOOLEAN).
      addColumn('active', lf.Type.INTEGER).
      addColumn('Bezeichnung_5', lf.Type.STRING).
      addPrimaryKey(['idAtClient'], true).
      addIndex('idxUuid', ['uuid']).
      addNullable(['Bezeichnung_5']);
    log("created table " + dataModels.Wartungsstatus.table);

    schemaBuilder.createTable(dataModels.Steuersatz.table).
      addColumn('idAtClient', lf.Type.INTEGER).
      addColumn('uuid', lf.Type.STRING).
      addColumn('deleted', lf.Type.BOOLEAN).
      addColumn('active', lf.Type.INTEGER).
      addColumn('Bezeichnung_5', lf.Type.STRING).
      addColumn('Kurzzeichen_6', lf.Type.STRING).
      addColumn('Gesamtprozentsatz_7', lf.Type.NUMBER).
      addPrimaryKey(['idAtClient'], true).
      addIndex('idxUuid', ['uuid']).
      addNullable(['Bezeichnung_5']);
    log("created table " + dataModels.Steuersatz.table);

    schemaBuilder.createTable(dataModels.Lager.table).
      addColumn('idAtClient', lf.Type.INTEGER).
      addColumn('uuid', lf.Type.STRING).
      addColumn('deleted', lf.Type.BOOLEAN).
      addColumn('active', lf.Type.INTEGER).
      addColumn('Bezeichnung_5', lf.Type.STRING).
      addPrimaryKey(['idAtClient'], true).
      addIndex('idxUuid', ['uuid']).
      addNullable(['Bezeichnung_5']);
    log("created table " + dataModels.Lager.table);

    schemaBuilder.createTable(dataModels.LagerdatenArtikel.table).
      addColumn('idAtClient', lf.Type.INTEGER).
      //addColumn('idAtServer', lf.Type.INTEGER).
      addColumn('uuid', lf.Type.STRING).
      addColumn('deleted', lf.Type.BOOLEAN).
      addColumn('active', lf.Type.INTEGER).
      addColumn('uuidArticle', lf.Type.STRING).
      addColumn('uuidWarehouse', lf.Type.STRING).
      addColumn('Lagerstand_13', lf.Type.NUMBER).
      addPrimaryKey(['idAtClient'], true).
      addIndex('idxUuid', ['uuid']).
      addNullable(['uuidArticle', 'uuidWarehouse', 'Lagerstand_13']);
    log("created table " + dataModels.LagerdatenArtikel.table);

    schemaBuilder.createTable(dataModels.Artikelgruppe.table).
      addColumn('idAtClient', lf.Type.INTEGER).
      addColumn('uuid', lf.Type.STRING).
      addColumn('deleted', lf.Type.BOOLEAN).
      addColumn('active', lf.Type.INTEGER).
      addColumn('Bezeichnung_5', lf.Type.STRING).
      addPrimaryKey(['idAtClient'], true).
      addIndex('idxUuid', ['uuid']).
      addNullable(['Bezeichnung_5']);
    log("created table " + dataModels.Artikelgruppe.table);

    const this_ = this;
    // connect to the db
    // var connectOptions = {storeType: lf.schema.DataStoreType.INDEXED_DB};
    // schemaBuilder.connect(connectOptions).then(function(db) {
    schemaBuilder.connect({ storeType: lf.schema.DataStoreType.INDEXED_DB, onUpgrade: this.onUpgrade }).then(function (db_: any) {
      log("connected to db: ", db_);

      log("Before create utility data")
      this_.createUtilityData(db_).then(() => {
        log("After create utility data?")
        // allow db to be used
        this_.db = db_;
      });
    });

  }

  private async onUpgrade(rawDb: any) {
    // Show the version currently persisted.
    log("in onUpgrade - db version: " + rawDb.getVersion());

    if (rawDb.getVersion() < 36) {
      // demo upgrading step
      log("upgrading to 36");
      await rawDb.renameTableColumn(dataModels.Kunde.table, 'Zusatz_6', 'Titel_8')
      log("upgraded to 36");
    }

    if (rawDb.getVersion() < 37) {
      // demo upgrading step
      log("upgrading to 37");
      await rawDb.addTableColumn(dataModels.Kunde.table, 'idCountryAtClient_9999', 0)
      await rawDb.addTableColumn(dataModels.Kunde.table, 'Land_14', 0)
      await rawDb.addTableColumn(dataModels.Kunde.table, 'AnredePersoenlichDu_176', 0)
      await rawDb.addTableColumn(dataModels.Kunde.table, 'idsCustomerCategoriesAtClient_9999', 0)
      await rawDb.addTableColumn(dataModels.Kunde.table, 'Kundenkategorie_21', 0)
      await rawDb.addTableColumn(dataModels.Kunde.table, 'Geburtsdatum_51', new Date("1990-01-01"))
      await rawDb.addTableColumn(dataModels.Kunde.table, 'idPaymentTypeAtClient_9999', 0)
      await rawDb.addTableColumn(dataModels.Kunde.table, 'Zahlungsart_61', 0)
      await rawDb.addTableColumn(dataModels.Kunde.table, 'idPaymentTargetAtClient_9999', 0)
      await rawDb.addTableColumn(dataModels.Kunde.table, 'Zahlungsziel_62', 0)
      await rawDb.addTableColumn(dataModels.Kunde.table, 'Rabatt_69', 0)
      await rawDb.addTableColumn(dataModels.Kunde.table, 'idPricelistAtClient_9999', 0)
      await rawDb.addTableColumn(dataModels.Kunde.table, 'Preisliste_60', 0)
      await rawDb.addTableColumn(dataModels.Auftrag.table, 'idInvoiceAtClient_9999', 0)
      await rawDb.addTableColumn(dataModels.Auftrag.table, 'Rechnung_52', 0)
      await rawDb.addTableColumn(dataModels.Kunde.table, 'Wiederanruf_Thema_238', 0)
      log("upgraded to 37")
    }

    if (rawDb.getVersion() < 38) {
      log("upgrading to 38")
      log("upgraded to 38")
    }

    if (rawDb.getVersion() < 39) {
      log("upgrading to 39")
      await rawDb.addTableColumn(dataModels.Geschlecht.table, 'uuid', "")
      log("upgraded to 39")
    }

    if (rawDb.getVersion() < 40) {
      log("upgrading to 40")
      await rawDb.addTableColumn(dataModels.Kunde.table, 'uuidSex', "")
      log("upgraded to 40")
    }

    if (rawDb.getVersion() < 41) {
      log("upgrading to 41")
      await rawDb.addTableColumn("manipulations", 'uuid', "")
    }

    if (rawDb.getVersion() < 42) {
      log("upgrading to 42")
      await rawDb.addTableColumn(dataModels.Kunde.table, 'uuid', "")
    }

    if (rawDb.getVersion() < 43) {
      log("upgrading to 43")
      await rawDb.addTableColumn(dataModels.Ansprechpartner.table, 'uuid', "")
    }

    if (rawDb.getVersion() < 44) {
      log("upgrading to 44")
      await rawDb.addTableColumn(dataModels.Ansprechpartner.table, 'uuidSex', "")
    }

    if (rawDb.getVersion() < 45) {
      log("upgrading to 45")
      await rawDb.addTableColumn(dataModels.Land.table, 'uuid', "")
    }

    if (rawDb.getVersion() < 46) {
      log("upgrading to 46")
      await rawDb.addTableColumn(dataModels.Kunde.table, 'uuidCountry', "")
    }

    if (rawDb.getVersion() < 47) {
      log("upgrading to 47")
      await rawDb.addTableColumn(dataModels.Zahlungsart.table, 'uuid', "")
    }

    if (rawDb.getVersion() < 48) {
      log("upgrading to 48")
      await rawDb.addTableColumn(dataModels.Kunde.table, 'uuidPaymentType', "")
    }

    if (rawDb.getVersion() < 49) {
      log("upgrading to 49")
      await rawDb.addTableColumn(dataModels.Zahlungsziel.table, 'uuid', "")
      await rawDb.addTableColumn(dataModels.Kunde.table, 'uuidPaymentTarget', "")
    }

    if (rawDb.getVersion() < 60) {
      log("upgrading to 60")
      await rawDb.addTableColumn(dataModels.Preisliste.table, 'uuid', "")
      await rawDb.addTableColumn(dataModels.Kunde.table, 'uuidPricelist', "")
    }

    if (rawDb.getVersion() < 61) {
      log("upgrading to 61")
      await rawDb.addTableColumn(dataModels.Kundenkategorie.table, 'uuid', "")
      await rawDb.addTableColumn(dataModels.Kunde.table, 'uuidsCustomerCategories', "")
    }

    if (rawDb.getVersion() < 62) {
      log("upgrading to 62")
      await rawDb.addTableColumn(dataModels.Artikel.table, 'uuid', "")
    }

    if (rawDb.getVersion() < 63) {
      log("upgrading to 63")
      await rawDb.addTableColumn(dataModels.Termin.table, 'uuid', "")
    }

    if (rawDb.getVersion() < 64) {
      log("upgrading to 64")
      await rawDb.addTableColumn(dataModels.Termin.table, 'uuidCustomer', "")
    }

    if (rawDb.getVersion() < 65) {
      log("upgrading to 65")
      await rawDb.addTableColumn(dataModels.Aufgabenkategorie.table, 'uuid', "")
    }

    if (rawDb.getVersion() < 66) {
      log("upgrading to 66")
      await rawDb.addTableColumn(dataModels.Aufgabe.table, 'uuid', "")
      await rawDb.addTableColumn(dataModels.Aufgabe.table, 'uuidTaskCategory', "")
    }

    if (rawDb.getVersion() < 67) {
      log("upgrading to 67")
      await rawDb.addTableColumn(dataModels.Kontaktart.table, 'uuid', "")
    }

    if (rawDb.getVersion() < 68) {
      log("upgrading to 68")
      await rawDb.addTableColumn(dataModels.Auftragsstatus.table, 'uuid', "")
    }

    if (rawDb.getVersion() < 69) {
      log("upgrading to 69")
      await rawDb.addTableColumn(dataModels.Kundenkontakt.table, 'uuid', "")
      await rawDb.addTableColumn(dataModels.Kundenkontakt.table, 'uuidCustomer', "")
      await rawDb.addTableColumn(dataModels.Kundenkontakt.table, 'uuidContactType', "")
    }

    if (rawDb.getVersion() < 71) {
      log("upgrading to 71")
      await rawDb.addTableColumn(dataModels.Stornierungsgrund.table, 'uuid', "")
    }

    if (rawDb.getVersion() < 72) {
      log("upgrading to 72")
      await rawDb.addTableColumn(dataModels.Angebot.table, 'Nummer_5', "")
      await rawDb.addTableColumn(dataModels.Auftrag.table, 'Nummer_5', "")
      await rawDb.addTableColumn(dataModels.Rechnung.table, 'Nummer_5', "")
    }

    if (rawDb.getVersion() < 74) {
      log("upgrading to 74")
      await rawDb.addTableColumn(dataModels.Angebot.table, 'uuid', "")
      await rawDb.addTableColumn(dataModels.Angebot.table, 'uuidCustomer', "")
      await rawDb.addTableColumn(dataModels.Angebot.table, 'uuidCancellationReason', "")
      await rawDb.addTableColumn(dataModels.Angebot.table, 'uuidPaymentType', "")
      await rawDb.addTableColumn(dataModels.Angebot.table, 'uuidsSalePositions', "")
      await rawDb.addTableColumn(dataModels.Angebot.table, 'uuidOrder', "")
    }

    if (rawDb.getVersion() < 75) {
      log("upgrading to 75")
      await rawDb.addTableColumn(dataModels.Angebotspositionen.table, 'uuid', "")
    }

    if (rawDb.getVersion() < 76) {
      log("upgrading to 76")
      await rawDb.addTableColumn(dataModels.Angebotspositionen.table, 'uuidParent', "")
    }

    if (rawDb.getVersion() < 79) {
      log("upgrading to 79")
      await rawDb.addTableColumn(dataModels.Auftrag.table, 'uuid', "")
      await rawDb.addTableColumn(dataModels.Auftrag.table, 'uuidCustomer', "")
      await rawDb.addTableColumn(dataModels.Auftrag.table, 'uuidCancellationReason', "")
      await rawDb.addTableColumn(dataModels.Auftrag.table, 'uuidPaymentType', "")
      await rawDb.addTableColumn(dataModels.Auftrag.table, 'uuidsSalePositions', "")
      await rawDb.addTableColumn(dataModels.Auftrag.table, 'uuidsStateOrder', "")
      await rawDb.addTableColumn(dataModels.Auftrag.table, 'uuidOffer', "")
      await rawDb.addTableColumn(dataModels.Auftrag.table, 'uuidInvoice', "")
    }

    if (rawDb.getVersion() < 80) {
      log("upgrading to 80")
      await rawDb.addTableColumn(dataModels.Auftragspositionen.table, 'uuid', "")
      await rawDb.addTableColumn(dataModels.Auftragspositionen.table, 'uuidParent', "")
    }

    if (rawDb.getVersion() < 81) {
      log("upgrading to 81")
      await rawDb.addTableColumn(dataModels.Angebotspositionen.table, 'uuidArticle', "")
      await rawDb.addTableColumn(dataModels.Auftragspositionen.table, 'uuidArticle', "")
    }

    if (rawDb.getVersion() < 84) {
      log("upgrading to 84")
      await rawDb.addTableColumn(dataModels.Rechnung.table, 'uuid', "")
      await rawDb.addTableColumn(dataModels.Rechnung.table, 'uuidCustomer', "")
      await rawDb.addTableColumn(dataModels.Rechnung.table, 'uuidCancellationReason', "")
      await rawDb.addTableColumn(dataModels.Rechnung.table, 'uuidPaymentType', "")
      await rawDb.addTableColumn(dataModels.Rechnung.table, 'uuidsSalePositions', "")
      await rawDb.addTableColumn(dataModels.Rechnung.table, 'uuidOrder', "")
      await rawDb.addTableColumn(dataModels.Rechnungspositionen.table, 'uuid', "")
      await rawDb.addTableColumn(dataModels.Rechnungspositionen.table, 'uuidArticle', "")
    }

    if (rawDb.getVersion() < 85) {
      log("upgrading to 85")
      await rawDb.addTableColumn(dataModels.Rechnungspositionen.table, 'uuidParent', "")
    }

    if (rawDb.getVersion() < 86) {
      log("upgrading to 86")
      await rawDb.addTableColumn(dataModels.Firma.table, 'uuid', "")
    }

    if (rawDb.getVersion() < 87) {
      log("upgrading to 87")
      await rawDb.addTableColumn(dataModels.Projektzeiterfassung.table, 'uuidOrder', "")
      await rawDb.addTableColumn(dataModels.Projektzeiterfassung.table, 'uuidCustomer', "")
    }

    if (rawDb.getVersion() < 88) {
      log("upgrading to 88")
      await rawDb.addTableColumn(dataModels.Projektzeiterfassung.table, 'uuid', "")
    }

    if (rawDb.getVersion() < 89) {
      log("upgrading to 89")
      await rawDb.dropTableColumn(dataModels.Kunde.table, 'idAtServer');
    }

    if (rawDb.getVersion() < 90) {
      log("upgrading to 90")
      await rawDb.dropTableColumn(dataModels.Kunde.table, 'Geschlecht_52');
      await rawDb.dropTableColumn(dataModels.Kunde.table, 'Land_14');
      await rawDb.dropTableColumn(dataModels.Kunde.table, 'Kundenkategorie_21');
      await rawDb.dropTableColumn(dataModels.Kunde.table, 'Zahlungsart_61');
      await rawDb.dropTableColumn(dataModels.Kunde.table, 'Zahlungsziel_62');
      await rawDb.dropTableColumn(dataModels.Kunde.table, 'Preisliste_60');
    }

    if (rawDb.getVersion() < 91) {
      log("upgrading to 91")
      await rawDb.dropTableColumn(dataModels.Artikel.table, 'idAtServer')
    }

    if (rawDb.getVersion() < 92) {
      log("upgrading to 92")
      await rawDb.dropTableColumn(dataModels.Kunde.table, 'idSexAtClient_9999')
      await rawDb.dropTableColumn(dataModels.Kunde.table, 'idCountryAtClient_9999')
      await rawDb.dropTableColumn(dataModels.Kunde.table, 'idsCustomerCategoriesAtClient_9999')
      await rawDb.dropTableColumn(dataModels.Kunde.table, 'idPaymentTypeAtClient_9999')
      await rawDb.dropTableColumn(dataModels.Kunde.table, 'idPaymentTargetAtClient_9999')
      await rawDb.dropTableColumn(dataModels.Kunde.table, 'idPricelistAtClient_9999')
    }

    if (rawDb.getVersion() < 93) {
      log("upgrading to 93")
      await rawDb.dropTableColumn(dataModels.Aufgabe.table, 'idAtServer')
      await rawDb.dropTableColumn(dataModels.Aufgabe.table, 'idTaskCategoryAtClient_9999')
      await rawDb.dropTableColumn(dataModels.Aufgabe.table, 'Kategorie_9')
    }

    if (rawDb.getVersion() < 94) {
      log("upgrading to 94")
      await rawDb.dropTableColumn(dataModels.Auftragsstatus.table, 'idAtServer')
      await rawDb.dropTableColumn(dataModels.Zahlungsart.table, 'idAtServer')
      await rawDb.dropTableColumn(dataModels.Zahlungsziel.table, 'idAtServer')
      await rawDb.dropTableColumn(dataModels.Preisliste.table, 'idAtServer')
      await rawDb.dropTableColumn(dataModels.Kontaktart.table, 'idAtServer')
      await rawDb.dropTableColumn(dataModels.Stornierungsgrund.table, 'idAtServer')
      await rawDb.dropTableColumn(dataModels.Aufgabenkategorie.table, 'idAtServer')
      await rawDb.dropTableColumn(dataModels.Geschlecht.table, 'idAtServer')
      await rawDb.dropTableColumn(dataModels.Land.table, 'idAtServer')
      await rawDb.dropTableColumn(dataModels.Kundenkategorie.table, 'idAtServer')

      await rawDb.dropTableColumn(dataModels.Angebot.table, 'idAtServer')
      await rawDb.dropTableColumn(dataModels.Angebot.table, 'idCustomerAtClient')
      await rawDb.dropTableColumn(dataModels.Angebot.table, 'Kunde_9')
      await rawDb.dropTableColumn(dataModels.Angebot.table, 'idOrderAtClient_9999')
      await rawDb.dropTableColumn(dataModels.Angebot.table, 'Auftrag_50')
      await rawDb.dropTableColumn(dataModels.Angebot.table, 'idPaymentTypeAtClient_9999')
      await rawDb.dropTableColumn(dataModels.Angebot.table, 'Zahlungsart_60')
      await rawDb.dropTableColumn(dataModels.Angebot.table, 'seqPosIdsAtClient_9999')
      await rawDb.dropTableColumn(dataModels.Angebot.table, 'Positionen_82')
      await rawDb.dropTableColumn(dataModels.Angebot.table, 'idCancellationReasonAtClient_9999')
      await rawDb.dropTableColumn(dataModels.Angebot.table, 'Stornierungsgrund_149')
    }

    if (rawDb.getVersion() < 95) {
      log("upgrading to 95")
      await rawDb.dropTableColumn(dataModels.Angebotspositionen.table, 'idAtServer')
      await rawDb.dropTableColumn(dataModels.Angebotspositionen.table, 'idParentAtClient_9999')
      await rawDb.dropTableColumn(dataModels.Angebotspositionen.table, 'parent_link')
      await rawDb.dropTableColumn(dataModels.Angebotspositionen.table, 'idArticleAtClient_9999')
      await rawDb.dropTableColumn(dataModels.Angebotspositionen.table, 'Artikel_4')
    }

    if (rawDb.getVersion() < 96) {
      log("upgrading to 96")
      await rawDb.dropTableColumn(dataModels.Auftrag.table, 'idAtServer')
      await rawDb.dropTableColumn(dataModels.Auftrag.table, 'idCustomerAtClient')
      await rawDb.dropTableColumn(dataModels.Auftrag.table, 'Kunde_9')
      await rawDb.dropTableColumn(dataModels.Auftrag.table, 'idOfferAtClient_9999')
      await rawDb.dropTableColumn(dataModels.Auftrag.table, 'Angebot_49')
      await rawDb.dropTableColumn(dataModels.Auftrag.table, 'idInvoiceAtClient_9999')
      await rawDb.dropTableColumn(dataModels.Auftrag.table, 'Rechnung_52')
      await rawDb.dropTableColumn(dataModels.Auftrag.table, 'idPaymentTypeAtClient_9999')
      await rawDb.dropTableColumn(dataModels.Auftrag.table, 'Zahlungsart_60')
      await rawDb.dropTableColumn(dataModels.Auftrag.table, 'seqPosIdsAtClient_9999')
      await rawDb.dropTableColumn(dataModels.Auftrag.table, 'Positionen_82')
      await rawDb.dropTableColumn(dataModels.Auftrag.table, 'idCancellationReasonAtClient_9999')
      await rawDb.dropTableColumn(dataModels.Auftrag.table, 'Stornierungsgrund_149')
      await rawDb.dropTableColumn(dataModels.Auftrag.table, 'idStateOrderAtClient_9999')
      await rawDb.dropTableColumn(dataModels.Auftrag.table, 'Status_159')

      await rawDb.dropTableColumn(dataModels.Auftragspositionen.table, 'idAtServer')
      await rawDb.dropTableColumn(dataModels.Auftragspositionen.table, 'idParentAtClient_9999')
      await rawDb.dropTableColumn(dataModels.Auftragspositionen.table, 'parent_link')
      await rawDb.dropTableColumn(dataModels.Auftragspositionen.table, 'idArticleAtClient_9999')
      await rawDb.dropTableColumn(dataModels.Auftragspositionen.table, 'Artikel_4')
    }

    if (rawDb.getVersion() < 97) {
      log("upgrading to 97")
      await rawDb.dropTableColumn(dataModels.Rechnung.table, 'idAtServer')
      await rawDb.dropTableColumn(dataModels.Rechnung.table, 'idCustomerAtClient')
      await rawDb.dropTableColumn(dataModels.Rechnung.table, 'Kunde_9')
      await rawDb.dropTableColumn(dataModels.Rechnung.table, 'idOrderAtClient_9999')
      await rawDb.dropTableColumn(dataModels.Rechnung.table, 'Auftrag_50')
      await rawDb.dropTableColumn(dataModels.Rechnung.table, 'idPaymentTypeAtClient_9999')
      await rawDb.dropTableColumn(dataModels.Rechnung.table, 'Zahlungsart_60')
      await rawDb.dropTableColumn(dataModels.Rechnung.table, 'seqPosIdsAtClient_9999')
      await rawDb.dropTableColumn(dataModels.Rechnung.table, 'Positionen_82')
      await rawDb.dropTableColumn(dataModels.Rechnung.table, 'idCancellationReasonAtClient_9999')
      await rawDb.dropTableColumn(dataModels.Rechnung.table, 'Stornierungsgrund_149')

      await rawDb.dropTableColumn(dataModels.Rechnungspositionen.table, 'idAtServer')
      await rawDb.dropTableColumn(dataModels.Rechnungspositionen.table, 'idParentAtClient_9999')
      await rawDb.dropTableColumn(dataModels.Rechnungspositionen.table, 'parent_link')
      await rawDb.dropTableColumn(dataModels.Rechnungspositionen.table, 'idArticleAtClient_9999')
      await rawDb.dropTableColumn(dataModels.Rechnungspositionen.table, 'Artikel_4')
    }

    if (rawDb.getVersion() < 98) {
      log("upgrading to 98")
      await rawDb.dropTableColumn(dataModels.Kundenkontakt.table, 'idAtServer')
      await rawDb.dropTableColumn(dataModels.Kundenkontakt.table, 'idCustomerAtClient')
      await rawDb.dropTableColumn(dataModels.Kundenkontakt.table, 'Kunde_6')
      await rawDb.dropTableColumn(dataModels.Kundenkontakt.table, 'idContactTypeAtClient_9999')
      await rawDb.dropTableColumn(dataModels.Kundenkontakt.table, 'Kontaktart_7')

      await rawDb.dropTableColumn(dataModels.Ansprechpartner.table, 'idAtServer')
      await rawDb.dropTableColumn(dataModels.Ansprechpartner.table, 'idSexAtClient_9999')
      await rawDb.dropTableColumn(dataModels.Ansprechpartner.table, 'Geschlecht_8')
      await rawDb.dropTableColumn(dataModels.Ansprechpartner.table, 'idCustomerAtClient')
      await rawDb.dropTableColumn(dataModels.Ansprechpartner.table, 'Kunde_28')

      await rawDb.dropTableColumn(dataModels.Termin.table, 'idAtServer')
      await rawDb.dropTableColumn(dataModels.Termin.table, 'idCustomerAtClient')
      await rawDb.dropTableColumn(dataModels.Termin.table, 'Kunde_29')

      await rawDb.dropTableColumn(dataModels.Projektzeiterfassung.table, 'idAtServer')
      await rawDb.dropTableColumn(dataModels.Projektzeiterfassung.table, 'idOrderAtClient_9999')
      await rawDb.dropTableColumn(dataModels.Projektzeiterfassung.table, 'Auftrag_13')
      await rawDb.dropTableColumn(dataModels.Projektzeiterfassung.table, 'idCustomerAtClient')
      await rawDb.dropTableColumn(dataModels.Projektzeiterfassung.table, 'Kunde_14')

      await rawDb.dropTableColumn(dataModels.Firma.table, 'idAtServer')
    }

    if (rawDb.getVersion() < 99) {
      log("upgrading to 99")
      await rawDb.dropTableColumn("manipulations", 'idAtClient')
    }

    if (rawDb.getVersion() < 100) {
      log("upgrading to 100")
      await rawDb.addTableColumn(dataModels.Ansprechpartner.table, 'uuidCustomer', "")
    }

    if (rawDb.getVersion() < 101) {
      log("upgrading to 101")
      await rawDb.addTableColumn(dataModels.Projektzeiterfassung.table, 'uuid', "")
    }

    if (rawDb.getVersion() < 102) {
      log("upgrading to 102")
      await rawDb.addTableColumn(dataModels.Artikel.table, 'Artikelgruppe_24', "")
      await rawDb.addTableColumn(dataModels.Artikel.table, 'uuidArticlegroup', "")
    }

    if (rawDb.getVersion() < 103) {
      log("upgrading to 103")
      await rawDb.addTableColumn(dataModels.Auftrag.table, 'gebucht_154', false)
    }

    if (rawDb.getVersion() < 111) {
      log("upgrading to 111")
      await rawDb.addTableColumn(dataModels.Artikel.table, 'uuidTaxRate', "")
      await rawDb.addTableColumn(dataModels.Angebotspositionen.table, 'uuidTaxRate', "")
      await rawDb.addTableColumn(dataModels.Auftragspositionen.table, 'uuidTaxRate', "")
      await rawDb.addTableColumn(dataModels.Rechnungspositionen.table, 'uuidTaxRate', "")
    }

    if (rawDb.getVersion() < 114) {
      log("upgrading to 114")
      await rawDb.addTableColumn(dataModels.Gerät.table, 'uuidsImages', "")
    }

    if (rawDb.getVersion() < 115) {
      log("upgrading to 115")
      await rawDb.addTableColumn(dataModels.Wartungsauftrag.table, 'uuidImageSignature', "")
    }

    if (rawDb.getVersion() < 116) {
      log("upgrading to 116")
      await rawDb.addTableColumn(dataModels.Artikel.table, 'Kalk_EK_Preis_per_Stk_103V', "0")
      await rawDb.addTableColumn(dataModels.Artikel.table, 'Bezugskosten_104V', "0")
      await rawDb.addTableColumn(dataModels.Artikel.table, 'Kosten_Material_105V', "0")
      await rawDb.addTableColumn(dataModels.Artikel.table, 'Kosten_Arbeit_107V', "0")
      await rawDb.addTableColumn(dataModels.Artikel.table, 'Kosten_Ressourcen_108V', "0")
      await rawDb.addTableColumn(dataModels.Artikel.table, 'Sonstige_Kosten_109V', "0")
      await rawDb.addTableColumn(dataModels.Artikel.table, 'Gemeinkostenanteil_126', "0")
    }

    log("upgrade finished");
    return rawDb.dump();
  }

  // create UtilityDataEntry if not exists; ignore duplicate
  private cUDE(db: any, tableUtility: any, rowObj: any) {
    var row = tableUtility.createRow(rowObj);
    return db.insert().into(tableUtility).values([row]).exec().catch(function (error: any) { });
  }

  // all ever used utility data keys must be created here!
  private async createUtilityData(db: any): Promise<any> {
    log("start creating utility data");

    var tableUtility = db.getSchema().table('utilityData');

    var syncTimeD = new Date(0);
    var syncTime = syncTimeD.toISOString().slice(0, 19).replace('T', ' ');

    /*
    var rowObj = {
      'key': "currency",
      'value': "?"
    };
    var row = tableUtility.createRow(rowObj);
    return db.insert().into(tableUtility).values([row]).exec(
    ).catch(function (error: any) { }*/
    return this.cUDE(db, tableUtility, { 'key': "currency", 'value': "?" }
    ).then(() => { return this.cUDE(db, tableUtility, { 'key': "nameUserLoginComponent", 'value': "" }); }
    ).then(() => { return this.cUDE(db, tableUtility, { 'key': "nameUser", 'value': "" }); }
    ).then(() => { return this.cUDE(db, tableUtility, { 'key': "flagDoSync", 'value': "" }); }
    ).then(() => { return this.cUDE(db, tableUtility, { 'key': "securityToken", 'value': "" }); }
    ).then(() => {
      // create all timestamps
      let promises: Promise<any>[] = [];

      for (const [name, value] of Object.entries(dataModels)) {
        var keySyncTime = "syncTimeUpdate" + name;
        var row = tableUtility.createRow({ 'key': keySyncTime, 'value': syncTime });
        promises.push(db.insert().into(tableUtility).values([row]).exec());

        var keySyncTime = "syncTimeDelete" + name;
        var row = tableUtility.createRow({ 'key': keySyncTime, 'value': syncTime });
        promises.push(db.insert().into(tableUtility).values([row]).exec());
      }

      return Promise.allSettled(promises).catch(function (error: any) { })
    }
    ).then(() => { return this.cUDE(db, tableUtility, { 'key': "syncTimeOpenAuftrag", 'value': syncTime }); }
    ).then(() => { return this.cUDE(db, tableUtility, { 'key': "syncTimeOpenAngebot", 'value': syncTime }); }
    ).then(() => { return this.cUDE(db, tableUtility, { 'key': "searchCustomersText", 'value': "" }); }
    ).then(() => { return this.cUDE(db, tableUtility, { 'key': "searchCustomersName", 'value': "" }); }
    ).then(() => { return this.cUDE(db, tableUtility, { 'key': "searchCustomersStreet", 'value': "" }); }
    ).then(() => { return this.cUDE(db, tableUtility, { 'key': "searchCustomersCity", 'value': "" }); }
    ).then(() => { return this.cUDE(db, tableUtility, { 'key': "sortCustomersColumn", 'value': "lastName" }); }
    ).then(() => { return this.cUDE(db, tableUtility, { 'key': "sortCustomersAscending", 'value': true }); }
    ).then(() => { return this.cUDE(db, tableUtility, { 'key': "showCustomersPage", 'value': 0 }); }
    ).then(() => { return this.cUDE(db, tableUtility, { 'key': "searchArticles", 'value': "" }); }
    ).then(() => { return this.cUDE(db, tableUtility, { 'key': "searchArticlesName", 'value': "" }); }
    ).then(() => { return this.cUDE(db, tableUtility, { 'key': "searchArticlesNumber", 'value': "" }); }
    ).then(() => { return this.cUDE(db, tableUtility, { 'key': "searchArticlesGroup", 'value': "" }); }
    ).then(() => { return this.cUDE(db, tableUtility, { 'key': "sortArticlesColumn", 'value': "number" }); }
    ).then(() => { return this.cUDE(db, tableUtility, { 'key': "sortArticlesAscending", 'value': true }); }
    ).then(() => { return this.cUDE(db, tableUtility, { 'key': "showArticlesPage", 'value': 0 }); }
    ).then(() => { return this.cUDE(db, tableUtility, { 'key': "showAppointmentsPage", 'value': 0 }); }
    ).then(() => { return this.cUDE(db, tableUtility, { 'key': "sortTasksColumn", 'value': "due" }); }
    ).then(() => { return this.cUDE(db, tableUtility, { 'key': "sortTasksAscending", 'value': true }); }
    ).then(() => { return this.cUDE(db, tableUtility, { 'key': "showTasksPage", 'value': 0 }); }
    ).then(() => { return this.cUDE(db, tableUtility, { 'key': "searchOpenSalesText", 'value': "" }); }
    ).then(() => { return this.cUDE(db, tableUtility, { 'key': "searchOpenSalesNumber", 'value': "" }); }
    ).then(() => { return this.cUDE(db, tableUtility, { 'key': "searchOpenSalesName", 'value': "" }); }
    ).then(() => { return this.cUDE(db, tableUtility, { 'key': "searchOpenSalesAddress", 'value': "" }); }
    ).then(() => { return this.cUDE(db, tableUtility, { 'key': "searchOpenSalesZipCode", 'value': "" }); }
    ).then(() => { return this.cUDE(db, tableUtility, { 'key': "sortOpenSalesColumn", 'value': "number" }); }
    ).then(() => { return this.cUDE(db, tableUtility, { 'key': "sortOpenSalesAscending", 'value': true }); }
    ).then(() => { return this.cUDE(db, tableUtility, { 'key': "showOpenSalesPageOffer", 'value': 0 }); }
    ).then(() => { return this.cUDE(db, tableUtility, { 'key': "showOpenSalesPageOrder", 'value': 0 }); }
    ).then(() => { return this.cUDE(db, tableUtility, { 'key': "sortEnquiriesColumn", 'value': "enquiryDate" }); }
    ).then(() => { return this.cUDE(db, tableUtility, { 'key': "sortEnquiriesAscending", 'value': true }); }
    ).then(() => { return this.cUDE(db, tableUtility, { 'key': "showEnquiriesPage", 'value': 0 }); }
    ).then(() => { return this.cUDE(db, tableUtility, { 'key': "timeRecordingDateStart", 'value': 0 }); }
    ).then(() => { return this.cUDE(db, tableUtility, { 'key': "timeRecordingUuidRecord", 'value': "" }); }
    ).then(() => { return this.cUDE(db, tableUtility, { 'key': "timeRecordingType", 'value': "" }); }
    ).then(() => { return this.cUDE(db, tableUtility, { 'key': "timeRecordingUuidCustomer", 'value': "" }); }
    ).then(() => { return this.cUDE(db, tableUtility, { 'key': "locationOriginSales", 'value': -1 }); }
    ).then(() => { return this.cUDE(db, tableUtility, { 'key': "showOpenTasksOnly", 'value': false }); }
    ).then(() => { return this.cUDE(db, tableUtility, { 'key': "showTaskCategoryUuid", 'value': "" }); }
    ).then(() => { return this.cUDE(db, tableUtility, { 'key': "searchDevicesText", 'value': "" }); }
    ).then(() => { return this.cUDE(db, tableUtility, { 'key': "showDevicesPage", 'value': 0 }); }
    ).then(() => { return this.cUDE(db, tableUtility, { 'key': "searchMaintenancesText", 'value': "" }); }
    ).then(() => { return this.cUDE(db, tableUtility, { 'key': "showOwnMaintenancesOnly", 'value': false }); }

    ).then(
      () => {
        log("Create utility data finished.")
        return Promise.resolve()
      }
    )

  }

  private timeGetDbStartMs = 0

  private async getDbLf(): Promise<any> {
    this.timeGetDbStartMs = Date.now()
    return this.getDbLf_();
  }

  private async getDbLf_(): Promise<any> {
    //
    let timeWaitingMs = Date.now() - this.timeGetDbStartMs

    do {
      if (this.db) {
        return Promise.resolve(this.db)
      }

      await CommonsService.sleep(200)

      timeWaitingMs = Date.now() - this.timeGetDbStartMs

    } while (timeWaitingMs < 2000)

    throw new DbNoConnectionError("No connection to local database.")
  }

  public async writeSecurityToken(securityToken: string) {
    return this.getDbLf().then(
      (dbLf) => {
        var table = dbLf.getSchema().table('utilityData');
        log("writing securityToken: " + securityToken);
        return dbLf.update(table).set(table.value, securityToken).where(table.key.eq("securityToken")).exec()
      }).then(function (results: any) {
        log("wrote securityToken: " + securityToken);
        return Promise.resolve(null);
      })
  }

  public async writeNameUser(nameuser: string) {
    return this.getDbLf().then(
      (dbLf) => {
        var table = dbLf.getSchema().table('utilityData');
        log("writing nameUser: " + nameuser);
        return dbLf.update(table).set(table.value, nameuser).where(table.key.eq("nameUser")).exec()
      }
    ).then(function (results: any) {
      return Promise.resolve(null);
    })
  }

  public async writeUtilityData(name: string, value: string) {
    return this.getDbLf().then(
      (dbLf) => {
        var table = dbLf.getSchema().table('utilityData');
        log("writing utility data: " + name + "=" + value);
        return dbLf.update(table).set(table.value, value).where(table.key.eq(name)).exec()
      }
    ).then(function (results: any) {
      return Promise.resolve(null);
    });
  }

  public async readUtilityData(name: string): Promise<any> {
    log("read utility data: " + name)

    return this.getDbLf().then(
      (dbLf) => {
        var table = dbLf.getSchema().table('utilityData');
        return dbLf.select().from(table).where(table.key.eq(name)).exec()
      }
    ).then(function (results: any) {
      var value = results[0]["value"];
      return Promise.resolve(value);
    });
  }


  public async writeFlagDoSync(flagDoSync: boolean) {
    return this.getDbLf().then(
      (dbLf) => {
        var table = dbLf.getSchema().table('utilityData');
        return dbLf.update(table).set(table.value, flagDoSync).where(table.key.eq("flagDoSync")).exec()
      })
      .then(function (results: any) {
        return Promise.resolve(null);
      })
  }

  public async readFlagDoSync(): Promise<boolean> {
    var dbLf = await this.getDbLf()

    var table = dbLf.getSchema().table('utilityData')
    return dbLf.select().from(table).where(table.key.eq("flagDoSync")).exec()
      .then(function (results: any) {
        return Promise.resolve(results[0]["value"]);
      })
  }

  public async readNameUser(): Promise<string> {
    return this.getDbLf().then(
      (dbLf) => {
        var table = dbLf.getSchema().table('utilityData');
        return dbLf.select().from(table).where(table.key.eq("nameUser")).exec()
      }
    ).then(function (results: any) {
      var nameUser = results[0]["value"];
      if (nameUser)
        return Promise.resolve(nameUser);
      else {
        log("Reject in readNameUser")
        return Promise.resolve("");
      }
    });
  }

  public async readSecurityToken(): Promise<string> {
    return this.getDbLf().then(
      (dbLf) => {
        var table = dbLf.getSchema().table('utilityData');
        return dbLf.select().from(table).where(table.key.eq("securityToken")).exec();
      }
    ).then(function (results: any) {
      var securityToken = results[0]["value"];
      //log("got security token from db: " + securityToken);
      return Promise.resolve(securityToken);
    });
  }

  public async readSomeManipulations(limit: number): Promise<any[]> {
    return this.getDbLf().then(
      (dbLf) => {
        var tableManipulations = dbLf.getSchema().table('manipulations');
        return dbLf.select().from(tableManipulations).limit(limit).orderBy(tableManipulations.id, lf.Order.ASC).exec();
      }
    ).then((manipulations) => {
      return Promise.resolve(manipulations)
    });
  }

  public async readManipulations(): Promise<any[]> {
    return this.getDbLf().then(
      (dbLf) => {
        var tableManipulations = dbLf.getSchema().table('manipulations');
        return dbLf.select().from(tableManipulations).orderBy(tableManipulations.id, lf.Order.ASC).exec();
      }
    ).then((manipulations) => {
      return Promise.resolve(manipulations)
    });
  }

  // stores or updates an object from the server locally
  public async saveOrUpdateItemFromServer(itemFromServer: any, nameDataModel: string) {
    var nameTable = dataModels[nameDataModel].table;
    const this_ = this;

    return this.getDbLf().then((dbLf) => {
      var table = dbLf.getSchema().table(nameTable);
      return dbLf.select(table.uuid).from(table).where(table.uuid.eq(itemFromServer.uuid)).exec()
    }).then(function (results: any) {
      if (results.length == 0) {
        // create new entry in local db
        return this_.saveNewObjectLocally(itemFromServer, nameDataModel)
      } else {
        // update existing entry in local db
        return this_.updateEditedModelByUuid(itemFromServer, nameDataModel)
      }
    }).then(() => {
      return Promise.resolve();
    });
  }

  public async saveNewObjectLocally(object: any, nameDataModel: any) {
    var nameTable = dataModels[nameDataModel].table;

    let db_ = await this.getDbLf()

    var table = db_.getSchema().table(nameTable);
    var tableDef = dataModels[nameDataModel];

    var rowObj: any = {
      'deleted': 0,
      'uuid': object.uuid
    };

    tableDef.columns.forEach(function (column: any) {
      rowObj[column.nameLocal] = object[column.nameScope];
      //if (rowObj[column.nameLocal] == null || typeof rowObj[column.nameLocal] == "undefined")
      // log("warning: set " + nameDataModel + " - " + column.nameLocal + " -  " + column.nameScope + " to " + object[column.nameScope]);
    });

    var row = table.createRow(rowObj);
    return db_.insert().into(table).values([row]).exec().then(
      function (results: any) {
        //log("stored object locally", results)
        return Promise.resolve();
      });
  }

  /*
  public async writeIntoTestTable(name: string) {
    var dbLf = await this.getDbLf()

    var table = dbLf.getSchema().table('testTable00');
    var rowObj: any = {
      'name': name,
    };

    var row = table.createRow(rowObj);
    return dbLf.insert().into(table).values([row]).exec().then(
      function (results: any) {
        return Promise.resolve();
      });
  }
  */


  // store 'create entry' operations to be executed at the server
  public async saveNewModelManipulation(object: any, nameModel: string) {
    var nameTable = dataModels[nameModel].table;

    log("saving object manipulation create: nameTable=" + nameTable);
    log("saving object manipulation create: ", object);

    let db_ = await this.getDbLf();
    var tableManipulations = db_.getSchema().table("manipulations");

    var rows = [];

    // the create operation
    var row = tableManipulations.createRow({
      id: 0, type: "create", nameTable: nameTable,
      nameColumn: "", uuid: object.uuid,
      time: "1970-01-01 00:00:00",
    });
    rows.push(row);

    // update operations column by column
    var tableDef = dataModels[nameModel];
    tableDef.columns.forEach(function (column: any) {
      var row = tableManipulations.createRow({
        id: 0, type: "update", nameTable: nameTable,
        uuid: object.uuid, nameColumn: column.nameLocal,
        time: "1970-01-01 00:00:00",
      });
      rows.push(row);
    });

    log("# inserting rows: " + rows.length);
    if (rows.length > 0) {
      return db_.insert().into(tableManipulations).values(rows).exec()
    } else {
      return Promise.resolve
    }
  }


  public async deleteOffers() {
    var db_ = await this.getDbLf();

    var tableOffers = db_.getSchema().table(dataModels.Angebot.table);
    // delete open offers locally
    return db_.delete().from(tableOffers).exec().then(function () {
      var tableOfferPositions = db_.getSchema().table(dataModels.Angebotspositionen.table);
      // delete old offer positions locally
      db_.delete().from(tableOfferPositions).exec().then(function () {
        return Promise.resolve();
      });
    });
  }

  public async deleteOrders() {
    var db_ = await this.getDbLf();

    var tableOrders = db_.getSchema().table(dataModels.Auftrag.table);
    // delete open orders locally
    return db_.delete().from(tableOrders).exec().then(function () {
      var tableOrderPositions = db_.getSchema().table(dataModels.Auftragspositionen.table);
      // delete old order positions locally
      db_.delete().from(tableOrderPositions).exec().then(function () {
        return Promise.resolve();
      });
    });
  }

  public async deleteTable(nameModel: string) {
    var db_ = await this.getDbLf();

    var table = db_.getSchema().table(dataModels[nameModel].table);
    return db_.delete().from(table).exec().then(function () {
      return Promise.resolve();
    });
  }

  public async updateTable(nameModel: string, objs: any[]) {
    await this.deleteTable(nameModel)
    for (let obj of objs) {
      //if (obj.uuid != "") {
      await this.saveNewObjectLocally(obj, nameModel)
      await this.saveImages(nameModel, obj)
      //}
    }
  }

  public async deleteImages(nameModel: string, uuid: string) {
    var db_ = await this.getDbLf();
    var tableImages = db_.getSchema().table(dataModels.Bilder.table);

    let idTable = CommonsService.getIdTable(nameModel)

    // delete images of object
    await db_.delete().from(tableImages).where(
      lf.op.or(tableImages.uuidParent.eq(idTable + ":" + uuid), tableImages.uuidParent.eq(""))).exec();

    // legacy
    await db_.delete().from(tableImages).where(
      lf.op.or(tableImages.uuidParent.eq(uuid), tableImages.uuidParent.eq(""))).exec();

    return Promise.resolve()
  }

  public async saveImages(nameModel: string, obj: any) {
    if (!("uuidsImages" in obj))
      return Promise.resolve()

    await this.deleteImages(nameModel, obj.uuid)

    let idTable = CommonsService.getIdTable(nameModel)

    // save images of object
    if (typeof obj.images != "undefined") {
      for (let image of obj.images) {
        if (image.uuidParent.indexOf(":") == -1)
          image.uuidParent = idTable + ":" + image.uuidParent

        await this.saveNewObjectLocally(image, "Bilder")
      }
    }

    return Promise.resolve()
  }

  public async readImages(entry: any): Promise<any> {
    var db_ = await this.getDbLf();
    var tableImages = db_.getSchema().table(dataModels.Bilder.table);

    let images = []
    let uuidsImagesFound = ""

    // read images except signature
    if ("uuidsImages" in entry) {
      let uuidsImagesArr = entry["uuidsImages"].split(",")

      for (let uuidImage of uuidsImagesArr) {
        let rowsImage = await db_.select().from(tableImages).where(tableImages.uuid.eq(uuidImage)).exec();
        if (!rowsImage || rowsImage.length != 1)
          continue;

        let rowImage = rowsImage[0]
        var image: any = {};
        image.idAtClient = rowImage['idAtClient'];
        image.uuid = rowImage['uuid'];

        var tableDef = dataModels["Bilder"];
        tableDef.columns.forEach(function (column: any) {
          image[column.nameScope] = rowImage[column.nameLocal];
        });

        if (uuidsImagesFound != "")
          uuidsImagesFound += ","
        uuidsImagesFound += uuidImage
        images.push(image)
      }
    }

    // read signature
    if ("uuidImageSignature" in entry) {
      let rowsImage = await db_.select().from(tableImages).where(tableImages.uuid.eq(entry["uuidImageSignature"])).exec();
      if (rowsImage && rowsImage.length == 1) {
        let rowImage = rowsImage[0]
        var image: any = {};
        image.idAtClient = rowImage['idAtClient'];
        image.uuid = rowImage['uuid'];

        var tableDef = dataModels["Bilder"];
        tableDef.columns.forEach(function (column: any) {
          image[column.nameScope] = rowImage[column.nameLocal];
        });

        images.push(image)
      }
    }

    entry.images = images
    entry.uuidsImages = uuidsImagesFound

    return Promise.resolve(entry)
  }

  public async updateSales(nameModel: string, nameModelPosition: string, sales: any[]) {
    await this.deleteTable(nameModel)
    await this.deleteTable(nameModelPosition)

    for (let sale of sales) {
      await this.saveNewObjectLocally(sale, nameModel)
      for (let position of sale.positions) {
        await this.saveNewObjectLocally(position, nameModelPosition)
      }
    }
  }

  public async deleteInvoices() {
    var db_ = await this.getDbLf();

    var tableInvoices = db_.getSchema().table(dataModels.Rechnung.table);
    // delete open invoices locally
    return db_.delete().from(tableInvoices).exec().then(function () {
      var tableInvoicePositions = db_.getSchema().table(dataModels.Rechnungspositionen.table);
      // delete old invoice positions locally
      db_.delete().from(tableInvoicePositions).exec().then(function () {
        return Promise.resolve();
      });
    });
  }

  public async deleteContacts() {
    var db_ = await this.getDbLf();
    var tableContacts = db_.getSchema().table(dataModels.Kundenkontakt.table);
    return db_.delete().from(tableContacts).exec();
  }

  public async deleteContactsOfCustomer(uuidCustomer: string) {
    var db_ = await this.getDbLf();
    var tableContacts = db_.getSchema().table(dataModels.Kundenkontakt.table);
    return db_.delete().from(tableContacts).where(tableContacts.uuidCustomer.eq(uuidCustomer)).exec();
  }

  public async deleteProjectTimes() {
    var db_ = await this.getDbLf();
    var tableProjectTimes = db_.getSchema().table(dataModels.Projektzeiterfassung.table);
    return db_.delete().from(tableProjectTimes).exec();
  }

  public async readSyncTime(nameSyncTime: string) {
    log("readSyncTime nameSyncTime: " + nameSyncTime);
    var this_ = this;
    var db_ = await this.getDbLf();
    var table = db_.getSchema().table('utilityData');

    return db_.select().from(table).where(table.key.eq(nameSyncTime)).exec()
      .then((results: any) => {
        if (!results || results.length == 0) {
          log("no sync time found for " + nameSyncTime);
          var syncTimeD = new Date(0);
          var syncTime = syncTimeD.toISOString().slice(0, 19).replace('T', ' ');
          // make sure sync time exists
          return this_.writeUtilityData(nameSyncTime, syncTime).then(() => Promise.resolve(syncTime))
        } else {
          var syncTime: string = results[0]["value"];
          log("readSyncTime nameSyncTime: " + nameSyncTime + ", syncTime: " + syncTime);
          syncTime = syncTime.slice(0, 19).replace('T', ' ');
          return Promise.resolve(syncTime);
        }
      });
  }

  public async writeSyncTime(nameSyncTime: string, syncTime: any) {
    log("writeSyncTime nameSyncTime: " + nameSyncTime + ", syncTime: " + syncTime);
    var db_ = await this.getDbLf();

    var tableUtility = db_.getSchema().table('utilityData');

    return db_.update(tableUtility).set(tableUtility["value"], syncTime).where(tableUtility.key.eq(nameSyncTime)).exec()
  }

  public async markModelDeletedByUuid(objectFromServer: any, nameDataModel: string) {
    if (!objectFromServer || !objectFromServer.uuid)
      return Promise.resolve()
    var db_ = await this.getDbLf();

    var nameTable = dataModels[nameDataModel].table;

    var table = db_.getSchema().table(nameTable);

    return db_.update(table)
      .set(table.deleted, true)
      .where(table.uuid.eq(objectFromServer.uuid)).exec()
  }

  public async resetSyncTime(nameSyncTime: string): Promise<any> {
    var db_ = await this.getDbLf();

    var tableUtility = db_.getSchema().table('utilityData');

    var syncTimeD = new Date(0);
    var syncTime = syncTimeD.toISOString().slice(0, 19).replace('T', ' ');

    var rowObj = {
      'key': nameSyncTime,
      'value': syncTime
    };
    var row = tableUtility.createRow(rowObj);
    return db_.insertOrReplace().into(tableUtility).values([row]).exec();
  }

  public async readCustomerAndStoreObject(objectFromServer: any, nameModel: string) {
    if (!objectFromServer.deleted) {
      return this.saveOrUpdateItemFromServer(objectFromServer, nameModel).then(
        () => Promise.resolve()
      )
    } else {
      return Promise.resolve();
    }
  }

  public async loadCustomerByUuid(uuid: string) {
    return this.loadTableEntryByUuid("Kunde", uuid).then((customer) => {
      if (customer == null)
        return Promise.resolve(null)

      customer.uuidsCustomerCategoriesArr = customer.uuidsCustomerCategories.split(",");

      if (!customer.remarks)
        customer.remarks = "";
      if (!customer.keywords)
        customer.keywords = "";
      if (!customer.contactFrequencyDays)
        customer.contactFrequencyDays = 0

      if (!customer.lastContact || typeof customer.lastContact == 'undefined')
        customer.lastContact = new Date(0)
      if (!customer.nextContact || typeof customer.nextContact == 'undefined')
        customer.nextContact = new Date(0)
      if (!customer.birthday || typeof customer.birthday == 'undefined')
        customer.birthday = new Date(0)

      if (customer.lastContact.getFullYear() < 1901)
        customer.lastContact = new Date(0)
      if (customer.nextContact.getFullYear() < 1901)
        customer.nextContact = new Date(0)
      if (customer.birthday.getFullYear() < 1901)
        customer.birthday = new Date(0)

      return Promise.resolve(customer)
    });
  }

  public async deleteCustomer(uuidCustomer: string) {
    let db_ = await this.getDbLf()

    var tableOffers = db_.getSchema().table(dataModels.Angebot.table);
    await db_.update(tableOffers).set(tableOffers.uuidCustomer, "").
      where(tableOffers.uuidCustomer.eq(uuidCustomer)).exec();

    var tableOrders = db_.getSchema().table(dataModels.Auftrag.table);
    await db_.update(tableOrders).set(tableOrders.uuidCustomer, "").
      where(tableOrders.uuidCustomer.eq(uuidCustomer)).exec();

    var tableInvoices = db_.getSchema().table(dataModels.Rechnung.table);
    await db_.update(tableInvoices).set(tableInvoices.uuidCustomer, "").
      where(tableInvoices.uuidCustomer.eq(uuidCustomer)).exec();

    var tableAppointments = db_.getSchema().table(dataModels.Termin.table);
    await db_.update(tableAppointments).set(tableAppointments.uuidCustomer, "").
      where(tableAppointments.uuidCustomer.eq(uuidCustomer)).exec();

    // mark customer as deleted
    var tableCustomers = db_.getSchema().table(dataModels.Kunde.table);

    return db_.update(tableCustomers).set(tableCustomers.deleted, true)
      .where(tableCustomers.uuid.eq(uuidCustomer)).exec();
  }

  public async loadCountries() {
    return this.loadTable("Land").then((countries) => {
      countries.sort((a: any, b: any) => {
        if (a.name == null)
          return -1
        if (b.name == null)
          return 1
        if (a.name == "Österreich")
          return -1
        if (b.name == "Österreich")
          return 1
        if (a.name == "Deutschland")
          return -1
        if (b.name == "Deutschland")
          return 1
        if (a.name == "Schweiz")
          return -1
        if (b.name == "Schweiz")
          return 1
        if (a.name > b.name)
          return 1;
        if (a.name < b.name)
          return -1;
        return 0
      })
      return Promise.resolve(countries)
    })
  }

  /** Zahlungsart, Zahlungsziel, Preisliste, Artikelgruppe */
  public async loadTableWithActiveFlag(nameDataModel: string): Promise<any[]> {
    var db_ = await this.getDbLf();

    var tableLf = db_.getSchema().table(dataModels[nameDataModel].table);

    var entries: any[] = [];

    return db_.select().from(tableLf).where(tableLf.active.eq(1)).orderBy(tableLf.idAtClient, lf.Order.ASC).exec()
      .then((results: any) => {
        results.forEach(function (row: any) {
          var entry: any = {};
          entry.idAtClient = row['idAtClient'];
          entry.deleted = row['deleted'];
          entry.uuid = row['uuid'];
          //if ("uuid" in row && row['uuid'] == "")
          //throw new DbOutOfDateError("Table has empty uuid: " + nameDataModel)

          var tableDef = dataModels[nameDataModel];
          tableDef.columns.forEach(function (column: any) {
            entry[column.nameScope] = row[column.nameLocal];
          });

          if (!entry.deleted) {
            entries.push(entry);
          }
        });

        return Promise.resolve(entries);
      });
  }

  /** Auftragsstatus, Stornierungsgrund, Aufgabenkategorie, Geschlecht, Land, Kundenkategorie, Kontaktart */
  public async loadTable(nameDataModel: string): Promise<any[]> {
    var db_ = await this.getDbLf();

    var table = db_.getSchema().table(dataModels[nameDataModel].table);

    var entries: any[] = [];
    return db_.select().from(table).orderBy(table.idAtClient, lf.Order.ASC).exec()
      .then(function (results: any) {
        results.forEach(function (row: any) {
          var entry: any = {};
          entry.idAtClient = row['idAtClient'];
          entry.deleted = row['deleted'];
          entry.uuid = row['uuid'];
          //if ("uuid" in row && row['uuid'] == "")
          //throw new DbOutOfDateError("Table has empty uuid: " + nameDataModel)

          var tableDef = dataModels[nameDataModel];
          tableDef.columns.forEach(function (column: any) {
            entry[column.nameScope] = row[column.nameLocal];
          });

          if (!entry.deleted) {
            entries.push(entry);
          }
        });

        return Promise.resolve(entries)
      });
  }

  public async loadMapUuidToIdAtClient(nameDataModel: string): Promise<Map<number, number>> {
    var db_ = await this.getDbLf();

    var mapUuidToIdAtClient = new Map();

    var table = db_.getSchema().table(dataModels[nameDataModel].table);

    return db_.select(table.idAtClient, table.uuid).from(table).exec().then(function (results: any) {
      for (var i = 0; i < results.length; i++) {
        var row = results[i];
        var idAtClient = row['idAtClient'];
        var uuid = row['uuid'];

        mapUuidToIdAtClient.set(uuid, idAtClient);
      }

      return Promise.resolve(mapUuidToIdAtClient)
    });
  }

  public async storeSequenceOfSalesPositions(nameDataModel: string, saleFromServer: any) {
    var db_ = await this.getDbLf();
    var tableSale = db_.getSchema().table(dataModels[nameDataModel].table);

    return db_.update(tableSale)
      .set(tableSale.uuidsSalePositions, saleFromServer.uuidsSalePositions)
      .where(tableSale.uuid.eq(saleFromServer.uuid)).exec()
  }

  public async loadTableSorted(nameDataModel: string, sortColumnName: string, sortAscending: boolean): Promise<any[]> {
    var db_ = await this.getDbLf();
    var table = db_.getSchema().table(dataModels[nameDataModel].table);

    var entries: any[] = [];

    return db_.select().from(table).orderBy(table[sortColumnName], sortAscending ? lf.Order.ASC : lf.Order.DESC).exec()
      .then(function (results: any) {
        results.forEach(function (row: any) {
          var entry: any = {};
          entry.deleted = row['deleted'];
          entry.uuid = row['uuid'];
          //if ("uuid" in row && row['uuid'] == "")
          //throw new DbOutOfDateError("Table has empty uuid: " + nameDataModel)

          var tableDef = dataModels[nameDataModel];
          tableDef.columns.forEach(function (column: any) {
            entry[column.nameScope] = row[column.nameLocal];
          });

          if (!entry.deleted) {
            entries.push(entry);
          }
        });

        return Promise.resolve(entries)
      });
  }

  // update an object locally
  public async updateEditedModelByUuid(object: any, nameDataModel: string) {
    var db_ = await this.getDbLf();
    var nameTable = dataModels[nameDataModel].table;

    var table = db_.getSchema().table(nameTable);

    var updQuery = db_.update(table);
    var tableDef = dataModels[nameDataModel];
    tableDef.columns.forEach(function (column: any) {
      var value = object[column.nameScope]
      if (typeof value === 'undefined')
        value = null;
      if (typeof table[column.nameLocal] != "undefined")
        updQuery.set(table[column.nameLocal], value);
    });

    await updQuery.where(table.uuid.eq(object.uuid)).exec()

    await this.saveImages(nameDataModel, object)
  }

  // store update operations to be executed at the server, when it is available again
  public async saveEditedModelManipulation(objectBeforeEdit: any, objectNew: any, nameDataModel: string) {
    let this_ = this

    var db_ = await this.getDbLf();
    var nameTable = dataModels[nameDataModel].table;

    var tableManipulations = db_.getSchema().table('manipulations');

    var rows: any[] = [];
    var promises: Promise<any>[] = []

    var tableDef = dataModels[nameDataModel];
    tableDef.columns.forEach(function (column: any) {

      // has the value changed?
      var valueHasChanged = false;

      //let isDateBefore = objectBeforeEdit[column.nameScope] instanceof Date
      //let isDateNew = objectNew[column.nameScope] instanceof Date

      //if (isDateBefore || isDateNew || column.type == "date") {
      if (column.type == "date") {
        var dateBefore = new Date(objectBeforeEdit[column.nameScope]);
        var dateNew = new Date(objectNew[column.nameScope]);
        valueHasChanged = Math.abs(dateBefore.getTime() - dateNew.getTime()) > 1000
      } else
        valueHasChanged = (objectBeforeEdit[column.nameScope] != objectNew[column.nameScope]);

      if (valueHasChanged) {
        // assemble the operation to be done on server
        var manipulation: any = {};
        manipulation.type = "update";
        manipulation.nameTable = nameTable;
        manipulation.uuid = objectBeforeEdit.uuid;
        manipulation.nameColumn = column.nameLocal;

        // delete previous update operation of the same type;
        // repetition of same manipulation is not necessary
        promises.push(
          db_.delete().from(tableManipulations).where(
            lf.op.and(
              tableManipulations.type.eq("update"),
              lf.op.and(
                tableManipulations.nameTable.eq(manipulation.nameTable),
                lf.op.and(
                  tableManipulations.uuid.eq(manipulation.uuid),
                  tableManipulations.nameColumn.eq(manipulation.nameColumn)
                )
              )
            )
          ).exec()
        )

        // check if images have changed
        if (column.nameScope == "uuidsImages" || column.nameScope == "uuidImageSignature") {
          // images added
          for (let image of objectNew.images) {
            let existingImage = CommonsService.getObjectByUuid(objectBeforeEdit.images, image.uuid)
            if (!existingImage) {
              // new image
              promises.push(this_.saveNewModelManipulation(image, "Bilder"))
            }
          }

          // images deleted
          for (let image of objectBeforeEdit.images) {
            let existingImage = CommonsService.getObjectByUuid(objectNew.images, image.uuid)
            if (!existingImage) {
              // the image was delted
              promises.push(this_.saveDeleteModelManipulations("Bilder", image.uuid))
            }
          }
        }

        // an update operation
        var row = {
          'id': 0,
          'type': manipulation.type,
          'nameTable': manipulation.nameTable,
          'uuid': manipulation.uuid,
          'nameColumn': manipulation.nameColumn
        }

        rows.push(row);
      }
    });

    return Promise.allSettled(promises).then(() => {
      if (rows.length > 0) {
        let rowsD = []
        for (let row of rows)
          rowsD.push(tableManipulations.createRow(row))
        return db_.insertOrReplace().into(tableManipulations).values(rowsD).exec();
      }
    })
  }

  public async readRowByUuid(nameTable: string, uuid: string) {
    var db_ = await this.getDbLf()
    var table = db_.getSchema().table(nameTable);
    return db_.select().from(table).where(table.uuid.eq(uuid)).
      exec().then(function (results: any) {
        if (results && results.length == 1) {
          var row = results[0];
          return Promise.resolve(row)
        } else {
          log("Reject in readRow")
          return Promise.resolve(null)
        }
      });
  }

  public async loadTableEntryByUuid(nameDataModel: string, uuid: string) {
    let this_ = this

    if (!uuid || uuid == "") {
      log("loadTableEntry: not found 0 " + nameDataModel + " " + uuid)
      return Promise.resolve(null)
    }

    const db_ = await this.getDbLf();

    var table = db_.getSchema().table(dataModels[nameDataModel].table);
    var tableDef = dataModels[nameDataModel];

    if (!table.uuid)
      throw new DbOutOfDateError("Table has no uuid " + nameDataModel)

    let results = await db_.select().from(table).where(table.uuid.eq(uuid)).exec()

    if (!results || results.length != 1) {
      log("loadTableEntry: not found 1 " + nameDataModel + " " + uuid)
      return Promise.resolve(null)
    } else {
      var row = results[0];
      var entry: any = {};
      entry.idAtClient = row['idAtClient'];
      entry.deleted = row['deleted'];
      entry.uuid = row['uuid'];
      //if ("uuid" in row && row['uuid'] == "")
      //throw new DbOutOfDateError("Table has empty uuid: " + nameDataModel)

      tableDef.columns.forEach(function (column: any) {
        entry[column.nameScope] = row[column.nameLocal];
      });

      if (entry.deleted) {
        log("loadTableEntry: not found 2 " + nameDataModel + " " + uuid)
        return Promise.resolve(null)
      }

      await this_.readImages(entry)

      return Promise.resolve(entry)
    }

  }

  public async loadTableEntry(nameDataModel: string, idAtClient: number) {
    if (isNaN(idAtClient)) {
      log("loadTableEntry: not found 0 " + nameDataModel + " " + idAtClient)
      return Promise.resolve(null)
    }

    const db_ = await this.getDbLf();

    var table = db_.getSchema().table(dataModels[nameDataModel].table);
    var tableDef = dataModels[nameDataModel];

    return db_.select().from(table).where(table.idAtClient.eq(idAtClient)).exec()
      .then(function (results: any) {

        if (!results || results.length != 1) {
          log("loadTableEntry: not found 1 " + nameDataModel + " " + idAtClient)
          return Promise.resolve(null)
        } else {
          var row = results[0];
          var entry: any = {};
          entry.idAtClient = row['idAtClient'];
          entry.deleted = row['deleted'];
          entry.uuid = row['uuid'];
          //if ("uuid" in row && row['uuid'] == "")
          //throw new DbOutOfDateError("Table has empty uuid: " + nameDataModel)

          tableDef.columns.forEach(function (column: any) {
            entry[column.nameScope] = row[column.nameLocal];
          });

          if (entry.deleted) {
            log("loadTableEntry: not found 2 " + nameDataModel + " " + idAtClient)
            return Promise.resolve(null)
          }

          return Promise.resolve(entry)
        }
      })
  }

  public async writeFieldByUuid(nameTable: string, uuid: string, nameColumn: string, value: any) {
    var db_ = await this.getDbLf()
    var table = db_.getSchema().table(nameTable);
    return db_.update(table).
      set(table[nameColumn], value).
      where(table.uuid.eq(uuid)).exec();
  }

  public async deleteManipulation(idManipulation: number) {
    var db_ = await this.getDbLf();
    var tableManipulations = db_.getSchema().table('manipulations');
    return db_.delete().from(tableManipulations).
      where(tableManipulations.id.eq(idManipulation)).exec()
  }

  public async deleteManipulationsBatch(idManipulationUpto: number) {
    var db_ = await this.getDbLf();
    var tableManipulations = db_.getSchema().table('manipulations');
    return db_.delete().from(tableManipulations).
      where(tableManipulations.id.lte(idManipulationUpto)).exec()
  }

  public async loadSalePositionsByUuidSale(uuidSale: number, nameModelPosition: string, currency: string) {

    var db_ = await this.getDbLf();
    var tableSalePositions = db_.getSchema()
      .table(dataModels[nameModelPosition].table);
    var tableDef = dataModels[nameModelPosition];

    let rows = await db_.select()
      .from(tableSalePositions)
      .where(tableSalePositions.uuidParent.eq(uuidSale))
      .exec();

    let salePositions = await Promise.all(rows.map(async (row: any) => {
      let salePosEdit: any = {};
      salePosEdit.idAtClient = row["idAtClient"];
      salePosEdit.deleted = row["deleted"];
      salePosEdit.uuid = row['uuid'];
      //if ("uuid" in row && row['uuid'] == "")
      //throw new DbOutOfDateError("Table has empty uuid: " + dataModels[nameModelPosition].table)

      if (salePosEdit.deleted)
        return Promise.resolve(null)

      tableDef.columns.forEach(function (column: any) {
        salePosEdit[column.nameScope] = row[column.nameLocal];
      });

      //salePosEdit.taxFactor =
      //Math.round(salePosEdit.taxFactor * 1000.0) / 1000.0;

      CommonsService.setPrices(salePosEdit, currency);
      return Promise.resolve(salePosEdit)
    }))
    let salePositions_ = salePositions.filter((salePos) => salePos != null);

    await Promise.all(salePositions_.map(async (salePos: any) => {
      salePos.number = "";

      var tableArticles = db_.getSchema().table(dataModels["Artikel"].table);
      let results = await db_
        .select()
        .from(tableArticles)
        .where(tableArticles.uuid.eq(salePos.uuidArticle))
        .exec()
      if (results.length > 0) {
        salePos.number = results[0]["Artikelnr_5"];
      }

      return Promise.resolve()
    }))
    return Promise.resolve(salePositions_)
  }

  // all sales (open and others) are in the same table
  public async loadOpenSales(nameDataModel: string) {
    if (!["Angebot", "Auftrag"].includes(nameDataModel))
      throw Error("Unknown nameDataModel '" + nameDataModel + "'")

    let openSales: any[] = [];

    let db_ = await this.getDbLf()
    var tableOpenSales = db_.getSchema().table(dataModels[nameDataModel].table);
    var query = undefined;
    if (nameDataModel == "Angebot") {
      // $scope.sale.isOpen = $scope.sale.idCancellationReasonAtClient <= 1 &&
      //						$scope.sale.idOrderAtClient <= 0;
      let cancellationReasons = await this.loadTable("Stornierungsgrund")
      if (!cancellationReasons || cancellationReasons.length == 0)
        return Promise.resolve([])
      query = db_.select().from(tableOpenSales).
        where(
          lf.op.and(
            lf.op.or
              (lf.op.or(tableOpenSales.uuidCancellationReason.isNull(),
                tableOpenSales.uuidCancellationReason.eq("")),
                tableOpenSales.uuidCancellationReason.eq(cancellationReasons[0].uuid)),
            lf.op.or(tableOpenSales.uuidOrder.isNull(),
              tableOpenSales.uuidOrder.eq(""))
          ))
    } else {
      let statesOrder = await this.loadTable("Auftragsstatus")
      if (!statesOrder || statesOrder.length == 0)
        return Promise.resolve([])
      query = db_.select().from(tableOpenSales).
        where(
          lf.op.and(
            tableOpenSales.gebucht_154.eq(true),
            (lf.op.or(tableOpenSales.uuidStateOrder.eq(statesOrder[1].uuid),
              lf.op.or(tableOpenSales.uuidStateOrder.eq(statesOrder[2].uuid),
                tableOpenSales.uuidStateOrder.eq(statesOrder[3].uuid)
              )
            ))
          ))
    }

    return query.exec().then(function (results: any) {
      results.forEach(function (row: any) {
        var openSale: any = {};
        openSale.idAtClient = row['idAtClient'];
        openSale.deleted = row['deleted'];
        openSale.uuid = row['uuid'];
        //if ("uuid" in row && row['uuid'] == "")
        //throw new DbOutOfDateError("Table has empty uuid: " + dataModels[nameDataModel].table)

        if (!openSale.deleted) {
          let tableDef = dataModels[nameDataModel];
          tableDef.columns.forEach(function (column: any) {
            openSale[column.nameScope] = row[column.nameLocal];
          });

          if (openSale.idCustomerAtClient >= 0) {
            openSale.enableButtonCustomer = true;
          } else {
            openSale.enableButtonCustomer = false;
          }

          var dateDummy = new Date(openSale.date);
          openSale.textDate = CommonsService.formatDate0(dateDummy);

          openSales.push(openSale)
        }
      })

      return Promise.resolve(openSales)
    })
  }

  public async loadSales(uuidCustomer: string, nameModel: string, currency: string) {
    let db_ = await this.getDbLf()

    let tableSales = db_.getSchema().table(dataModels[nameModel].table);
    let tableDef = dataModels[nameModel];

    let sales: any[] = [];

    let query = null;
    if (uuidCustomer != null && uuidCustomer != "")
      query = db_.select().from(tableSales).where(tableSales.uuidCustomer.eq(uuidCustomer))
    else
      query = db_.select().from(tableSales)

    return query.orderBy(tableSales.Datum_6, lf.Order.ASC).exec()
      .then(function (results: any) {
        results.forEach(function (row: any) {
          var sale: any = {};
          sale.deleted = row['deleted'];
          sale.uuid = row['uuid'];
          //if ("uuid" in row && row['uuid'] == "")
          //throw new DbOutOfDateError("Table has empty uuid: " + dataModels[nameModel].table)

          tableDef.columns.forEach(function (column: any) {
            sale[column.nameScope] = row[column.nameLocal];
          });

          CommonsService.setPrices(sale, currency);

          var dateDummy = new Date(sale.date);
          sale.textDate = CommonsService.formatDate0(dateDummy);

          if (!sale.deleted) {
            CommonsService.setPrices(sale, currency);
            sales.push(sale);
          }
        });

        return Promise.resolve(sales)
      });
  }

  public async loadSale(uuidSale: string, nameModel: string, nameModelPosition: string, currency: string) {
    let sale = await this.loadTableEntryByUuid(nameModel, uuidSale)
    if (!sale)
      return Promise.resolve(null)
    sale.textDate = CommonsService.formatDate0(sale.date);
    CommonsService.setPrices(sale, currency)

    sale.sumNetBeforeDiscountV = 0;
    sale.positions = await this.loadSalePositionsByUuidSale(sale.uuid, nameModelPosition, currency)

    for (var iSalePos = 0; iSalePos < sale.positions.length; iSalePos++) {
      var position = sale.positions[iSalePos];
      sale.sumNetBeforeDiscountV += position.priceSumNetV;
    }
    sale.sumNetDiscountV = sale.sumNetBeforeDiscountV - sale.sumNetV;
    CommonsService.setPrices(sale, currency);

    // sort positions by sequence of idAtClient
    if (sale.positions.length > 0 && sale.uuidsSalePositions != "") {
      let arrSequPosUuids = sale.uuidsSalePositions.split(",");
      sale.positions.sort(function (pos0: any, pos1: any) {
        var iPos0 = arrSequPosUuids.indexOf("" + pos0.uuid);
        var iPos1 = arrSequPosUuids.indexOf("" + pos1.uuid);
        if (iPos0 == -1 && iPos1 == -1)
          return 0;
        if (iPos0 == -1)
          return 1
        if (iPos1 == -1)
          return -1
        return iPos0 - iPos1;
      });
    }

    return Promise.resolve(sale)
  }

  public async loadSalePosition(uuidSalePosition: string, nameModelPosition: string, currency: string) {
    let db_ = await this.getDbLf()
    var tableSalePositions = db_.getSchema().table(dataModels[nameModelPosition].table);
    var tableDef = dataModels[nameModelPosition];

    return db_.select().from(tableSalePositions).where(tableSalePositions.uuid.eq(uuidSalePosition)).exec()
      .then((results: any) => {
        log("found # rows: " + results.length);
        if (!results || results.length != 1) {
          log("Reject in loadSalePosition")
          return Promise.reject()
        } else {
          let row = results[0];
          var salePosEdit: any = {};
          salePosEdit.idAtClient = row['idAtClient'];
          salePosEdit.uuid = row['uuid'];
          salePosEdit.deleted = row['deleted'];

          if (salePosEdit.deleted) {
            log("Reject in loadSalePosition")
            return Promise.reject()
          }

          tableDef.columns.forEach(function (column: any) {
            salePosEdit[column.nameScope] = row[column.nameLocal];
          });

          salePosEdit.textDescription = CommonsService.convertRtfToPlain(salePosEdit.description);
          salePosEdit.textDescription2 = CommonsService.convertRtfToPlain(salePosEdit.description2);

          //if (!salePosEdit.taxFactor)
          //salePosEdit.taxFactor = 0.0

          CommonsService.setPrices(salePosEdit, currency);

          log("   found a salePosition ", salePosEdit);

          log("   idArticleAtClient: " + salePosEdit.idArticleAtClient);

          /*
          if (salePosEdit.idArticleAtClient) {
            var tableArticles = $rootScope.db.getSchema().table(dataModels["Artikel"].table);
            $rootScope.db.select().from(tableArticles).where(tableArticles.idAtClient.eq(salePosEdit.idArticleAtClient)).exec()
              .then(function (results) {
                results.forEach(function (row) {
                  log("article: ", row);
                });
              });
          }
          */

          return Promise.resolve(salePosEdit)
        }
      });
  }

  public async deleteTableEntryByUuid(nameModel: string, uuid: string) {
    let db_ = await this.getDbLf()
    var table = db_.getSchema().table(dataModels[nameModel].table);

    await this.deleteImages(nameModel, uuid)

    return db_.update(table).set(table.deleted, true)
      .where(table.uuid.eq(uuid)).exec()
  }

  public async saveDeleteModelManipulations(nameModel: string, uuid: string) {
    let db_ = await this.getDbLf()

    var nameTable = dataModels[nameModel].table;

    var tableManipulations = db_.getSchema().table("manipulations");

    var rows = [];

    var row = tableManipulations.createRow({
      id: 0,
      type: "delete",
      nameTable: nameTable,
      uuid: uuid,
      nameColumn: "",
    });
    rows.push(row);

    return db_
      .insertOrReplace()
      .into(tableManipulations)
      .values(rows)
      .exec()
  }

  public async saveUpdateStockAfterStockMovementManipulation(uuidStockMovement: string) {
    let db_ = await this.getDbLf()

    var tableManipulations = db_.getSchema().table("manipulations");

    var rows = [];

    var row = tableManipulations.createRow({
      id: 0,
      type: "updateStockAfterStockMovement",
      nameTable: "",
      uuid: uuidStockMovement,
      nameColumn: "",
    });
    rows.push(row);

    return db_
      .insertOrReplace()
      .into(tableManipulations)
      .values(rows)
      .exec()
  }

  public async deleteSale(uuid: string, nameModel: string) {
    let db_ = await this.getDbLf()

    var table = db_.getSchema().table(dataModels[nameModel].table);
    return db_.update(table).set(table.deleted, true).where(table.uuid.eq(uuid)).exec()
      .then(() => {
        if (nameModel == "Angebot") {
          // log("unlinking deleted offer");
          var tableOrders = db_.getSchema().table(dataModels["Auftrag"].table);
          return db_.update(tableOrders).
            set(tableOrders.uuidOffer, "").
            where(tableOrders.uuidOffer.eq(uuid)).exec();
        } else if (nameModel == "Auftrag") {
          // log("unlinking deleted order");
          var tableOffers = db_.getSchema().table(dataModels["Angebot"].table);
          return db_.update(tableOffers).
            set(tableOffers.uuidOrder, "").
            where(tableOffers.uuidOrder.eq(uuid)).exec()
            .then(() => {
              var tableInvoices = db_.getSchema().table(dataModels["Rechnung"].table);
              return db_.update(tableInvoices).
                set(tableInvoices.uuidOrder, "").
                where(tableInvoices.uuidOrder.eq(uuid)).exec();
            })
        } else if (nameModel == "Rechnung") {
          // log("unlinking deleted invoice");
          var tableOrders = db_.getSchema().table(dataModels["Auftrag"].table);
          return db_.update(tableOrders).
            set(tableOrders.uuidInvoice, "").
            where(tableOrders.uuidInvoice.eq(uuid)).exec();
        }
      })
  }

  async loadCustomerContacts(uuidCustomer: string): Promise<any[]> {
    let db_ = await this.getDbLf()

    var tableCustomerContacts = db_.getSchema().table(dataModels.Kundenkontakt.table);
    var tableContactTypes = db_.getSchema().table(dataModels.Kontaktart.table);

    var tableDef = dataModels["Kundenkontakt"];

    let customerContacts: any[] = [];

    let results: any[] = await db_.select(
      tableContactTypes.Bezeichnung_5, tableCustomerContacts.Datum_5,
      tableCustomerContacts.Thema_16, tableCustomerContacts.Zusagen_17,
      tableCustomerContacts.ZuTun_18, tableCustomerContacts.uuid,
      tableCustomerContacts.uuidCustomer, tableCustomerContacts.uuidContactType).from(tableCustomerContacts).
      leftOuterJoin(tableContactTypes,
        tableCustomerContacts.uuidContactType.eq(tableContactTypes.uuid)).
      where(lf.op.and(tableCustomerContacts.uuidCustomer.eq(uuidCustomer),
        tableCustomerContacts.deleted.eq(false))).exec()

    results.forEach(function (row) {
      var customerContact: any = {};
      customerContact.uuid = row[dataModels.Kundenkontakt.table]['uuid'];
      //if ("uuid" in row[dataModels.Kundenkontakt.table] && row[dataModels.Kundenkontakt.table]['uuid'] == "")
      //throw new DbOutOfDateError("Table has empty uuid: Kundenkontakt")
      customerContact.deleted = row[dataModels.Kundenkontakt.table]['deleted'];

      tableDef.columns.forEach(function (column: any) {
        customerContact[column.nameScope] = row[dataModels.Kundenkontakt.table][column.nameLocal];
      });
      customerContact['nameContactType'] = row[dataModels.Kontaktart.table]['Bezeichnung_5'];
      customerContact.textDate = CommonsService.formatDate0(customerContact.date);

      if (!customerContact.deleted) {
        customerContacts.push(customerContact);
      }
    });

    return Promise.resolve(customerContacts)
  }

  // store an 'update time' operation to be executed at the server
  async saveNewModelManipulationOfTime(nameModel: string, nameColumn: string, strTime: string, uuid: string) {
    let db_ = await this.getDbLf()

    var tableManipulations = db_.getSchema().table('manipulations');

    var rows = [];
    var row = tableManipulations.createRow({
      'id': 0,
      'type': "updateTimeIfGreater",
      'nameTable': dataModels[nameModel].table,
      'nameColumn': nameColumn,
      'uuid': uuid,
      'time': strTime
    });
    rows.push(row);

    return db_.insertOrReplace().into(tableManipulations).values(rows).exec()
  }

  // store an 'increment value' operation to be executed at the server
  async saveNewModelManipulationIncrement(nameModel: string, nameColumn: string, uuid: string) {
    let db_ = await this.getDbLf()

    var tableManipulations = db_.getSchema().table('manipulations');

    var rows = [];
    var row = tableManipulations.createRow({
      'id': 0,
      'type': "incrementColumn",
      'nameTable': dataModels[nameModel].table,
      'nameColumn': nameColumn,
      'uuid': uuid,
    });
    rows.push(row);

    return db_.insertOrReplace().into(tableManipulations).values(rows).exec()
  }

  public async deleteDatabase() {
    if (this.db)
      await this.db.close();

    log("Closed database")
    var req = window.indexedDB.deleteDatabase('FF_DB');

    req.onsuccess = function () {
      log("Deleted database successfully");
    };
    req.onerror = function () {
      log("Couldn't delete database");
    };
    req.onblocked = function () {
      log("Couldn't delete database due to the operation being blocked");
    };
  }

}
