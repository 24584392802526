import { BaseModel } from "./base-model"

export class StocksOfArticleModel extends BaseModel {
  nameWarehouse: string = ""
  amount: number = 0

  constructor(params: any = null) {
    super(params)
    this.copyFromObject(params)
  }

  static override newModel(object: any = null) {
    return new StocksOfArticleModel(object)
  }
}
