import { ArticleModel } from "./article-model";
import { CustomerModel } from "./customer-model";
import { DeviceModel } from "./device-model";
import { MaintenanceModel } from "./maintenance-model";

export class MaintenanceForViewModel extends MaintenanceModel {

  customer: CustomerModel | null = null
  customerName: string = ""
  customerFirstName: string = ""
  customerFullName: string = ""
  customerStreet: string = ""
  customerZipCode: string = ""
  customerCity: string = ""
  customerTelephone: string = ""

  device: DeviceModel | null = null
  deviceName: string = ""

  article: ArticleModel | null = null
  articleName: string = ""

  textBegin: string = ""

  setCustomer(customer: CustomerModel | null) {
    if(customer) {
      this.customer = customer
      this.customerName = customer.lastName
      this.customerFirstName = customer.firstName
      this.customerFullName = (this.customerName + " " + this.customerFirstName).trim()
      this.customerStreet = customer.street
      this.customerZipCode = customer.zipCode
      this.customerCity = customer.city
      this.customerTelephone = customer.telephone1
    } else {
      this.customer = null
      this.customerName = ""
      this.customerFirstName = ""
      this.customerFullName = ""
      this.customerStreet = ""
      this.customerZipCode = ""
      this.customerCity = ""
      this.customerTelephone = ""
    }
  }

  setDevice(device: DeviceModel | null) {
    if(device) {
      this.device = device
      this.deviceName = device.name
    } else {
      this.device = null
      this.deviceName = ""
    }
  }

  setArticle(article: ArticleModel | null) {
    if(article) {
      this.article = article
      this.articleName = article.name
    } else {
      this.article = null
      this.articleName = ""
    }
  }

  constructor(params: any = null) {
    super(params)
    this.copyFromObject(params)
  }

  static override newModel(object: any = null) {
    return new MaintenanceForViewModel(object)
  }
}
