import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { UUID } from 'angular2-uuid';
import { fromEvent, Observable, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CommonsService } from './commons-service';
import { DbService } from './db.service';
import { ParametersStartService } from './parameters-start.service';
declare var $: any;
declare const log: any
declare const dataModels: any;

@Injectable({
  providedIn: 'root'
})
export class NetworkService implements OnDestroy {
  private local = false
  private serverAddress = this.local ? "ws://localhost:3001" : "wss://" + window.location.hostname + "/ws/"; //environment.SERVER_HOST;// + ":" + environment.port;
  private securityToken = "";
  public isOnline = false;
  public hasServer = false;

  private ws: WebSocket | null = null;

  static TIMEOUT_REQUESTS_MS = 1500;
  static TIMEOUT_SYNC_REQUESTS_MS = 2000;
  static TIMEOUT_ALLOW_RETRY_MS = 3000;
  static BATCH_SIZE_MANIPULATIONS = 50;
  static MESSAGE_STATUS_UNAUTHORIZED = "Unauthorized"

  offlineEvent: Observable<Event>;
  onlineEvent: Observable<Event>;
  subscriptions: Subscription[] = [];

  messages = new Map()
  dateLoaded = new Date()

  countManipulationBatches = 0
  showProgressMessageSync = false

  token: string = ""

  constructor(
    private tranlsate: TranslateService,
    private parametersStart: ParametersStartService,
    private dbService: DbService,
  ) {
    this.onlineEvent = fromEvent(window, 'online')
    this.offlineEvent = fromEvent(window, 'offline')
    this.setIsOnline(navigator.onLine)
    this.subscriptions.push(this.onlineEvent.subscribe(e => {
      log("is online");
      this.setIsOnline(true)
    }));
    this.subscriptions.push(this.offlineEvent.subscribe(e => {
      log("is offline");
      this.setIsOnline(false)
    }));

    this.init()
  }

  async init() {
    this.securityToken = await this.dbService.readSecurityToken()
    this.openWebSocket()
    let this_ = this
    setInterval(function () {
      this_.observeServer()
    }, NetworkService.TIMEOUT_ALLOW_RETRY_MS)
  }

  async sendWsRequest(type: string, parameters: any): Promise<any> {
    let iTry = 0
    let intervalTryMs = 20
    let maxTry = NetworkService.TIMEOUT_REQUESTS_MS / intervalTryMs
    log("max try " + maxTry)

    iTry = 0
    do {
      iTry++
      await CommonsService.sleep(intervalTryMs)
    } while (iTry < maxTry &&
      this.dateLoaded.getTime() > new Date().getTime() - NetworkService.TIMEOUT_REQUESTS_MS)
    log("waited after reload? " + iTry + "/" + maxTry)

    if (!this.ws) {
      log("The websocket is null")
      return Promise.resolve(null)
    }

    if (!this.isOnline) {
      log("Not online")
      return Promise.resolve(null)
    }

    if (this.ws.readyState != WebSocket.OPEN) {
      log("The websocket is not open!")
      return Promise.resolve(null)
    }

    // send
    let msgUuid = UUID.UUID()
    let msgData = {
      token: this.securityToken,
      msgUuid: msgUuid,
      time: new Date().getTime(),
      status: "Pending",
      type: type,
      parameters: parameters
    }
    this.messages.set(msgUuid, msgData)
    CommonsService.startTime("send ws request " + msgData.type)
    this.ws.send(JSON.stringify(msgData))

    // wait for response
    let message = null
    iTry = 0
    do {
      iTry++
      await CommonsService.sleep(intervalTryMs)
      message = this.messages.get(msgUuid)
    } while (iTry < maxTry && (!message || message.status == "Pending"))

    this.messages.delete(msgUuid)
    log("iTry", iTry)
    log("the message", message)
    if (message.status == "Pending")
      log("ws request failed.", message)
    else
      CommonsService.stopTime("send ws request " + msgData.type)

    if (message.status == NetworkService.MESSAGE_STATUS_UNAUTHORIZED) {
      message.response = null
      await this.logout()
    }

    if (typeof message.response == "undefined")
      message.response = null

    return Promise.resolve(message.response)
  }

  getWebsocketStateText(state: number) {
    if (state == WebSocket.CLOSED)
      return "CLOSED-" + state
    else if (state == WebSocket.CLOSING) {
      return "CLOSING-" + state
    } else if (state == WebSocket.CONNECTING) {
      return "CONNECTING-" + state
    } else if (state == WebSocket.OPEN) {
      return "OPEN"
    } else
      return "UNKOWN-" + state
  }

  openWebSocket() {
    log("Opening websocket")
    let this_ = this

    if (this.ws) {
      if (this.ws.readyState == WebSocket.CONNECTING)
        return
      log("Closing websocket, state: " + this.getWebsocketStateText(this.ws.readyState))
      log(this.ws)
      this.ws.close()
    }

    log("Connecting " + this.serverAddress)
    this.ws = new WebSocket(this.serverAddress);

    this.ws.addEventListener('error', (event) => {
      console.log('WebSocket error: ', event);
    });

    this.ws.addEventListener("open", () => {
      log("We are connected: ws");
      this.setHasServer(true)
    });

    this.ws.addEventListener('message', function (event) {
      log("ws received a message")
      let data = JSON.parse(event.data)
      this_.cacheAuxiliaryTables(data)
      let message = this_.messages.get(data.msgUuid)
      if (!message)
        return
      message.status = data.status
      message.response = data.response
    });

    this.ws.addEventListener("close", () => {
      log("ws is closing")
      this.setHasServer(false)
    })
  }

  async login(nameUser: string, password: string) {
    log("login: has server? " + this.hasServer);
    if (!this.hasServer) {
      let text = this.tranlsate.instant("ERROR_NO_SERVER_CONNECTION")
      CommonsService.showErrorMessage(text)
      return Promise.resolve(false)
    }
    let token = await this.sendWsRequest("login", { nameUser: nameUser, password: password })
    if (token) {
      this.securityToken = token
      await this.dbService.writeSecurityToken(token)
      await this.dbService.writeNameUser(nameUser)
      this.parametersStart.nameUser = nameUser
    } else {
      let text = this.tranlsate.instant("ERROR_LOGIN_FAILED")
      CommonsService.showErrorMessage(text)
      this.securityToken = ""
      await this.dbService.writeSecurityToken("")
      await this.dbService.writeNameUser("")
      this.parametersStart.nameUser = ""
    }
    return Promise.resolve(token != null)
  }

  async logout() {
    await this.dbService.writeSecurityToken("")
    await this.dbService.writeNameUser("")
    this.parametersStart.nameUser = ""
    this.securityToken = ""
  }

  isObserveServerOngoing = false
  async observeServer() {
    if (this.isObserveServerOngoing) {
      log("observeServer is ongoing")
      return
    }
    this.isObserveServerOngoing = true

    if (!this.ws) {
      this.setHasServer(false)
      this.isObserveServerOngoing = false
      return
    }

    if (!this.isOnline) {
      this.setHasServer(false)
      this.isObserveServerOngoing = false
      return
    }

    //log("checking server status: " + this.ws.readyState)
    if (this.ws.readyState == WebSocket.CONNECTING) {
      this.setHasServer(false)
    } if (this.ws.readyState == WebSocket.OPEN) {
      //log("connection to server is open")
      this.setHasServer(true)
      try {
        await this.sendPendingManipulations(false, "observeServer")
      } catch (err) {
        log("caught error", err)
      }
    } else {
      log("Status: " + this.ws.readyState)
      this.setHasServer(false)
      this.openWebSocket()
    }

    this.isObserveServerOngoing = false
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  async isReachable() {
    return fetch(this.serverAddress, { method: 'HEAD', mode: 'no-cors' })
      .then(function (resp) {
        return resp && (resp.ok || resp.type === 'opaque');
      })
      .catch(function (err) {
        console.warn('[conn test failure]:', err);
      });
  }

  setIsOnline(isOnline: boolean) {
    this.isOnline = isOnline
    if (!isOnline)
      this.setHasServer(false)
  }

  setHasServer(hasServer: boolean) {
    this.hasServer = hasServer
    if (!hasServer) {
      log("request failed")
      this.timeLastFailedRequest = new Date()
      this.cachedTables.clear()
    }
  }

  async setIsSyncOngoing(isSyncOngoing: boolean, showProcessingMessage: boolean, calledBy: string) {
    log("setIsSyncOngoing " + isSyncOngoing + " " + calledBy)
    this.isSyncOngoing = isSyncOngoing
    if (!this.isSyncOngoing) {
      this.showProgressMessageSync = false
      await CommonsService.hideProcessingMessageSync(calledBy)
    } else {
      if (!this.showProgressMessageSync) {
        if (showProcessingMessage) {
          this.showProgressMessageSync = true
          await CommonsService.showProcessingMessageSync(calledBy, this.countManipulationBatches)
        }
      }
    }
  }

  private timeLastFailedRequest = new Date(0)

  private isRetryAllowed(): boolean {
    if (this.hasServer)
      return true
    if (new Date().getTime() - this.timeLastFailedRequest.getTime() > NetworkService.TIMEOUT_ALLOW_RETRY_MS)
      return true
    else {
      this.timeLastFailedRequest = new Date()
      return false
    }
  }

  isSyncOngoing = false;

  async sendPendingManipulations(showMessage: boolean, calledBy: string): Promise<boolean> {
    log("sendPendingManipulations: online status " + this.isOnline + " " + this.hasServer + " '" + this.parametersStart.nameUser + "'");
    if (!this.isOnline || !this.hasServer || !this.parametersStart.nameUser) {
      return Promise.resolve(false);
    }
    if (this.isSyncOngoing) {
      await this.setIsSyncOngoing(true, showMessage, calledBy)
      log("need to wait: " + calledBy)
      do {
        log("wait until sync is over")
        await CommonsService.sleep(50)
      } while (this.isSyncOngoing)
      log("waited until sync was over: " + calledBy)
      await this.setIsSyncOngoing(false, showMessage, calledBy)
      return Promise.resolve(false);
    }
    await this.setIsSyncOngoing(true, false, calledBy)
    log("no need to wait: " + calledBy)
    try {
      return this.sendPendingManipulationsInt(showMessage, calledBy)
    } catch (error) {
      log("Sync error")
      log(error)
      await this.setIsSyncOngoing(false, false, calledBy)
      return Promise.resolve(false)
    }
  }

  async sendPendingManipulationsInt(showMessage: boolean, calledBy: string): Promise<boolean> {
    if (!this.isOnline) {
      await this.setIsSyncOngoing(false, false, calledBy)
      return Promise.resolve(false)
    }

    if (!this.isRetryAllowed()) {
      log("will not try again: sendPendingManipulationsInt")
      await this.setIsSyncOngoing(false, false, calledBy)
      return Promise.resolve(false)
    }

    let manipulationsAll = await this.dbService.readManipulations()
    let manipulations = await this.dbService.readSomeManipulations(NetworkService.BATCH_SIZE_MANIPULATIONS)
    if (manipulations.length == 0) {
      await this.setIsSyncOngoing(false, false, calledBy)
      return Promise.resolve(true)
    }

    this.countManipulationBatches = Math.ceil(manipulationsAll.length / NetworkService.BATCH_SIZE_MANIPULATIONS)

    let lastManipulationId = 0
    let manipulationsNew = []
    log("manipulations:")
    log(manipulations)
    for (let manipulation of manipulations) {
      lastManipulationId = manipulation.id
      if (manipulation.uuid == "")
        continue;
      if (manipulation.type === "incrementColumn") {
        manipulation.value = 0
        manipulationsNew.push(manipulation)
      } else if (manipulation.type === "updateStockAfterStockMovement") {
        manipulationsNew.push(manipulation)
      } else if (manipulation.type === "updateTimeIfGreater") {
        manipulation.value = manipulation.time
        manipulationsNew.push(manipulation)
      } else if (manipulation.type === "create") {
        manipulation.value = 0
        manipulationsNew.push(manipulation)
      } else if (manipulation.nameColumn.endsWith("_9999") ||
        manipulation.nameColumn.endsWith("_9999V")) {
        //ignore
      } else if (manipulation.type === "delete") {
        manipulationsNew.push(manipulation)
      } else if (manipulation.type === "update") {
        let rowFirst = await this.dbService.readRowByUuid(manipulation.nameTable, manipulation.uuid)
        if (rowFirst == null)
          continue; // entry does not exist any more
        if (!(manipulation.nameColumn in rowFirst))
          continue;

        let valueColumnFirst = rowFirst[manipulation.nameColumn];
        if (typeof valueColumnFirst == "boolean") {
          valueColumnFirst = valueColumnFirst ? "1" : "0"
        } else if (typeof valueColumnFirst == "number") {
          valueColumnFirst = "" + valueColumnFirst
        } else if (valueColumnFirst instanceof Date) {
          // convert to mysql format YYYY-MM-DD hh:mm:ss
          // valueColumn = valueColumn.toISOString().substring(0, 19).replace('T', ' ');
          valueColumnFirst = CommonsService.dateToMySQLString(valueColumnFirst);
        }
        valueColumnFirst = encodeURIComponent(valueColumnFirst);

        log("update " + manipulation.nameTable + " uuid: " + manipulation.uuid +
          ", nameColumn: " + manipulation.nameColumn +
          ", valueColumn: " + (valueColumnFirst.length < 100 ? valueColumnFirst : "#" + valueColumnFirst.length));

        // escape string
        if (manipulation.nameColumn === "Titel_8" || manipulation.nameColumn === "Thema_16" ||
          manipulation.nameColumn === "Zusagen_17" || manipulation.nameColumn === "ZuTun_18") {
          valueColumnFirst = valueColumnFirst.replace("\n", "\\r\\n");
        }

        manipulation.value = valueColumnFirst

        manipulationsNew.push(manipulation)
      }
    }
    manipulations = manipulationsNew

    if (manipulations.length == 0) {
      await this.dbService.deleteManipulationsBatch(lastManipulationId)
      log("recursive call 1")
      await this.sendPendingManipulationsInt(showMessage, "self")
      await this.setIsSyncOngoing(false, false, calledBy)
      return Promise.resolve(true)
    }

    // really call server
    CommonsService.startTime("sendPendingManipulationsInt")
    await this.setIsSyncOngoing(true, showMessage, calledBy)
    log("sendPendingManipulationsInt " + calledBy);

    let isDone = await this.sendWsRequest("syncManipulations", { manipulations: manipulations })
    log("reaponseo " + isDone)
    CommonsService.stopTime("sendPendingManipulationsInt")
    if (isDone) {
      this.setHasServer(true)
      await this.dbService.deleteManipulationsBatch(lastManipulationId)
      log("recursive call")
      await this.sendPendingManipulationsInt(showMessage, "self")
    } else {
      this.setHasServer(false)
      await this.setIsSyncOngoing(false, showMessage, calledBy)
      CommonsService.stopTime("sendPendingManipulationsInt")
    }
    return Promise.resolve(isDone)

    /*
    let data: any = {};
    data.manipulations = manipulations;

    let this_ = this
    let commandServerPost = this.serverAddress + "/syncManipulations?securityToken=" + this.securityToken;
    return new Promise((resolve, reject) => {
      let req = $.post(commandServerPost, data, function (response: any) {
        //this.dbService.deleteMAn
        if (response.status == "OK") {
          this_.cacheAuxiliaryTables(response)
          this_.setHasServer(true)
          // a batch was sent
          this_.dbService.deleteManipulationsBatch(lastManipulationId).then(() => {
            // send next batch
            this_.sendPendingManipulationsInt(showMessage, "self").then(() => {
              CommonsService.stopTime("sendPendingManipulationsInt")
              resolve(true)
            })
          })
        } else {
          await this_.setIsSyncOngoing(false, showMessage, calledBy)
          this_.setHasServer(false)
          CommonsService.stopTime("sendPendingManipulationsInt")
          resolve(false)
        }
      }).fail(function () {
        await this_.setIsSyncOngoing(false, showMessage, calledBy)
        this_.setHasServer(false)
        CommonsService.stopTime("sendPendingManipulationsInt")
        resolve(false)
      })
      // sync manipulations may take longer
      setTimeout(function () {
        log("aborting sendPendingManipulationsInt")
        req.abort();
      }, NetworkService.TIMEOUT_SYNC_REQUESTS_MS);
    })
    */
  }

  async readSale(nameModel: string, uuid: string): Promise<any> {
    await this.sendPendingManipulations(true, "readSale")

    let sale = await this.sendWsRequest("readSale", { nameModel: nameModel, uuid: uuid })
    return Promise.resolve(sale)
  }

  async updateSale(nameModel: string, sale: any): Promise<boolean> {
    await this.sendPendingManipulations(true, "updateSale")

    let done = await this.sendWsRequest("updateSale", { nameModel: nameModel, sale: sale })
    return Promise.resolve(done)
  }

  async createSale(nameModel: string, sale: any): Promise<boolean> {
    await this.sendPendingManipulations(true, "createSale")

    let done = await this.sendWsRequest("createSale", { nameModel: nameModel, sale: sale })
    return Promise.resolve(done)
  }

  async readTable(nameModel: string): Promise<any[] | null> {
    await this.sendPendingManipulations(true, "readTable " + nameModel)

    let cachedTable = this.cachedTables.get(nameModel)
    if (cachedTable) {
      log("table from cache " + nameModel)
      log(cachedTable)
      return Promise.resolve(this.eliminateInactive(cachedTable))
    }

    let result = await this.sendWsRequest("readTable", { nameModel: nameModel })
    return Promise.resolve(this.eliminateInactive(result))
  }

  async readOffers(uuidCustomer: string = ""): Promise<any[] | null> {
    await this.sendPendingManipulations(true, "readOffers")

    let result = await this.sendWsRequest("readSales", { nameModel: "Angebot", uuidCustomer: uuidCustomer })
    return Promise.resolve(result)
  }

  async readOrders(uuidCustomer: string = ""): Promise<any[] | null> {
    await this.sendPendingManipulations(true, "readOrders")

    let result = await this.sendWsRequest("readSales", { nameModel: "Auftrag", uuidCustomer: uuidCustomer })
    return Promise.resolve(result)
  }

  async readInvoices(uuidCustomer: string = ""): Promise<any[] | null> {
    await this.sendPendingManipulations(true, "readInvoices")

    let result = await this.sendWsRequest("readSales", { nameModel: "Rechnung", uuidCustomer: uuidCustomer })
    return Promise.resolve(result)
  }

  /*
  private async readSales(nameOp: string, uuidCustomer: string = ""): Promise<any[] | null> {
    await this.sendPendingManipulations(true, nameOp)

    let this_ = this
    //this.setIsOnline(navigator.onLine)
    if (!this.isOnline)
      return Promise.resolve(null)

    if (!this.isRetryAllowed()) {
      log("will not try again " + nameOp)
      return Promise.resolve(null)
    }

    if (!uuidCustomer)
      uuidCustomer = ""

    return new Promise((resolve, reject) => {
      let req = $.getJSON(this.serverAddress + "/" + nameOp + "?uuidCustomer=" + uuidCustomer + "&securityToken=" + this.securityToken,
        function (response: any) {
          this_.commons.checkResponse(response)
          if (response.status == "OK") {
            this_.cacheAuxiliaryTables(response)
            this_.setHasServer(true)
            resolve(response.data)
          } else {
            this_.setHasServer(false)
            resolve(null)
          }
        }).fail(function () {
          this_.setHasServer(false)
          resolve(null)
        })
      setTimeout(function () { req.abort(); }, NetworkService.TIMEOUT_REQUESTS_MS);
    })
  }
  */

  private eliminateInactive(entries: any[]) {
    if (!entries)
      return entries
    let res = []
    for (let entry of entries) {
      if (!("active" in entry) || entry["active"])
        res.push(entry)
    }
    return res
  }

  async createTableEntry(nameModel: string, entry: any): Promise<boolean> {
    await this.sendPendingManipulations(true, "createTableEntry " + nameModel)

    let result = await this.sendWsRequest("createTableEntry", { nameModel: nameModel, entry: entry })
    return Promise.resolve(result)
  }

  async deleteTableEntry(nameModel: string, uuid: string): Promise<boolean> {
    await this.sendPendingManipulations(true, "deleteTableEntry " + nameModel)

    let result = await this.sendWsRequest("deleteTableEntry", { nameModel: nameModel, uuid: uuid })
    return Promise.resolve(result)
  }

  async readTableEntry(nameModel: string, uuid: string): Promise<any[] | null> {
    await this.sendPendingManipulations(true, "readTableEntry " + nameModel)

    let result = await this.sendWsRequest("readTableEntry", { nameModel: nameModel, uuid: uuid })
    return Promise.resolve(result)
  }

  async updateTableEntry(nameModel: string, entry: any): Promise<boolean> {
    await this.sendPendingManipulations(true, "updateTableEntry " + nameModel)

    let result = await this.sendWsRequest("updateTableEntry", { nameModel: nameModel, entry: entry })
    return Promise.resolve(result)
  }

  async updateStockAfterStockMovement(uuidStockMovement: string): Promise<boolean> {
    await this.sendPendingManipulations(true, "updateStockAfterStockMovement")

    let result = await this.sendWsRequest("updateStockAfterStockMovement", { uuidStockMovement: uuidStockMovement })
    return Promise.resolve(result)
  }

  async readCustomerContacts(uuidCustomer: string): Promise<any[] | null> {
    await this.sendPendingManipulations(true, "readCustomerContacts")

    let contacts = await this.sendWsRequest("readCustomerContacts", { uuidCustomer: uuidCustomer })
    return Promise.resolve(contacts)
  }

  async readCustomerHistoryHtml(nameModel: string, uuidCustomer: string): Promise<string> {
    await this.sendPendingManipulations(true, "readCustomerHistoryHtml")

    let html = await this.sendWsRequest("readCustomerHistoryHtml", { nameModel: nameModel, uuidCustomer: uuidCustomer })
    return Promise.resolve(html)
  }

  async readCustomerSalesHistory(uuidCustomer: string, dateFromStr: string, dateToStr: string) {
    await this.sendPendingManipulations(true, "readCustomerSalesHistory")

    let rows = await this.sendWsRequest("readCustomerSalesHistory",
      { uuidCustomer: uuidCustomer, dateFromStr: dateFromStr, dateToStr })
    return Promise.resolve(rows)
  }

  async readStocksOfArticleInWarehouses(uuidArticle: string) {
    await this.sendPendingManipulations(true, "readCustomerSalesHistory")

    let rows = await this.sendWsRequest("readStocksOfArticleInWarehouses",
      { uuidArticle: uuidArticle })

    return Promise.resolve(rows)
  }

  private cachedTables = new Map()
  private cachedCurrency = null
  private cachedCompany = null

  private async cacheAuxiliaryTables(response: any) {
    if (response.cachedTables) {
      for (let nameModel in response.cachedTables) {
        let cachedTable = response.cachedTables[nameModel]
        this.cachedTables.set(nameModel, cachedTable)
      }
    }

    this.cachedCurrency = response.currency
    this.cachedCompany = response.company
  }

  getCurrency() {
    return this.cachedCurrency
  }

  getCompany() {
    return this.cachedCompany
  }

}
